import auth0Client from './../Auth';
import axios from 'axios';
import history from '../utils/history';
// import { Button, notification, Divider, Space } from 'antd';
// import {
//   RadiusUpleftOutlined,
//   RadiusUprightOutlined,
//   RadiusBottomleftOutlined,
//   RadiusBottomrightOutlined,
// } from '@ant-design/icons';
import {toast} from "react-toastify";

const apiConfig = {
  host: process.env.REACT_APP_API_URL,
};

const getRequestConfig = () => {
  return {
    headers: {
      Authorization: 'Bearer ' + auth0Client.getIdToken(),
    },
  };
};

const handleErrorResponse = async (error) => {
  if (error.response) {
    let response = error.response;
    switch (response.data.error) {
      case 'UserDoesNotExistException':
        history.push('/register');
        return Promise.reject();

      case 'TokenExpired':
        // TODO
        break;

      case 'NotFound':
        alert("Resource not found.");
        return Promise.reject();

      case 'AccessDenied':
        alert("Project access denied.");
        // TODO: redirect to /
        // if project is set as default, remove this data from localStorage
        history.push('/');
        return Promise.reject();

      case 'ProjectNotActive':
        alert("Project is not active.");
        // TODO: redirect to /
        // if project is set as default, remove this data from localStorage
        history.push('/');
        return Promise.reject();

      default:
        let errorMessage = response.data.message || "Server error: " + response.data.error + ", please try again.";
        // not sure if we should use toast here
        // console.log(errorMessage);
        toast(errorMessage, {
          type: toast.TYPE.ERROR
        });
        break;

    }

    // console.log(`API error: [${response.status}] ${response.data.error}`);
    // notification.error({
    //   message: `API Error ${response.status}`,
    //   description: `${response.data.error}`,
    //   placement: `topRight`,
    //   duration: 5
    // });
    throw error;
  } else if (error.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
   //  console.log(error.request);

    alert(`API error, probably can't connect to backend`);
    // turning off for development
    //throw new Error(`API error, probably can't connect to backend`);
  } else {
    // Something happened in setting up the request that triggered an Error
   //  console.log('Error', error.message);
    throw new Error(`API error, probably can't connect to backend`);
  }
};

export const apiGET = (path, params) => {
  return axios.get(
    `${apiConfig.host}${path}`,
    {
      ...getRequestConfig(),
      params
    })
    .then(result => result.data)
    .catch(handleErrorResponse);
};

export const apiPOST = (path, body) => {
  return axios.post(
    `${apiConfig.host}${path}`,
    body,
    getRequestConfig()
  )
    .then(result => result.data)
    .catch(handleErrorResponse);
};

export const apiPATCH = (path, body) => {
  return axios.patch(
    `${apiConfig.host}${path}`,
    body,
    getRequestConfig()
  )
    .then(result => result.data)
    .catch(handleErrorResponse);
};

// eslint-disable-next-line no-unused-vars
export const apiPUT = (path, body) => {
  return axios.put(
    `${apiConfig.host}${path}`,
    body,
    getRequestConfig()
  )
    .then(result => result.data)
    .catch(handleErrorResponse);
};

// eslint-disable-next-line no-unused-vars
export const apiDELETE = path => {
  return axios.delete(
    `${apiConfig.host}${path}`,
    getRequestConfig()
  )
    .then(result => result.data)
    .catch(handleErrorResponse);
};
