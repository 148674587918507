import React, { useState } from 'react';
import { Box } from '../box/Box';
import { SavedWithUs } from '../Account/SavedWithUs';
import { useHistory } from 'react-router-dom';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Menu } from 'antd';
import { Tabs} from 'antd';
// eslint-disable-next-line no-unused-vars
const { TabPane } = Tabs;
// eslint-disable-next-line no-unused-vars
const { SubMenu } = Menu;

export const LeftMainBoxes = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [cols, setCols] = useState(2);
  const history = useHistory();

  const settings = {
    arrows: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1
  };
  // eslint-disable-next-line no-unused-vars
  const current = 'mail';
  // eslint-disable-next-line no-unused-vars
  const handleClick = e => {
    // console.log(e);
    props.boxes.filter(box => box.box.id === e).map(b => b.box.link && history.push(b.box.link));
    // props.boxes.map((box) => {
    //   if (box.box.id === e) {
    //     // console.log(box.box);
    //   }
    //   if (box.box.id === e && box.box.link) {
    //     // console.log(box.box.link);
    //     history.push(box.box.link);
    //   }
    //   if (box.box.id === e && box.box.click) {
    //     // console.log(box.box.click);
    //   }
    // });
  };
  // eslint-disable-next-line no-unused-vars
  const currentActiveBox = () => {
    // props.boxes.filter(box => box.box.active).map((box) => {
    //   if (box.box.active) {
    //     // console.log(box.box.id);
    //     return box.box.id;
    //   }
    // });
    props.boxes.filter(box => box.box.active).map(b => b.box.id);

  }

  return (
    <>
      <section
        className={`scpc--leftmainboxes scpc--leftmainboxes-cols-${props.cols ? props.cols : 1} ${props.children ? 'has-banner' : ''}`}
        style={props.styles}>
        <div className="container-fluid scpc--leftmainboxes-container">
          <div className={`row scpc--leftmainboxes-holder-md`}>
            {/*<Tabs defaultActiveKey={currentActiveBox()} tabPosition={'top'} style={{ height: 100 }}*/}
            {/*      onTabClick={(e) => handleClick(e)}>*/}
            {/*  {props.boxes ?*/}
            {/*    props.boxes.map((box) => {*/}
            {/*      return (<TabPane tab={`${box.box.name}`} key={box.box.id}/>)*/}
            {/*    }) : ''}*/}
            {/*</Tabs>*/}
          </div>
          <div className="row scpc--leftmainboxes-contentrow">
            <div className="col-md-12">
              {props.children}
            </div>
          </div>

          <div className="row scpc--leftmainboxes-boxesrow">

            <div className={`scpc--leftmainboxes-holder-small`}>
              <Slider {...settings}>
                {props.boxes ?
                  props.boxes.map((box) => {
                    return (
                      <Box
                        box={box.box}
                        active={box.box.active}
                        cols={cols}
                        key={box.box.id}
                        setActiveBox={box.box.active}
                        link={box.box.link}
                        textCenter={props.boxTextCenter}
                        click={box.box.click}
                        visible={box.box.visible}
                      />
                    )
                  })
                  : ''}
              </Slider>
            </div>
            <div
              className={`scpc--leftmainboxes-holder ${props.sticky ? 'scpc--leftmainboxes-sticky' : ''}`}>
              {props.boxes ?
                props.boxes.map((box) => {
                  return (
                    <Box
                      box={box.box}
                      active={box.box.active}
                      cols={cols}
                      key={box.box.id}
                      setActiveBox={box.box.active}
                      link={box.box.link}
                      textCenter={props.boxTextCenter}
                      click={box.box.click}
                      visible={box.box.visible}
                    />
                  )
                })
                : ''}
            </div>
          </div>
          {props.savedWithUs && window.innerWidth > 1500 ?
            <>
              <SavedWithUs/>
              <div className={`mb-30`}>&nbsp;</div>
            </> : ''}
        </div>
      </section>
    </>
  );
};