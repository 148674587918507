import React, {useState} from 'react';
import {loadStripe} from '@stripe/stripe-js';
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  Elements,
  useElements,
  useStripe
} from "@stripe/react-stripe-js";
import {toast, ToastContainer} from 'react-toastify';
import {LoaderElem} from "../Loader";
import {useHistory} from "react-router-dom";
import routes from '../../../routes/routes';
import useResponsiveFontSize from "./useResponsiveFontSize";
import * as PaymentsApi from "../../../api/payments";
import auth0Client from '../../../Auth';
import Visa from 'simple-icons/icons/visa';
import MasterCard from 'simple-icons/icons/mastercard';
import AmericanExpress from 'simple-icons/icons/americanexpress';
import {Button, Result} from 'antd';
import 'react-toastify/dist/ReactToastify.css';

// const CARD_OPTIONS = {
//   iconStyle: 'solid',
//   style: {
//     base: {
//       iconColor: '#c4f0ff',
//       fontSize: '16px',
//       fontSmoothing: 'antialiased',
//       ':-webkit-autofill': {
//         color: '#F0F0F0',
//       },
//       '::placeholder': {
//         color: '#F0F0F0',
//       },
//     },
//     invalid: {
//       iconColor: '#ffc7ee',
//       color: '#ffc7ee',
//     },
//   },
// };

// const CardField = ({ onChange }) => (
//   <div className="FormRow">
//     <div className={`scpc--forms-input-holder`}>
//       {/*<CardElement options={CARD_OPTIONS} onChange={onChange}/>*/}
//     </div>
//   </div>
// );

const Field = ({label, id, type, placeholder, required, autoComplete, value, onChange}) => (
  <div className="FormRow">
    <div className={`scpc--forms-input-holder`}>
      <label htmlFor={id} className="FormRowLabel">
        {label}
      </label>
      <input
        className="FormRowInput scpc--forms-input-text input-default input-full"
        id={id}
        type={type}
        placeholder={placeholder}
        required={required}
        autoComplete={autoComplete}
        value={value}
        onChange={onChange}
      />
    </div>
  </div>
);

const SubmitButton = ({processing, error, children, disabled}) => (
  <button
    className={`scpc--button-flat text-center color-blue button-rounded-default with-icon size--small ${error ? 'SubmitButton--error' : ''}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? 'Processing...' : children}
  </button>
);

// const ResetButton = ({ onClick }) => (
//   <button type="button" className="ResetButton" onClick={onClick}>
//     Reset form
//   </button>
// );

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  const user = auth0Client.getUser();

  const [error, setError] = useState(null);
  const [cardErrors, setCardErrors] = useState({
    cardNumber: null,
    cardDate: null,
    cardCvc: null
  });
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [loading, setLoading] = useState(false);
  const [billingDetails, setBillingDetails] = useState({
    email: user.email,
    phone: '',
    name: user.name,
  });
  // eslint-disable-next-line no-unused-vars
  const fontSize = useResponsiveFontSize();

  const options = {
    style: {
      base: {
        color: "#313971",
        letterSpacing: "-0.025em",
        fontFamily: "'Work Sans', sans-serif",
        "::placeholder": {
          color: "#b3b3b3",
          fontWeight: '300'
        },
        fontSize: '15px'
      },
      invalid: {
        color: "#f05667"
      },
      focus: {
        color: "#0296ff"
      }
    }
  };


  const handleSubmit = async (event) => {
    event.preventDefault();

    if (cardErrors.cardCvc || cardErrors.cardNumber || cardErrors.cardDate) {
      setError({message: 'Your form has errors - fix them before submission'});
      return;
    }

    if (!stripe || !elements) {
      /**
       * Stripe.js has not loaded yet. Make sure to disable
       * form submission until Stripe.js has loaded.
       */
      return;
    }

    setProcessing(true);

    let payload = (element) => {
      setLoading(true);
      /**
       *  Retrieve Client Secret
       *  from the backend setupIntent call
       */
      PaymentsApi.getStripePaymentMethodIntent().then(
        ({intent}) => {
          /**
           * Create payment method
           * using data from the card form
           */
          stripe.confirmCardSetup(intent.client_secret, {
            payment_method: {
              card: element,
              billing_details: billingDetails,
            }
          }).then(
            /**
             * Save setup intent response to the backend
             * @param result
             */
            (result) => {
              if (result.error) {
                toast("Card error: " + result.error.message, {
                  toastId: 'confirmCardSetupError',
                  type: toast.TYPE.ERROR
                });
                setProcessing(false);
                setLoading(false);
                setCardErrors({...cardErrors, cardNumber: result.error})
                elements.getElement('CardNumberElement').focus();
              } else {
                // card successfully verified, send to backend
                PaymentsApi.createPaymentMethod({
                  "payment_method": "new",
                  "new_payment_method": result
                }).then(() => {
                  setPaymentMethod(true);
                  setLoading(false);
                  toast.success('Payment method added', {
                    position: "top-right",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                  });
                });
              }
            }).catch(err => {
            toast(err.message, {
              toastId: 'confirmCardSetupError',
              type: toast.TYPE.ERROR
            });
            // console.log(err);
          });
        }).catch(err => {
        toast(err.message, {
          toastId: 'getStripePaymentMethodIntentError',
          type: toast.TYPE.ERROR
        });
        // console.log(err);
      });
    };
    payload(elements.getElement(CardNumberElement));
    setProcessing(false);
    if (payload.error) {
      setError(payload.error);
    }
  };

  const showFormErrorsToast = () => {
    if (!toast.isActive('carderrors')) {
      toast(error.message, {
        toastId: 'carderrors',
        type: toast.TYPE.ERROR
      });

    }
    return;
  };

  // const reset = () => {
  //   setError(null);
  //   setProcessing(false);
  //   setPaymentMethod(null);
  //   setBillingDetails({
  //     email: '',
  //     phone: '',
  //     name: '',
  //   });
  // };

  const redirectAfterPaymentAdded = () => {
    auth0Client.getUser().meta.firstLogin = null;
    setTimeout(() => {
      history.push(routes.addProject);
    }, 3000);
  }

  return paymentMethod ? (
    <div className={`scpc--payments-box`}>
      <Result
        status="success"
        title="Added method successfully"
        subTitle="Thank you for adding the Payment Method. It will be used for the future payments."
      />
      {redirectAfterPaymentAdded()}
    </div>
  ) : (
    <div className={`scpc--payments-box`}>
      <form className="Form scpc--forms-has-loader scpc--forms-form" onSubmit={handleSubmit}
            width={'100%'}>
        <LoaderElem loading={loading}/>
        <fieldset className="FormGroup">
          <Field
            label="Name"
            id="name"
            type="text"
            placeholder="Jane Doe"
            required
            autoComplete="name"
            value={billingDetails.name}
            onChange={(e) => {
              setBillingDetails({...billingDetails, name: e.target.value});
            }}
          />
          <Field
            label="Email"
            id="email"
            type="email"
            placeholder="janedoe@gmail.com"
            required
            autoComplete="email"
            value={billingDetails.email}
            onChange={(e) => {
              setBillingDetails({...billingDetails, email: e.target.value});
            }}
          />
          {/*TODO: Not needed*/}
          <Field
            label="Phone"
            id="phone"
            type="tel"
            placeholder="+1 (123) 123-0123"
            required
            autoComplete="tel"
            value={billingDetails.phone}
            onChange={(e) => {
              setBillingDetails({...billingDetails, phone: e.target.value});
            }}
          />
        </fieldset>
        <fieldset className="FormGroup scpc--forms-stripecard-box">
          <div className={`scpc--forms-input-holder ${cardErrors.cardNumber ? 'has-error' : ''} `}>
            <label>Card number</label>
            <CardNumberElement
              options={options}
              onReady={() => {
              }}
              onChange={event => {
                event.error
                  ? setCardErrors({...cardErrors, cardNumber: event.error})
                  : setCardErrors({...cardErrors, cardNumber: null});
                // console.log("CardNumberElement [change]", event);
              }}
              onBlur={() => {
              }}
              onFocus={() => {
              }}
            />
            {cardErrors.cardNumber && <small
              className='scpc--forms-input-error static inline'>{cardErrors.cardNumber.message}</small>}
          </div>
          <div className={`scpc--forms-input-holder ${cardErrors.cardDate ? 'has-error' : ''}`}>
            <label>
              Expiration date</label>
            <CardExpiryElement
              options={options}
              onReady={() => {
              }}
              onChange={event => {
                event.error
                  ? setCardErrors({...cardErrors, cardDate: event.error})
                  : setCardErrors({...cardErrors, cardDate: null});
              }}
              onBlur={() => {
              }}
              onFocus={() => {
              }}
            />
            {cardErrors.cardDate &&
            <small
              className='scpc--forms-input-error static inline'>{cardErrors.cardDate.message}</small>}
          </div>
          <div className={`scpc--forms-input-holder ${cardErrors.cardDate ? 'has-error' : ''}`}>
            <label>
              CVC</label>
            <CardCvcElement
              options={options}
              onReady={() => {
              }}
              onChange={event => {
                event.error
                  ? setCardErrors({...cardErrors, cardCvc: event.error})
                  : setCardErrors({...cardErrors, cardCvc: null});
              }}
              onBlur={() => {
              }}
              onFocus={() => {
              }}
            />
            {cardErrors.cardCvc &&
            <small
              className='scpc--forms-input-error static inline'>{cardErrors.cardCvc.message}</small>}
          </div>
        </fieldset>
        {error ? showFormErrorsToast() : ''}

        <div className={`scpc--payments-box-submit`}>
          <SubmitButton processing={processing} error={error} disabled={!stripe}>
            <div className={`icon--holder button-rounded-default`}>
              <i className={`icon-0346-credit-card`}/>
            </div>
            {props.updateCard || props.card ? `Update Card` : `Add Card`}
          </SubmitButton>
        </div>
      </form>
    </div>
  );
};

const ELEMENTS_OPTIONS = {};

/**
 *  Make sure to call `loadStripe` outside of a component’s render to avoid
 *  recreating the `Stripe` object on every render.
 */

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const CardForm = (props) => {
  const user = auth0Client.getUser();
  const card = user.paymentMethod ? user.paymentMethod : null;
  const [updateCard, setUpdateCard] = useState(false);
  // console.log('user');
  // console.log(card);
  //payment_method
  const isStandalone = () => {
    return props.type === 'standalone' ? 'scpc--account-component-standalone' : '';
  }
  const renderCardType = (cardName) => {
    // console.log(cardName);
    switch (cardName) {
      case 'mastercard':
        return <path d={MasterCard.path}/>;
      case 'amex':
        return <path d={AmericanExpress.path}/>;
      case 'visa':
        return <path d={Visa.path}/>;
      default:
        return;
    }
  }
  return (
    <div className={`CardFormWrapper ${isStandalone()}`}>
      <ToastContainer/>
      {card ?
        /**
         * TODO: This should be visible only if user has defined the payment method
         */
        <>
          <div className={`scpc--card-element-stripe-info`}>
            <i className={`linear-cloud-lock`}/>
            <p>
              We <strong>do not store</strong> your credit card details. Payment data is encrypted
              and securely stored with our payment processor Stripe
            </p>
          </div>
          <div className={`scpc--card-element`}>
            <div className={`scpc--card-element-brand`}>
              <svg width={`30px`} height={`30px`}>
                {renderCardType(card.cardType)}
              </svg>
            </div>
            <div className={`scpc--card-element-number`}>
              **** **** **** {card.cardLast4}
            </div>
            <div className={`scpc--card-element-holder`}>
              {user.name}
            </div>
            <div className={`scpc--card-element-date`}>
              {card.cardExpiresMonth}/{card.cardExpiresYear}
            </div>
            <div className={`scpc--card-element-logo`}>
              <img src={`/svg/scpc-logo-white.svg`} alt={'logo'}/>
            </div>
          </div>
          <div className={`scpc--card-element-delete`}>
            {/*<button className={} onClick={() => {setUpdateCard(true);}}>*/}
            {/*  Update payment method*/}
            {/*</button>*/}

            {card && !updateCard ?
              <Button type="primary" size={`large`} shape="round" onClick={() => {
                setUpdateCard(true)
              }}>
                Update payment method
              </Button> : ''}

          </div>
        </>
        : ''}
      {
        /**
         * TODO: This should be visible only if user has NOT defined the payment method
         */
      }
      {!card || updateCard ?
        <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
          <CheckoutForm standalone={props.type} updateCard={updateCard} card={card}/>
        </Elements>
        : ''}
    </div>
  );
};

export default CardForm;