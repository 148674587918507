import React, {useContext} from 'react';
import {CampaignGroup} from "./CampaignGroup";
import _ from "lodash";
import ProjectSettingsContext from "../../../context/CF/ProjectSettingsEditor/ProjectSettingsContext";

export const ExpertMode = () => {
  const projectSettingsContext = useContext(ProjectSettingsContext);

  let campaignGroups = projectSettingsContext.getGroups();
  let canGroupsBeDeleted = _.size(campaignGroups) > 1;
  return (
    <ProjectSettingsContext.Consumer>
      {({isProjectLoading}) => (
        <>
          {isProjectLoading ? <></> :
            <>
              {_.map(campaignGroups, (campaignGroup) => {
                return (
                  <CampaignGroup
                    key={campaignGroup.id}
                    campaignGroup={campaignGroup}
                    canBeDeleted={canGroupsBeDeleted}
                  />
                );
              })}
            </>
          }
        </>
      )}
    </ProjectSettingsContext.Consumer>
  );
};