import React from 'react';
import {useHistory} from "react-router-dom";

export const Box = ({box, active, textCenter, link, click,visible}) => {
  let history = useHistory();

  return (
    <>
      <div
        className={`scpc--box ${active ? 'active' : ''} ${textCenter ? 'text-center' : ''}`}
        onClick={() => {
          if (link) {
            history.push(link);
          }
          if (click) {
            // console.log(click);
          }
        }}
        style={{'display' : visible === 'none' ? 'none' : '', 'width' : box.size === 'full' ? '100%' : '' }}
      >
        <div className="scpc--box_icon">
          <i className={box.icon}/>
        </div>
        <div className="scpc--box--title">
          <span>{box.name}</span>
        </div>
      </div>
    </>
  )
};
