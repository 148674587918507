import {getBanReasonDescription, getBanSource} from "../../Helpers/VisitsDataHelpers";
import {BAN_SOURCE_MONITOR} from "../../../../context/types";
import React from "react";
import { Alert, Card, Timeline } from 'antd';
import parse from 'html-react-parser';

/**
 * List bans for IP/fingerprint details view
 * @param params
 * @returns {*}
 * @constructor
 */
export const BansList = (params) => {
  const {bans} = params;
  if (bans.length) {
    return (<>
        <Alert
          message="Banned"
          description="This resource was banned."
          type="error"
          showIcon
        />
      <div className={`scpc--details-view-timeline mt-40 mb-40`}>
        <div className={`row`}>
          <div className={`col-lg-12 col-sm-12 col-xs-12 col-md-12`}>
        <Timeline mode={`left`}>
            {bans.map((ban) => (
              <Timeline.Item key={ban.id} label={parse(`<i class="fal fa-calendar" ></i> Banned at:<br /> ${ban.createdAt}`)}>
              <Card size="small" title={`Ban source: ${getBanSource(ban.banSource)}`} style={{ width: '100%', textAlign: 'left' }}>
                <table className={`table table-borderless table-hover`}>
                  <tbody>
                  <tr><td>Ban source</td><td>{getBanSource(ban.banSource)}</td></tr>
                  <tr><td>Banned at</td><td>{ban.createdAt}</td></tr>
                  {ban.banSource === BAN_SOURCE_MONITOR ? <>
                    <tr><td>ban expires:</td><td>{ban.expires}</td></tr></> : <></>}
                  <tr><td>Ban active: </td><td>{ban.isBanActive ? <>yes</> : <>no</>}</td></tr>
                  <tr><td>Reason: </td><td>{getBanReasonDescription(ban.reason).description}</td></tr>
                  </tbody>
                </table>
              </Card>
              </Timeline.Item>
            ))}
        </Timeline>
          </div></div>
          </div>
      </>
    );
  } else {
    return (
      <>
        {/*<div className={`container-fluid`}>*/}
        {/*  <div className={`row`}>*/}
        {/*    <div className={`col-lg-4 col-sm-12 col-md-12 col-xs-12`}>*/}
        {/*      <h5>Manual ban this resource</h5>*/}
        {/*      <Switch defaultChecked />*/}
        {/*    </div>*/}
        {/*    <div className={`col-lg-8 col-sm-12 col-md-12 col-xs-12`}>*/}
        {/*      <Alert*/}
        {/*        message="Not banned"*/}
        {/*        description="IP/Fingerprint was not added to the ban list"*/}
        {/*        type="success"*/}
        {/*        showIcon*/}
        {/*      />*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </>
    );
  }
}
