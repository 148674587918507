import React from 'react';

export const ContentBox = (props) => {
  const addShadowClass = () => {
    if (props.shadow) {
      return ` box-shadow-${props.shadow}`;
    }
    return '';
  };
  const addRadiusClass = () => {
    if (props.rounded) {
      return ` box-rounded-${props.rounded}`;
    }
    return '';
  };
  const addPaddingClass = () => {
    if (props.padding) {
      return ` box-padding-${props.padding}`;
    }
    return '';
  };
  const addGradientClass = () => {
    if (props.gradient) {
      return ` with-gradient`;
    }
    return '';
  }
  const addLeftGradientClass = () => {
    /**
     * Options:
     * blue / green / orange / darkblue / inverseblue
     */
    const ns = 'left-gradient';
    if (props.leftGradient) {
      return `${ns} ${ns}-${props.leftGradient}`;
    }
  }
  const addMarginClass = () => {
    if (props.margin) {
      return props.margin;
    }
    return '';
  }
  return (
    <>
      <div
        className={
          `scpc--contentbox 
          ${addShadowClass()} 
          ${addRadiusClass()} 
          ${addPaddingClass()} 
          ${addGradientClass()} 
          ${addLeftGradientClass()}
          ${addMarginClass()}`
        } style={props.style}>
        {props.children}
      </div>
    </>
  );
};
export const ContentBoxHeader = (props) => {
  return (
    <>
      <div className={`scpc--contentbox-header`}>
        <div className={`scpc--contentbox-header-left`}>
          <h3>
            {props.title}
          </h3>
        </div>
        <div className={`scpc--contentbox-header-right`}>
          {props.children}
        </div>
      </div>
    </>
  );
};
export const ContentBoxFooter = (props) => {
  return (
    <>
      <div className={`scpc--contentbox-footer`}>
        <div className={`scpc--contentbox-footer-inner`}>
          <div className={`scpc--contentbox-footer-inner-left`}>
            {props.children.filter((child) => child.props.type === 'left').map((child) => child)}
          </div>
          <div className={`scpc--contentbox-footer-inner-right`}>
            {props.children.filter((child) => child.props.type === 'right').map((child) => child)}
          </div>
        </div>
      </div>
    </>
  )
};