import React, {useState, useEffect} from 'react';
import Scrollbar from "react-scrollbars-custom";
import {ContentBox, ContentBoxFooter, ContentBoxHeader} from '../box/ContentBox';
import {Switch} from 'antd';
import {Button} from '../../layout/Button';
import auth0Client from "../../../Auth";
import {useForm} from "react-hook-form";
import {LoaderElem} from "../Loader";
import * as UsersApi from '../../../api/users';
import {toast, ToastContainer} from "react-toastify";

export const NotificationsInner = (props) => {
  const user = auth0Client.getUser();
  const {handleSubmit, register, setValue} = useForm();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // hack: https://stackoverflow.com/questions/58703615/react-hook-form-with-antd-styling
    // (example from hook form creator doesn't work as onClick has different params, but the idea is the same)
    register({name: 'settings.emails.cfEvents'});
    register({name: 'settings.emails.weeklyReports'});
    register({name: 'settings.emails.cfInsights'});

    // ok this is getting ridiculous
    setValue('settings.emails.cfEvents', user.settings.emails.cfEvents);
    setValue('settings.emails.weeklyReports', user.settings.emails.weeklyReports);
    setValue('settings.emails.cfInsights', user.settings.emails.cfInsights);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = data => {
    //setLoading(true);
    UsersApi.updateUserAccount(data).then(res => {
      auth0Client.setUser(res.user);

      toast.success('Your settings has been saved', {
        position: "top-right",
        closeOnClick: true,
        pauseOnHover: true,
      });

      setLoading(false);
    });
  }

  return (
    <>
      <ToastContainer/>
      <div className={`mt-30`}></div>
      <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
        <LoaderElem loading={loading}/>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContentBoxHeader title={`Notifications settings`}>
          </ContentBoxHeader>
          <div className={`scpc--contentbox-switcherrow`}>
            <Switch
              className={`scpc--controls-switch`}
              name={`emails.cfEvents`}
              defaultChecked={user.settings.emails.cfEvents}
              onChange={(checked) => setValue('settings.emails.cfEvents', checked)}
            />
            <span>Check if you want to receive notifications from SafeCPC</span>
          </div>
          <div className={`scpc--contentbox-switcherrow mt-30`}>
            <Switch
              className={`scpc--controls-switch`}
              name={`settings.emails.weeklyReports`}
              defaultChecked={user.settings.emails.weeklyReports}
              onChange={(checked) => setValue('settings.emails.weeklyReports', checked)}
            />
            <span>Check if you want to receive weekly reports via e-mail</span>
          </div>
          <div className={`scpc--contentbox-switcherrow mt-30`}>
            <Switch
              className={`scpc--controls-switch`}
              defaultChecked={user.settings.emails.cfInsights}
              onChange={(checked) => setValue('settings.emails.cfInsights', checked)}
            />
            <span>Check if you want to receive insights about your campaigns</span>
          </div>
          <ContentBoxFooter>
            <div type={`left`}>
              <button className={`scpc--contentbox-needhelpbtn`}>
                <i className={`linear-information`}/> Need help?
              </button>
            </div>
            <div type={`right`}>
              <Button
                type={`submit`}
                icon={`linear-checkmark-circle`}
                color={`blue`}
                flat={true}
                rounded={`default`}
                style={{
                  marginLeft: 25
                }}
              >
                Update settings
              </Button>
            </div>
          </ContentBoxFooter>
        </form>
      </ContentBox>
    </>
  )
}

export const Notifications = (props) => {
  return (
    <>
      {!props.standalone ?
        <Scrollbar disableTracksWidthCompensation
                   style={{position: "", 'height': '100vh', 'padding': '40px'}}>
          <div style={{'width': '100%', 'padding': '20px'}}>
            <h1 className={`mt-10 mb-60`}>Notifications</h1>
            <NotificationsInner/>
          </div>
        </Scrollbar>
        : <NotificationsInner/>
      }
    </>
  )
};