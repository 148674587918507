import React from 'react';

export const RightNavBox = ({box, active}) => {
  return (
    <>
      <div className={`scpc--right-nav-box ${active ? 'active' : ''}`}>
        <div className="scpc--right-nav-box_icon">
          <i className={box.icon}/>
        </div>
        <div className="scpc--right-nav-box--title">
          <span>{box.name}</span>
        </div>
      </div>
    </>
  )
};
