import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import routes, { getRoute } from '../../../routes/routes';
import { Badge } from 'antd';
export const DomainSelectorItem = ({project, setActiveProject, status}) => {
  const [elemActive, setElemActive] = useState(false);
  const history = useHistory();

  const isAnyActive = (project) => {
    if(!localStorage.activeProjectId){
      setActiveProject(project.id, project.domain);
    }
  }
  const gotoEdit = (projectId) => {
    history.push(getRoute(routes.projectSettings, {projectId}));
  }
  return (
    <>
      {isAnyActive(project)}
    <li key={project.id} className={elemActive ? 'active' : ''}>
      <div className="scpc--forms-select-input-elem-wrapper">
        <div
          className="scpc--forms-select-input-dropdown-elem"
          onClick={() => setActiveProject(project.id, project.domain)}>

          {status === 'setup' ? <Badge count={'Setup'} style={{'marginRight':'10px'}}/> : <i className="icon-web"/>}
          <span>{project.domain}</span>

        </div>

        <div
          className="scpc--forms-select-input-dropdown-actions">
          <button
            className="scpc--forms-select-toggleActions"
            onClick={() => setElemActive(!elemActive)}>
            <i className="icon-dot"/>
            <i className="linear-chevron-right-circle"/>
          </button>
        </div>
        <div
          className="scpc--forms-select-input-dropdown-actionsElement">
          <button
            className="scpc--forms-select-action-edit"
            onClick={() => { gotoEdit(project.id) }}
          >
            <i className="icon-document-edit"/>
          </button>
          {/*<button*/}
          {/*  className="scpc--forms-select-action-delete">*/}
          {/*  <i className="icon-cross-circle"/>*/}
          {/*</button>*/}
        </div>
      </div>
    </li>
      </>
  );
};