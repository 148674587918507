import React from 'react';
import notificationsTooltipBox from "../../../svg/notifications-tooltip.svg";
import ReactHtmlParser from 'react-html-parser';
import {Link} from 'react-router-dom';
import routes, {getRoute} from "../../../routes/routes";
import NotificationsContext from "../../../context/Notifications/NotificationsContext";
import {Tag} from 'antd';

export const NotificationsTooltip = ({notificationsList, activeNotifications, projectId}) => {
  const ns = 'scpc--notifications-tooltip';
  const nsc = (c) => ns + c;
  // eslint-disable-next-line no-unused-vars
  const getBrowserIcon = (browser) => {
    switch (browser.toLowerCase()) {
      case 'chrome':
        return (ReactHtmlParser('<i class="fab fa-chrome" />'));
      case 'firefox':
        return (ReactHtmlParser('<i class="fab fa-firefox" />'));
      case 'safari':
        return (ReactHtmlParser('<i class="fab fa-safari" />'));
      case 'opera':
        return (ReactHtmlParser('<i class="fab fa-opera" />'));
      default:
        return (<></>);
    }
  };
  const notificationHeaderRender = (headerType, {content}) => {
    switch (headerType) {
      case 'paused_under_attack':
        return (
          <>
            <div className={nsc('-single-box-header')}>
              <i className="fal fa-pause-circle" /> Paused under attack
            </div>
            <div className={nsc('-single-box-header-elements')}>
              {/*<div data-tip={content.browserVersion}>*/}
              {/*  <ReactTooltip/>*/}
              {/*  {getBrowserIcon(content.browser)}*/}
              {/*</div>*/}
              {/*<div data-tip={content.mobile ? 'Mobile' : 'Desktop'}>*/}
              {/*  <i className={content.mobile ? 'icon-0422-phone' : 'icon-0432-desktop'}/>*/}
              {/*  <ReactTooltip/>*/}
              {/*</div>*/}
              {/*<div data-tip={content.vpn ? 'VPN' : ''}>*/}
              {/*  {content.vpn ? ReactHtmlParser('<i class="icon-globe-blocked" />') : ''}*/}
              {/*  <ReactTooltip/>*/}
              {/*</div>*/}
            </div>
          </>
        );
      case 'new_ban':
        return (
          <div className={nsc('-single-box-header')}>
            <i className="fal fa-ban" /> <span>New ban</span>
          </div>
        );
      case 'trialReminder':
        return (
          <div className={nsc('-single-box-header')}>

          </div>
        );
      default:
        return (
          <div className={nsc('-single-box-header')}>
            {content.title}
          </div>
        );
    }
  };
  const notificationContentRender = (notification) => {
    const {type, content} = notification;
    let projectId = notification.cfProjectId;
    switch (type) {
      case 'paused_under_attack':
        return (
          <div className={nsc('-single-box-content')}>
            Attack detected, campaigns in
            <Link to={getRoute(routes.project, {projectId})}>
              {notification.cfProject.domain}
            </Link>
            were paused until {content.pausedUntil}
          </div>
        );

      case 'new_ban':
        return (
          <div className={nsc('-single-box-content blocked')}>
            <div className={nsc('-single-box-content-blocked')}>
              <span className={nsc('-single-box-date')}><i className="fal fa-calendar" />{notification.createdAt}</span>
              <div className={`innameHolder`}>
                <Link to={getRoute(routes.project, {projectId})}>
                  <span>Project: </span> {notification.cfProject.domain}
                </Link>
              </div>
              {content.bans.map((ban) => {
                  if (ban.type === 'ip') {
                    return (<div key={ban.ip} className={nsc('-single-box-content-ip')}>
                        <span className={`scpc--device-id-notification`}><i className="fal fa-fingerprint" /><span>IP:  {ban.ip}</span></span>
                        <strong>
                          <Link to={getRoute(routes.analyticsDetailsByIp, {projectId, ip: ban.ip})}>
                            Show IP details
                          </Link>
                        </strong>
                      </div>
                    )
                  } else {
                    return (
                      <div key={ban.fingerprint} className={nsc('-single-box-content-ip')}>
                        <span className={`scpc--device-id-notification`}><i className="fal fa-fingerprint" /><span>Device ID:  {ban.fingerprint}</span></span>
                        <strong>
                          <Link to={getRoute(routes.analyticsDetailsByFingerprint, {
                            projectId,
                            fingerprint: ban.fingerprint
                          })}>
                           Show device details
                          </Link>
                        </strong>
                      </div>
                    )
                  }
                }
              )}
            </div>
          </div>
        );

      case 'trend':
        return (
          <div className={nsc('-single-box-content')}>
            <div className={nsc('-single-box-content-trend')}>
              <span>Campaign:</span><br/>
              <strong>{content.campaignName}</strong><br/>
              {ReactHtmlParser(content.trendContent)}
            </div>
          </div>
        );
      case 'trialReminder':
        return (
          <div className={nsc('-single-box-content')}>
            <div className={nsc('-single-box-content-trial')}>
              <i className={content.icon}/>
              <div className={nsc('-single-box-content-trial-text')}>
                <strong>
                  {content.title}
                </strong>
                <p>
                  {content.text}
                </p>
              </div>
            </div>
          </div>
        );
      default:
        return ('<>--</>');
    }
  };
  return (
    <NotificationsContext.Consumer>
      {({isLoading, markNotificationsRead}) => (
        isLoading ? <></> :
          <div className={`${ns} ${activeNotifications ? ' active' : ''}`}>
            <img src={notificationsTooltipBox} className={nsc('-bgelem')} alt={`notifications`}/>
            <div className={nsc('-scrollholder')}>
              {
                notificationsList.map((notification) => {
                  return (
                    <div className={nsc('-single') + ' ' + notification['type']} key={notification.id}>
                      <div className={nsc('-single-icon')}>
                        {notification.type === 'new_ban' ? <i className="fal fa-ban" /> :
                          <i className="fal fa-pause-circle" />}
                      </div>

                      {/* TODO: @alex here's the flag indicating if notification was read */}
                      {!notification.isRead ? <><Tag color={`${notification.type === 'new_ban' ? '#ff1075' : '#2db7f5'}`} className={`newTag`}>New</Tag></> : <></>}

                      <div className={nsc('-single-box')}>
                        {notificationHeaderRender(notification.type, notification)}
                        {notificationContentRender(notification)}
                      </div>
                    </div>
                  );
                })
              }
            </div>
            <div className={nsc('-markread')}>
              <button onClick={() => markNotificationsRead(projectId)}>
                Mark all read
              </button>
              {/*<Link to={'#'}>*/}
              {/*  Clear notifications*/}
              {/*</Link>*/}
            </div>
          </div>
      )}
    </NotificationsContext.Consumer>
  );
};