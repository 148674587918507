import React from 'react';
import {Invoices} from '../RightNavigation/Invoices';

export const ManageInvoices = (props) => {

  return (
    <>
      <div className={`container-fluid`}>
        <div className={`row justify-content-center`}>
          <div className={`col-lg-8 col-md-10 col-sm-12 col-xs-12`}>
            <div className={`scpc--account-baner`}>
              <div className={`scpc--account-baner-content`}>

                <h4>Manage invoices</h4>
                <p>
                  You can manage your invoices from here. You can search, export, print them from the beginning of our
                  work together.
                </p>
              </div>
              <img
                src={`/svg/invoices.svg`}
                className={`scpc--account-baner-image`}
                alt={`Payments baner`}
              />
            </div>
            <Invoices standalone={props.type === 'standalone'}/>
          </div>
        </div>
      </div>
    </>
  );
}