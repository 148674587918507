import React from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { uuid } from 'uuidv4';
import { Box } from '../box/Box';

export const SupportContents = [
  {
    box: {
      'id': uuid(),
      'icon': 'linear-server',
      'name': 'System informations',
      'active': false,
      'link': '/account'
    }
  },
  {
    box: {
      'id': uuid(),
      'icon': 'linear-hammer-wrench',
      'name': 'How it works',
      'active': false,
      'link': '/account/add-payment-method'
    }
  },
  {
    box: {
      'id': uuid(),
      'icon': 'linear-credit-card',
      'name': 'Billing and Invoices',
      'active': false,
      'link': '/account/add-payment-method'
    }
  },
  {
    box: {
      'id': uuid(),
      'icon': 'linear-chip',
      'name': 'Setup and Sripts',
      'active': false,
      'link': '/account/add-payment-method'
    }
  },
  {
    box: {
      'id': uuid(),
      'icon': 'linear-earth-lock',
      'name': 'Blocking rules',
      'active': false,
      'link': '/account/add-payment-method'
    }
  },
  {
    box: {
      'id': uuid(),
      'icon': 'linear-pause-circle',
      'name': 'Pause rules',
      'active': false,
      'link': '/account/add-payment-method'
    }
  },
  {
    box: {
      'id': uuid(),
      'icon': 'linear-bubble',
      'name': 'Notifications info',
      'active': false,
      'link': '/account/add-payment-method'
    }
  },

  {
    box: {
      'id': uuid(),
      'icon': 'linear-file-charts',
      'name': 'Reports setup',
      'active': false,
      'link': '/account/add-payment-method'
    }
  },

  {
    box: {
      'id': uuid(),
      'icon': 'linear-magic-wand',
      'name': 'Best practices',
      'active': false,
      'link': '/account/add-payment-method'
    }
  }
]

export const SupportInner = (props) => {

  return (
    <>
    <div
      className={`scpc--support-holder ${props.standalone ? 'scpc--support-holder-standalone' : ''}`}>
      {SupportContents.map(support => {
        return (
          <Box
            box={support.box}
            active={support.box.active}
            cols={2}
            key={support.box.id}
            setActiveBox={support.box.active}
            link={support.box.link}
            textCenter={true}
          />
        )
      })
      }
    </div>
</>
)
  ;
}

export const Support = (props) => {
  return (
    <>
      {props.type !== 'standalone' ?
        <>

          <Scrollbar disableTracksWidthCompensation
                     style={{ position: "", 'height': '100vh', 'padding': '40px' }}>
            <div style={{ 'width': '100%', 'padding': '20px' }}>
              <h1 className={`mt-15`}>Support</h1>
              <div className={`scpc--card-element-stripe-info mb-50 mt-15`}>
                <i className={`icon-help`}/>
                <p>
                  We have collected most frequent questions, and we've answered them as much as it was possible. You can browse every topic, and seek the answer you need. We've recorded instruction videos for you, which should make the whole process easier.
                </p>
              </div>
              <SupportInner/>
            </div>
          </Scrollbar>
        </>
        :
        <>
          <div className={`scpc--card-element-stripe-info mb-40 mt-15`}>
            <i className={`icon-help`}/>
            <p>
              We have collected most frequent questions, and we've answered them as much as it was possible. You can browse every topic, and seek the answer you need. We've recorded instruction videos for you, which should make the whole process easier.
            </p>
          </div>
          <SupportInner standalone={true}/>
          </>
      }
    </>
  )
};