import {apiGET, apiPATCH} from './api';

export const removeFirstLoginMeta = async (userId) => {
  return apiPATCH(`/core/users/${userId}`, {pretend: true});
};

export const updateUserAccount = async (details) => {
  return apiPATCH(`/core/user`, details);
};

export const getInvoices = async () => {
  return apiGET('/core/account/invoices');
}

export const getNotifications = async (afterId, projectId) => {
  return apiGET('/core/user/notifications', {afterId, projectId});
}

export const markNotificationsRead = async (projectId) => {
  return apiPATCH('/core/user/notifications/read', {projectId});
}
