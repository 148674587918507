import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App.js';
import * as serviceWorker from './serviceWorker';
import history from "./utils/history";
import {BrowserRouter as Router} from "react-router-dom";

ReactDOM.render(
    <Router history={history}>
    <App />
    </Router>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

//
// const config = { attributes: true, childList: true, subtree: true };
//
// // Callback function to execute when mutations are observed
// const callback = function(mutationsList, observer) {
//     for(let mutation of mutationsList) {
//         if (mutation.type === 'attributes') {
//
//         }
//     }
// };
//
// const observer = new MutationObserver(callback);
// const updateApexCharts = () => {
//     let lines = document.querySelectorAll('.apexcharts-line');
//     console.log(lines);
//     if(lines.length){
//         window.clearInterval(updateInterval);
//         updateInterval = null;
//         for(let i = 0; i < lines.length; i++){
//             // observer.observe(lines[i], config);
//             let clipPath = lines[i].getAttribute('clip-path');
//             if(clipPath) {
//                 console.log(clipPath);
//                 lines[i].setAttribute('style', `-webkit-clip-path:${clipPath}`)
//             }
//             // console.log();
//         }
//     }
// }
//
// let updateInterval = setInterval(() => {
//     updateApexCharts();
// },1000);

