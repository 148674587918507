import React, {useContext} from 'react';
import ProjectSettingsContext from "../../../context/CF/ProjectSettingsEditor/ProjectSettingsContext";
import {CampaignGroup} from "./CampaignGroup";

export const StandardMode = (props) => {
  const projectSettingsContext = useContext(ProjectSettingsContext);
  let campaignGroup = projectSettingsContext.getGroups()[0];
  return (
    <ProjectSettingsContext.Consumer>
      {({isProjectLoading}) => (
        <>
          {isProjectLoading ? <></> :
            <CampaignGroup
              key={campaignGroup.id}
              campaignGroup={campaignGroup}
              canBeDeleted={false}
              standardMode={true}
            />
          }
        </>
      )}
    </ProjectSettingsContext.Consumer>
  );
}
