import React from 'react';
import {ContactUs} from '../RightNavigation/ContactUs';

export const ShowContact = (props) => {

  return (
    <>
      <div className={`container-fluid`}>
        <div className={`row justify-content-center`}>
          <div className={`col-lg-8 col-md-10 col-sm-12 col-xs-12`}>
            <div className={`scpc--account-baner`}>
              <div className={`scpc--account-baner-content`}>

                <h4>Need assistance?</h4>
                <p>
                  Here you can find answers for the most frequent questions.
                  However, if you can't find answer, we will be more than happy to assist you through our Contact Center or Live Chat.
                </p>
              </div>
              <img
                src={`/svg/contact.svg`}
                className={`scpc--account-baner-image`}
                alt={`Payments baner`}
              />
            </div>
            <ContactUs type={`standalone`} />
          </div>
        </div>
      </div>
    </>
  );
}