import React from 'react';
import auth0Client from '../../../Auth';
import {uuid} from 'uuidv4';
import {LeftMainBoxes} from '../appLayout/LeftMainBoxes';
import {RightContent} from '../appLayout/RightContent';
import {AddPaymentMethod} from './AddPaymentMethod';
import {ManageNotifications} from './ManageNotifications';
import {ManageInvoices} from './ManageInvoices';
// import {Notifications} from '../RightNavigation/Notifications';
import {MyAccount} from '../RightNavigation/MyAccount';
import {ShowSupport} from './ShowSupport';
import {ShowContact} from './ShowContact';
import routes, {getRoute} from '../../../routes/routes';

export const AccountInner = (props) => {
  return (
    <>
      <div className={`container-fluid`}>
        <div className={`row justify-content-center`}>
          <div className={`col-10 scpc--account-col`}>
            <div className={`scpc--account-baner`}>
              <div className={`scpc--account-baner-content`}>

                <h4>Your Account</h4>
                <p>
                  You can edit yours account details from here.
                  If you want to receive invoice please provide yours company
                  data with the Tax ID
                </p>
              </div>
              <img
                src={`/svg/account.svg`}
                className={`scpc--account-baner-image`}
                alt={`Payments baner`}
              />
            </div>
            <MyAccount type={'standalone'}/>
          </div>
        </div>
      </div>
    </>
  )
}

export const Account = (props) => {
  const user = auth0Client.getUser();
  const leftNavBoxes = [
    {
      box: {
        'id': uuid(),
        'icon': 'icon-user',
        'name': 'My Account',
        'active': props.load === 'account',
        'link' : getRoute(routes.account)
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'icon-0346-credit-card',
        'name': 'Payments',
        'active': props.load === 'payments',
        'link' : getRoute(routes.addPaymentMethod)
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'icon-bell',
        'name': 'Notifications',
        'active': props.load === 'notifications',
        'link' : getRoute(routes.manageNotifications)
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'icon-0217-document',
        'name': 'Invoices',
        'active': props.load === 'invoices',
        'link' : getRoute(routes.manageInvoices)
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'icon-help',
        'name': 'Support',
        'active': props.load === 'support',
        'link': getRoute(routes.support)
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'icon-0152-envelope-open',
        'name': 'Contact Us',
        'active': props.load === 'contact',
        'link': getRoute(routes.contact)
      }
    }
  ];
  const accountRouteComponent = (whichComponent) => {
    switch (whichComponent) {
      case 'payments':
        return <AddPaymentMethod/>;
      case 'notifications':
        return <ManageNotifications type={`standalone`}/>;
      case 'invoices':
        return <ManageInvoices type={`standalone`}/>;
      case 'account':
        return <AccountInner type={`standalone`}/>;
      case 'support':
        return <ShowSupport type={`standalone`}/>;
      case 'contact':
        return <ShowContact type={`standalone`}/>;
      default:
        break;
    }
  }
  return (
    <>
      <LeftMainBoxes
        boxes={leftNavBoxes}
        cols={2}
        boxTextCenter={true}
        savedWithUs={true}
      >
        <div className={`scpc--user-avatarbox`}>
          <img src={`/images/user.png`} alt={`user`}/>
          <div className={`scpc--user-avatarbox-circle`}/>
          <div className={`scpc--user-avatarbox-inner`}>
            {user && user.givenName ?
              <h4>Hi, {user.givenName}!</h4> : ''}
            <p>
              You can manage your entire account from here, however, if you need any assistance, or
              have any questions feel free to ask through the Contact Center.
            </p>
          </div>
        </div>

      </LeftMainBoxes>
      <RightContent className={`scpc--user-right-content`}>
        {accountRouteComponent(props.load)}
      </RightContent>
    </>
  );
}