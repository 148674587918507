import React from 'react';

export const Modal = ({modalActiveStatus, modalClose, children}) => {
  const ns = 'scpc--modal';
  const nsc = (c) => ns + c;
  return (
    <>
      <div className={nsc('-backdrop') + `${modalActiveStatus ? ' active' : ''}`} onClick={() => modalClose()}/>
      <div className={nsc('-window') + `${modalActiveStatus ? ' active' : ''}`}>
        <div className={nsc('-close-button')} onClick={() => modalClose()}>
          <i className="linear-cross"/>
        </div>
        {children}
      </div>
    </>
  );
};