import React from 'react';
import {ReportsLeft} from '../Reports/ReportsLeft';
import {ReportGenerator} from './ReportGenerator';

export const Reports = (props) => {

  const reportColumns = [
    {
      'name': 'IP Address',
      'id': 'ip'
    },
    {
      'name': 'Device Fingerprint',
      'id': 'fingerprint'
    },
    {
      'name': 'Google Click ID',
      'id': 'gclid'
    },
    {
      'name': 'Country',
      'id': 'country'
    },
    {
      'name': 'Device ID',
      'id': 'device_id'
    },
    {
      'name': 'City',
      'id': 'city'
    },
    {
      'name': 'Ad Placement',
      'id': 'ad_placement'
    },
    {
      'name': 'Campaign ID',
      'id': 'adwords_campaign_id'
    },
    {
      'name': 'Campaign Group ID',
      'id': 'adwords_ad_group_id'
    },
    {
      'name': 'Browser Agent',
      'id': 'browser_agent'
    },
    {
      'name': 'Keywords',
      'id': 'keywords'
    },
    {
      'name': 'URL',
      'id': 'url'
    }
  ];

  const ReportsComponent = (load) => {
    switch (load) {
      case 'click-fraud':
        return <ReportGenerator
          title={`Click fraud analytics report`}
          reportColumns={reportColumns}
          simpleReportColumnsIds={['ip', 'fingerprint', 'gclid', 'adwords_campaign_id', 'url']}
          load={props.load}
        />;

      case 'devices-analytics':
        return <ReportGenerator
          title={`Device analytics report`}
          reportColumns={reportColumns}
          simpleReportColumnsIds={['ip', 'fingerprint', 'gclid', 'adwords_campaign_id', 'url']}
          load={props.load}
        />;

      case 'ip-analytics':
        return <ReportGenerator
          title={`IP analytics report`}
          reportColumns={reportColumns}
          simpleReportColumnsIds={['ip', 'fingerprint', 'gclid', 'adwords_campaign_id', 'url']}
          load={props.load}
        />;

      case 'ip-groups':
        return <ReportGenerator
          title={`IP groups analytics report`}
          reportColumns={reportColumns}
          simpleReportColumnsIds={['ip', 'fingerprint', 'gclid', 'adwords_campaign_id', 'url']}
          load={props.load}
        />;

      case 'fraud-country':
        return <ReportGenerator
          title={`Country analytics report`}
          reportColumns={reportColumns}
          simpleReportColumnsIds={['country', 'ip', 'fingerprint', 'gclid', 'adwords_campaign_id', 'url']}
          load={props.load}
        />;

      case 'keywords':
        return <ReportGenerator
          title={`Keywords analytics report`}
          reportColumns={reportColumns}
          simpleReportColumnsIds={['keywords', 'ip', 'fingerprint', 'gclid', 'adwords_campaign_id', 'url']}
          load={props.load}
        />;
      default:
        return <ReportsLeft load={props.load}/>
    }
  }
  return (<>{ReportsComponent(props.load)}</>);
}