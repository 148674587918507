import React from 'react';
import {LeftMainBoxes} from '../appLayout/LeftMainBoxes';
import {uuid} from 'uuidv4';
import { useParams} from 'react-router-dom';
import routes, {getRoute} from '../../../routes/routes';

export const AnalyticsLeft = (props) => {
  // let location = useLocation();
  let {projectId} = useParams();

  /**
   *  Visual related settings
   */
  const leftNavBoxes = [
    {
      box: {
        'id': uuid(),
        'icon': 'linear-mouse-left',
        'name': 'Click Fraud Analytics',
        'link' : getRoute(routes.analyticsClickFraud, {projectId}),
        'active' : props.load === 'click-fraud',
        'size' : 'full'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-desktop',
        'name': 'Devices analytics',
        'link' : getRoute(routes.analyticsDeviceAnalytics, {projectId}),
        'active' : props.load === 'device-analytics'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-radar',
        'name': 'IP Analytics',
        'link' : getRoute(routes.analyticsIPAnalytics, {projectId}),
        'active' : props.load === 'ip-analytics'
      }
    },
    // {
    //   box: {
    //     'id': uuid(),
    //     'icon': 'linear-radar',
    //     'name': 'IP Range Analytics',
    //     'link': getRoute(routes.analyticsIPGroupAnalytics, {projectId}),
    //     'active': props.load === 'ip-range-analytics'
    //   }
    // },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-location',
        'name': 'Fraud by country analytics',
        'link' : getRoute(routes.analyticsFraudCountry, {projectId}),
        'active' : props.load === 'fraud-country-analytics'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-feather',
        'name': 'Keyword fraud analytics',
        'link' : getRoute(routes.analyticsKeywords, {projectId}),
        'active' : props.load === 'keywords-analytics'
      }
    }
    // {
    //   box: {
    //     'id': uuid(),
    //     'icon': 'linear-battery-low-3',
    //     'name': 'AdGroup level analytics',
    //     'link' : getRoute(routes.analyticsAdGroup, {projectId}),
    //     'active' : props.load === 'adgroup'
    //   }
    // },
    // {
    //   box: {
    //     'id': uuid(),
    //     'icon': 'linear-battery-full',
    //     'name': 'Campaign level analytics',
    //     'link' : getRoute(routes.analyticsCampaign, {projectId}),
    //     'active' : props.load === 'campaign'
    //   }
    // }
  ];
  return (
    <>
      <LeftMainBoxes cols={2} boxTextCenter={true} boxesSize={'full'} boxes={leftNavBoxes}>
        <div className={`mt-20`}>
          <div className={`scpc--reports-baner`}>
            <img src={`/svg/reports.svg`} alt={`reports`}/>
            <h4>Analytics Center</h4>
            <p>
              Here you have access to the full analytics of your account. You can browse devices, IPs and many more
            </p>
          </div>

          {/*<ReactApexChart options={donutChart.options} series={donutChart.series} type="area" height={160} />*/}
        </div>
      </LeftMainBoxes>
    </>
  );
}