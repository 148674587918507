import React, {useState, useEffect} from 'react';
import * as ProjectsApi from '../../../api/projects';
import { Button } from 'antd';

const InstallTrackingCode = (props) => {
  const {nextStep, prevStep, state} = props;
  const [trackingCode, setTrackingCode] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const submit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    nextStep();
  };

  useEffect(() => {
    ProjectsApi.getEmbedCode(state.cfProject.id).then((res) => {
      setTrackingCode(res.embedCode);
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="wizard_right-step--3">
      <div className="wizard_right-content">
        <div className="container">
          <div className="row">
            <div className="col-lg-8">
              <div className="wizard_right-content--header">
                <h3>Installation</h3>
                <p>
                  This is a tracking code for your domain. Please paste it right after the
                  opening <span className="note-text">&lt;body&gt;</span> tag on every page on your website.
                </p>
              </div>
            </div>
            <div className="col-lg-4">
              &nbsp;
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-lg-8">

              <div className="wizard_right-content--inner">
                <div className="pre--code-box-holder">
                  <code className="pre--code-box">
                    {trackingCode}
                  </code>
                </div>
                <div className="wizard_right-content--code-box-buttons">
                  <button className="scpc--button-border button-rounded-default color-blue width-auto">
                    Copy to clipboard
                  </button>

                </div>
              </div>
            </div>
            <div className="col-lg-4">
              <button className="scpc--button-flat button-rounded-default color-gray text-center stacked">
                <img src="/images/wordpress.png" alt={`wordpress`}/>
                <span>Use Wordpress plugin</span>
              </button>
              <button className="scpc--button-flat button-rounded-default color-gray text-center stacked">
                <img src="/images/gtm.png" alt={`gtm`}/>
                <span>Use Google Tag Manager</span>
              </button>
              <button className="scpc--button-border button-rounded-default color-blue width-auto text-center">
                Verify installation
              </button>
            </div>
          </div>
        </div>
        <div className="container wizard_right-step--3-sendInstructions">
          <div className="row">
            <div className="col-lg-8">
              <div className="wizard_right-content--header">
                <h3>Send this instruction to your developer</h3>
                <div className="wizard-send--box-holder">
                  <div className="wizard-send--input-holder">
                    <input type="text"
                           className="wizard-send--input scpc--forms_text-input color-gray rounded-default"
                           placeholder="developer@example.com"/>
                    <button className="scpc--button-border button-rounded-default color-blue button-small">Send</button>
                  </div>

                </div>
              </div>
            </div>
            <div className="col-lg-4">
              &nbsp;
            </div>
          </div>
        </div>
        <div className="wizard_right-content--buttons">
          <div className="container-fluid">
            <div className="row">
              <div className="col-md-5"></div>
              <div className="col-md-7">
                <div className="wizard_right-content--buttons-inner">
                  <button onClick={prevStep}
                     className="scpc--button-border button-open button-border color-gray button-rounded">
                    Go back
                  </button>
                  {/*<a onClick={submit} className="scpc--button blue-gradient button-rounded">*/}
                  {/*  Next step*/}
                  {/*</a>*/}
                  <Button type="primary" loading={isLoading} onClick={submit} shape="round" size={`large`}>Next step</Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InstallTrackingCode;