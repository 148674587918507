import React, {useState} from 'react';
import {LeftMainBoxes} from '../appLayout/LeftMainBoxes';
// import {uuid} from 'uuidv4';
// import {useLocation, useParams} from 'react-router-dom';
// import routes, {getRoute} from '../../../routes/routes';
// import {Alert, Result} from 'antd';
import {Button} from 'antd';
import {LockOutlined} from '@ant-design/icons';
import * as ProjectApi from "../../../api/projects";

export const DetailsLeft = (props) => {
  let [buttonLoading, setButtonLoading] = useState(false);
  const renderBanButton = (fingerprint, ip) => {
    let label = fingerprint ? 'Ban Fingerprint' : 'Ban IP';
    return <Button block
                   danger
                   large
                   shape="round"
                   icon={<LockOutlined/>}
                   type={`primary`}
                   loading={buttonLoading}
                   onClick={
                     () => {
                       setButtonLoading(true);
                       if (fingerprint) {
                         ProjectApi.manuallyBanFingerprint(props.projectID,
                           props.fingerprint).then((resp) => {
                           setTimeout(() => {
                             window.location.reload();
                           }, 300);
                         })
                       }
                       if (ip) {
                         ProjectApi.manuallyBanIp(props.projectID,
                           props.ip).then((resp) => {
                           setTimeout(() => {
                             window.location.reload();
                           }, 300);
                         });
                       }
                     }
                   }

    >{label}</Button>;
  }

  const renderUnbanButton = (fingerprint, ip) => {
    let label = fingerprint ? 'Remove Fingerprint Ban' : 'Remove IP Ban';
    return <Button block
                   danger
                   large
                   shape="round"
                   icon={<LockOutlined/>}
                   type={`primary`}
                   loading={buttonLoading}
                   onClick={
                     () => {
                       setButtonLoading(true);
                       if (fingerprint) {
                         ProjectApi.manuallyUnbanFingerprint(props.projectID,
                           props.fingerprint).then((resp) => {
                           setTimeout(() => {
                             window.location.reload();
                           }, 300);
                         })
                       }
                       if (ip) {
                         ProjectApi.manuallyUnbanIp(props.projectID,
                           props.ip).then((resp) => {
                           setTimeout(() => {
                             window.location.reload();
                           }, 300);
                         });
                       }
                     }
                   }
    >{label}</Button>;
  }

  return (
    <>
      <LeftMainBoxes cols={2} boxTextCenter={true} boxesSize={'full'}>
        <div className={`mt-20`}>
          <div className={`scpc--user-avatarbox scpc--user-avatarbox-animation`}
               style={{'paddingTop': '100px'}}>
            <div className={`scpc--user-avatarbox-circle avatarbox-animate`}
                 style={{'overflow': 'hidden'}}>
              {props.bans.length ?
                <lord-icon animation="auto" palette="#ff60a9;#ff0248"
                           src="/lordicons/25-error-cross-outline.json"/>
                : <lord-icon animation="auto" palette="#0296ff;#313971"
                             src="/lordicons/500-fingerprint-security-outline.json"/>}

            </div>
            <div className={`scpc--user-avatarbox-inner ${props.bans.length ? 'banned' : ''}`}>
              {props.bans.length ?
                <>
                  <h4>Resource banned</h4>
                  <p>
                    You can manage your entire account from here, however, if you need any
                    assistance, or
                    have any questions feel free to ask through the Contact Center.
                  </p></>
                : <>
                  <h4>Resource not banned</h4>
                  <p>
                    IP or Fingerprint was not flagged as malicious. This resource can see your ADS.
                    If you think, it should be blocked, click "Ban resource" button.
                  </p></>}
            </div>
          </div>
          <div className={`scpc--details-left-baninfo`}>
            <div className={`scpc--details-left-banbtn`}>
              {props.bans.length ?
                renderUnbanButton(props.fingerprint, props.ip) : renderBanButton(props.fingerprint, props.ip)
              }
            </div>
          </div>
          {/*<ReactApexChart options={donutChart.options} series={donutChart.series} type="area" height={160} />*/}
        </div>
      </LeftMainBoxes>
    </>
  )
}