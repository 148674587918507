import React, { useContext, useState, useEffect } from 'react';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import logoWhite from "../../../svg/scpc-logo-white.svg";
import logoWhiteFull from "../../../svg/scpc-logo-white-full.svg";
import licenseCalendar from "../../../svg/license-calendar.svg";
import { LeftNavSubmenu } from "./leftNavSubmenu";
import ReactHtmlParser from 'react-html-parser';
import { uuid } from "uuidv4";
import ProjectContext from '../../../context/CF/ProjectContext/ProjectContext';
import routes, { getRoute } from '../../../routes/routes';
import auth0Client from "../../../Auth";
import _ from "lodash";
import { MobileNav } from '../appLayout/mobileNav';
import moment from 'moment'
import Burger from '@animated-burgers/burger-slip'
import '@animated-burgers/burger-slip/dist/styles.css'

export const LeftNavigation = () => {
  const projectContext = useContext(ProjectContext);
  const [mobileNavOpen, toggleMobileNavOpen] = useState(false);
  const [currentMobileNav, setCurrentMobileNav] = useState('');
  const [subscription, setSubscription] = useState({});


  let location = useLocation();
  let history = useHistory();
  let { projectId } = useParams();
  if (!projectId) {
    // we're not in /project/[projectId]/something link, user ProjectContext defaults
    projectId = projectContext.defaultProjectId;
  }

  let user = auth0Client.getUser();

  useEffect(() => {
    if (projectContext.project) {
      // console.log('Subscription');
      // console.log(projectContext.project);
      setSubscription(projectContext.project.subscription);
    }

  }, [projectContext.project]);

  const getDiffInDays = (endDate) => {
    let now = moment();
    let end = moment(endDate);
    let diff = end.diff(now, 'days');
    return diff;
  }
  // eslint-disable-next-line no-unused-vars
  const getDaysAsString = (days) =>
  {
    let daysAsString = '';
    if (Math.abs(days) > 2) {
      daysAsString = 'in ' + days + ' days';
    } else if (Math.abs(days) === 1) {
      daysAsString = 'in ' + days + ' day';
    } else {
      return  'today';
    }

    if (days > 0) {
      return 'in ' + daysAsString;
    } else {
      return daysAsString + ' ago';
    }
  }

  const [leftNavElements, setLeftNavElements] = useState([
    {
      id: uuid(),
      link: getRoute(routes.project, { projectId: projectId }),
      name: 'Dashboard',
      active: false,
      icon: 'linear-home2',
      submenu: false,
      projectBasedLink: false,
      additionalClass: '',
      routeName: 'dashboard',
      submenuItems: [],
      mobile: true,
      mobileOrder: 1,
      mobileOnly: false,
      mobileLink: getRoute(routes.project, { projectId: projectId })
    },
    {
      id: uuid(),
      link: '#',
      name: 'Fraud<br />analytics',
      active: false,
      icon: 'linear-chip',
      submenu: true,
      projectBasedLink: false,
      additionalClass: 'two-lines',
      routeName: 'analytics',
      mobile: true,
      mobileOrder: 2,
      mobileOnly: false,
      mobileSubmenuActive: false,
      mobileLink: getRoute(routes.analyticsClickFraud, { projectId: projectId }),
      submenuItems: [
        {
          id: uuid(),
          name: 'Clicks Fraud Analytics',
          link: getRoute(routes.analyticsClickFraud, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'analytics',
          active: false
        },
        {
          id: uuid(),
          name: 'Devices&nbsp;analytics',
          link: getRoute(routes.analyticsDeviceAnalytics, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'analytics',
          active: false
        },
        {
          id: uuid(),
          name: 'IP&nbsp;Analytics',
          link: getRoute(routes.analyticsIPAnalytics, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'analytics',
          active: false
        },
        // {
        //   id: uuid(),
        //   name: 'IP&nbsp;Range&nbsp;Analytics',
        //   link: `/analytics/${projectId}/ip-range-analytics`,
        //   projectBasedLink: true,
        //   routeName: 'analytics',
        //   active: false,
        //   blocked: false
        // },
        {
          id: uuid(),
          name: 'Fraud&nbsp;by&nbsp;Country&nbsp;Analytics',
          link: getRoute(routes.analyticsFraudCountry, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'analytics',
          active: false,
          blocked: false
        },
        {
          id: uuid(),
          name: 'Keyword&nbsp;Analytics',
          link: getRoute(routes.analyticsKeywords, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'analytics',
          active: false
        },
        // {
        //   id: uuid(),
        //   name: 'AdGroup&nbsp;level&nbsp;analytics',
        //   link: `/analytics/${projectId}/adgroup-analytics`,
        //   projectBasedLink: true,
        //   routeName: 'analytics',
        //   active: false,
        //   blocked: true
        // },
        // {
        //   id: uuid(),
        //   name: 'Campaign&nbsp;level&nbsp;analytics',
        //   link: `/analytics/${projectId}/campaign-analytics`,
        //   projectBasedLink: true,
        //   routeName: 'analytics',
        //   active: false,
        //   blocked: true
        // }
      ]
    },
    {
      id: uuid(),
      link: '#',
      name: 'Tools',
      active: true,
      icon: 'linear-hammer-wrench',
      submenu: true,
      projectBasedLink: false,
      additionalClass: '',
      routeName: 'tools',
      mobile: false,
      mobileOrder: 2,
      mobileOnly: false,
      mobileSubmenuActive: false,
      submenuItems: [
        {
          id: uuid(),
          name: 'Manage&nbsp;detection&nbsp;rules',
          link: getRoute(routes.projectSettings, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'projectSettings',
          active: false
        },
        {
          id: uuid(),
          name: 'Manage&nbsp;auto&nbsp;IP&nbsp;blocking',
          link: getRoute(routes.projectSettingsIpBased, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'projectSettings',
          active: false
        },
        {
          id: uuid(),
          name: 'Manage&nbsp;notifications',
          link: getRoute(routes.manageNotifications, {}),
          projectBasedLink: false,
          routeName: 'account',
          active: false
        },
        {
          id: uuid(),
          name: 'VPN&nbsp;Detection&nbsp;Settings',
          link: getRoute(routes.projectSettingsVpnBlocking, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'projectSettings',
          active: false
        },
        {
          id: uuid(),
          name: 'Domain&nbsp;setup',
          link: getRoute(routes.domainSetup, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'tools',
          active: false,
          blocked: false
        }
      ]
    },
    {
      id: uuid(),
      link: '#',
      name: 'Account<br />Settings',
      active: false,
      icon: 'icon-user',
      submenu: true,
      projectBasedLink: false,
      additionalClass: 'two-lines',
      routeName: 'account',
      mobile: true,
      mobileOrder: 4,
      mobileOnly: false,
      mobileSubmenuActive: false,
      mobileLink: getRoute(routes.account, { projectId: projectId }),
      submenuItems: [
        {
          id: uuid(),
          name: 'My Account',
          link: getRoute(routes.account, { projectId: projectId }),
          projectBasedLink: false,
          routeName: 'account',
          active: false
        },
        {
          id: uuid(),
          name: 'Payments',
          link: getRoute(routes.addPaymentMethod, { projectId: projectId }),
          projectBasedLink: false,
          routeName: 'account',
          active: false
        },
        {
          id: uuid(),
          name: 'Notifications',
          link: getRoute(routes.manageNotifications, { projectId: projectId }),
          projectBasedLink: false,
          routeName: 'account',
          active: false
        },
        {
          id: uuid(),
          name: 'Invoices',
          link: getRoute(routes.manageInvoices),
          projectBasedLink: false,
          routeName: 'account',
          active: false
        },
        {
          id: uuid(),
          name: 'Support',
          link: getRoute(routes.support),
          projectBasedLink: false,
          routeName: 'account',
          active: false
        },
        {
          id: uuid(),
          name: 'Contact Us',
          link: getRoute(routes.contact),
          projectBasedLink: false,
          routeName: 'showContact',
          active: false
        }
      ]
    },
    {
      id: uuid(),
      name: 'Domain<br />settings',
      active: false,
      icon: 'linear-cog',
      submenu: false,
      additionalClass: 'two-lines',
      link: getRoute(routes.domainSetup, { projectId }),
      projectBasedLink: true,
      routeName: 'domainSetup',
      mobile: false,
      mobileOrder: null,
      mobileOnly: false,
      submenuItems: []
    },
    {
      id: uuid(),
      link: `#`,
      name: 'Reports',
      active: false,
      icon: 'linear-chart-bars',
      submenu: true,
      projectBasedLink: true,
      routeName: 'reports',
      additionalClass: '',
      mobile: false,
      mobileOrder: null,
      mobileOnly: false,
      mobileSubmenuActive: false,
      submenuItems: [
        {
          id: uuid(),
          name: 'Clicks Fraud Reports',
          link: getRoute(routes.reportsClickFraud, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'reports',
          active: false
        },
        {
          id: uuid(),
          name: 'Devices&nbsp;reports',
          link: getRoute(routes.reportsDeviceAnalytics, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'reports',
          active: false
        },
        {
          id: uuid(),
          name: 'IP&nbsp;Reports',
          link: getRoute(routes.reportsIPAnalytics, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'reports',
          active: false
        },
        // {
        //   id: uuid(),
        //   name: 'IP&nbsp;Range&nbsp;Reports',
        //   link: `/reports/${projectId}/ip-range-analytics`,
        //   projectBasedLink: true,
        //   routeName: 'reports',
        //   active: false,
        //   blocked: false
        // },
        {
          id: uuid(),
          name: 'Fraud&nbsp;by&nbsp;Country&nbsp;Reports',
          link: getRoute(routes.reportsFraudCountry, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'reports',
          active: false,
          blocked: false
        },
        {
          id: uuid(),
          name: 'Keyword&nbsp;Reports',
          link: getRoute(routes.reportsKeywords, { projectId: projectId }),
          projectBasedLink: true,
          routeName: 'reports',
          active: false
        },
        // {
        //   id: uuid(),
        //   name: 'AdGroup&nbsp;level&nbsp;Reports',
        //   link: `/reports/${projectId}/adgroup-analytics`,
        //   projectBasedLink: true,
        //   routeName: 'reports',
        //   active: false,
        //   blocked: true
        // },
        // {
        //   id: uuid(),
        //   name: 'Campaign&nbsp;level&nbsp;Reports',
        //   link: `/reports/${projectId}/campaign-analytics`,
        //   projectBasedLink: true,
        //   routeName: 'reports',
        //   active: false,
        //   blocked: true
        // }
      ]
    },
    {
      id: uuid(),
      link: '#',
      name: 'Navigation',
      active: false,
      icon: 'linear-menu',
      submenu: false,
      projectBasedLink: false,
      routeName: 'navigation',
      additionalClass: '',
      mobile: true,
      mobileOrder: 3,
      mobileOnly: true,
      submenuItems: []
    },
    {
      id: uuid(),
      link: getRoute(routes.domainSetup, { projectId: projectId }),
      name: 'More',
      active: false,
      icon: 'linear-cog',
      submenu: false,
      projectBasedLink: true,
      routeName: 'tools',
      additionalClass: '',
      mobile: true,
      mobileOrder: 5,
      mobileOnly: true,
      submenuItems: [],
      mobileLink: getRoute(routes.domainSetup, { projectId: projectId })
    }
  ]);

  // eslint-disable-next-line no-unused-vars
  const mobileNavElements = [];

  // TODO: @alex temporary
  // block submenus if we haven't add the first project yet
  if (user.meta.firstLogin === true) {
    _.each(leftNavElements, (item, i) => {
      item.active = false;
      item.submenu = false;
    });
  }

  const setActiveMenu = (routeName, currentRoute) => {

    if (routeName === 'dashboard' && currentRoute === '/') {
      return true;
    }
    if (routeName === 'dashboard' && location.pathname.match('projects') && !location.pathname.match(
      'projects/.*/settings')) {
      return true;
    }

    if (routeName === '/' && currentRoute === '/') {
      return true;
    }
    if (routeName === 'account' && location.pathname === '/account') {
      return true
    }
    if (routeName === 'account' && location.pathname.match('/account/.*')) {
      return true
    }
    if (routeName === 'projectSettings' && location.pathname.match('settings')) {
      return true;
    }
    if (routeName === 'tools' && location.pathname.match('/domain-setup')) {
      return true;
    }
    if (routeName === 'analytics' && location.pathname.match('/analytics')) {
      return true;
    }
    if (routeName === 'reports' && location.pathname.match('/reports/.*')) {
      return true;
    }
    return false;
  }
  const mobileNavClick = (link) => {
    history.push(link);
  }
  const mobileNavMenuOpen = () => {
    if (mobileNavOpen) {
      document.body.classList.remove('fixed');
    } else {
      document.body.classList.add('fixed');
    }
    toggleMobileNavOpen(!mobileNavOpen);
  }
  const mobileNavSubmenuToggle = (id) => {
    let nextState = [...leftNavElements];
    nextState.filter(element => element.id === id).map((el) => {
      return el.mobileSubmenuActive = !el.mobileSubmenuActive;
      // if (el.id === id) {
      //   el.mobileSubmenuActive = !el.mobileSubmenuActive;
      // }
    });
    setLeftNavElements(nextState);
  }
  return (
    <>
      <ProjectContext.Consumer>
        {({ projectId }) => (
          <>
            <aside className="scpc--nav-left-wrapper">
              <div className="scpc--nav-left-inner">
                <div className="scpc--nav-left-logo">
                  <img src={logoWhite} alt="SCPC" className="scpc--nav-left-logo-print"/>
                  <img src={logoWhiteFull} alt="SCPC" className="scpc--nav-left-logo-full"/>
                </div>
                <nav className="scpc--nav-left-menu">
                  <ul className="scpc--nav-left-menu-list">
                    {
                      leftNavElements.filter((el) => !el.mobileOnly)
                      .map(
                        ({
                           id,
                           link,
                           name,
                           icon,
                           active,
                           submenu,
                           submenuItems,
                           additionalClass,
                           projectBasedLink,
                           routeName
                         }) => (
                          <li
                            key={id}
                            className={`
                        ${setActiveMenu(routeName,
                              location.pathname) ? 'scpc--nav-left-active' : ''} 
                        ${additionalClass}
                        `}>
                            {link === '#' ?
                              <div className={`spcc--nav-left-uberbutton`}>
                                <div className="scpc--nav-left-menuitem-holder">
                                  <i className={icon}/>
                                  <span
                                    className="scpc--nav-left-menu-description">
                              {ReactHtmlParser(name)}
                            </span>
                                  {setActiveMenu(routeName, location.pathname) ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="108.38"
                                         height="125" alt={`active menu image`}>
                                      <path
                                        d="M31 35h52c30.46 0 25-35 25-35v125s4.02-29-27-29H31S0 96.71 0 65.22C0 34.63 31 35 31 35z"
                                        fill="#fff"/>
                                    </svg> : ''}

                                  {submenu ? <div className="icon-chevron-right"/> : ''}
                                </div>
                                {submenu ? <LeftNavSubmenu submenuItems={submenuItems}/> : ''}
                              </div>
                              :
                              <Link to={link}>
                                <div className="scpc--nav-left-menuitem-holder">
                                  <i className={icon}/>
                                  <span
                                    className="scpc--nav-left-menu-description">
                              {ReactHtmlParser(name)}
                            </span>
                                  {setActiveMenu(routeName, location.pathname) ?
                                    <svg xmlns="http://www.w3.org/2000/svg" width="108.38"
                                         height="125" alt={`active menu image`}>
                                      <path
                                        d="M31 35h52c30.46 0 25-35 25-35v125s4.02-29-27-29H31S0 96.71 0 65.22C0 34.63 31 35 31 35z"
                                        fill="#fff" fillRule="evenodd"/>
                                    </svg> : ''}

                                  {submenu ? <div className="icon-chevron-right"/> : ''}
                                </div>
                                {submenu ? <LeftNavSubmenu submenuItems={submenuItems}/> : ''}
                              </Link>
                            }
                          </li>
                        ))}
                  </ul>
                </nav>
                {subscription.status ?
                  <div className="scpc--nav-left-licenseinfo">
                    <img src={licenseCalendar} alt={`license calendar`}/>
                    <div className="scpc--nav-left-licenseinfo-days">
                      <strong>
                        {subscription.status === 'trialing' ?
                          <>
                            {getDiffInDays(subscription.trialPeriodEnd) < 0 ? <>
                              trial ended
                              <br/>{Math.abs(getDiffInDays(subscription.trialPeriodEnd))} days
                              ago
                            </> : <>
                              trial ends
                              <br/>in {Math.abs(getDiffInDays(subscription.trialPeriodEnd))} days
                            </>}
                          </>
                          :
                          <>
                            {/*
                            TODO: @alex
                            use getDaysAsString (feel free to modify it, not tested)
                            in this case don't check trialPeriodEnd but subscription.expires only

                            */}
                            {getDiffInDays(subscription.trialPeriodEnd) < 0 ? <>
                              Premium ended
                              <br/>{Math.abs(getDiffInDays(subscription.expires))} days ago
                            </> : <>
                              Premium ends
                              <br/>in {Math.abs(getDiffInDays(subscription.expires))} days
                            </>}
                          </>}
                      </strong>
                    </div>
                  </div> : <div className="scpc--nav-left-licenseinfo">
                    <div className="scpc--nav-left-licenseinfo-days">
                      <strong>Select a project</strong>
                    </div>
                  </div>}
              </div>
            </aside>
            <section className={`scpc--mobile-bottomnav`}>
              {
                leftNavElements.filter((el) => el.mobile)
                .sort((a, b) => a.mobileOrder - b.mobileOrder)
                .map((
                  {
                    id,
                    link,
                    name,
                    icon,
                    active,
                    submenu,
                    submenuItems,
                    additionalClass,
                    projectBasedLink,
                    mobileLink,
                    routeName
                  }, index) => {
                  if (routeName === 'navigation') {
                    return (
                      <button
                        key={uuid()}
                        className={`
                      ${setActiveMenu(routeName, location.pathname) ? 'scpc--nav-left-active' : ''} 
                      ${mobileNavOpen ? 'scpc--mobilenav-home-active' : ''} 
                      ${additionalClass} scpc--mobilenav-home`}
                        onClick={() => {
                          mobileNavMenuOpen()
                        }}>
                        <div className={`scpc--mobilenav-home-holder`}>
                          <div className={`scpc--mobilenav-home-circle`}>
                            &nbsp;
                          </div>
                        </div>
                        <div className={`scpc--mobilenav-home-inner`}>
                          <Burger isOpen={mobileNavOpen}/>
                        </div>
                      </button>
                    )
                  } else {
                    return (
                      <button
                        key={uuid()}
                        className={`
                      ${setActiveMenu(routeName, location.pathname) ? 'scpc--nav-left-active' : ''} 
                      ${additionalClass} mobile-${routeName}`}
                        onClick={() => mobileNavClick(mobileLink)}
                      >
                        <i className={icon}/>
                      </button>
                    )
                  }
                })}
            </section>
            <MobileNav
              isActive={mobileNavOpen}
              navElements={leftNavElements}
              setActiveMenu={setActiveMenu}
              currentMobileNav={currentMobileNav}
              setCurrentMobileNav={setCurrentMobileNav}
              mobileNavSubmenuToggle={mobileNavSubmenuToggle}
            />
          </>
        )}
      </ProjectContext.Consumer>
    </>
  )
};