import React from 'react';
import {AnalyticsClickFraud} from './AnalyticsClickFraud';
import {AnalyticsKeywords} from './AnalyticsKeywords';
import {AnalyticsDevices} from './AnalyticsDevices';
import {AnalyticsIps} from './AnalyticsIps';
import {AnalyticsLeft} from './AnalyticsLeft';
import {AnalyticsFraudCountry} from './AnalyticsFraudCountry';
import {AnalyticsIpRange} from './AnalyticsIpRange';

export const Analytics = (props) => {
  const AnalyticsComponent = (load) => {
    switch (load) {
      case 'click-fraud':
        return <AnalyticsClickFraud load={load}/>

      case 'keywords-analytics':
        return <AnalyticsKeywords load={load}/>

      case 'device-analytics':
        return <AnalyticsDevices load={load}/>

      case 'ip-analytics':
        return <AnalyticsIps load={load}/>

      case 'fraud-country-analytics':
        return <AnalyticsFraudCountry load={load}/>

      case 'ip-range-analytics':
        return <AnalyticsIpRange load={load}/>

      default:
        return <>
          <AnalyticsLeft load={load}/>
        </>
    }
  }
  return (
    <>
      <div className={`scpc--analytics-view`}>
        {AnalyticsComponent(props.load)}
      </div>
    </>
  );
}