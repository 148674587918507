import {NOTIFICATIONS_ARRIVED, NOTIFICATIONS_UPDATED} from "../types"

export default (state, action) => {
  const maxNotifications = 100;

  switch (action.type) {
    case NOTIFICATIONS_ARRIVED:
      const newNotifications = action.payload;
      let maxId = state.maxId;
      if (newNotifications.length > 0) {
        maxId = newNotifications[0].id;
      }

      // merge the new ones with existing ones and trim if needed
      let mergedNotifications = [...newNotifications, ...state.notifications].slice(0, maxNotifications);

      return {
        ...state,
        isLoading: false,
        notifications: mergedNotifications,
        maxId: maxId
      };

    case NOTIFICATIONS_UPDATED:
      // in-memory notifications updated, no need to merge with existing list
      // maxId doesn't change
      const updatedNotifications = action.payload;

      return {
        ...state,
        isLoading: false,
        notifications: updatedNotifications,
      };

    default:
      return state;
  }
}