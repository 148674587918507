import _ from "lodash";

const routes = {
  index: '/',
  // projects
  project: '/projects/:projectId(\\d+)',
  projectSettings: '/projects/:projectId(\\d+)/settings',
  projectSettingsClickFraud: '/projects/:projectId(\\d+)/settings/click-fraud',
  projectSettingsIpBased: '/projects/:projectId(\\d+)/settings/ip-based',
  projectSettingsManualBan: '/projects/:projectId(\\d+)/settings/manual-ban',
  projectSettingsVpnBlocking: '/projects/:projectId(\\d+)/settings/vpn-blocking',
  projectSettingsBehaviorAnalysis: '/projects/:projectId(\\d+)/settings/behavior-analysis',
  projectSettingsMonitorMode: '/projects/:projectId(\\d+)/settings/monitor-mode',
  visits: '/projects/:projectId(\\d+)/visits',
  addProject: '/projects/new',
  addProjectContinue: '/projects/:projectId(\\d+)/new/:stepId?',
  // account
  projectSettingsDemo: '/project-settings/:projectId(\\d+)',
  account: '/account',
  // payments
  addPaymentMethod: '/account/add-payment-method',
  viewInvoice: 'account/invoices/:invoiceHash',
  authenticatePayment: 'account/authenticate-payment/:paymentIntentId',
  manageNotifications: '/account/notifications',
  manageInvoices: '/account/invoices',
  support: '/account/support',
  contact: '/account/contact',
  // Analytics
  analyticsClickFraud: '/analytics/:projectId(\\d+)/click-fraud',
  analyticsDeviceAnalytics: '/analytics/:projectId(\\d+)/device-analytics',
  analyticsIPAnalytics: '/analytics/:projectId(\\d+)/ip-analytics',
  analyticsIPGroupAnalytics: '/analytics/:projectId(\\d+)/ip-range-analytics',
  analyticsFraudCountry: '/analytics/:projectId(\\d+)/fraud-country-analytics',
  analyticsKeywords: '/analytics/:projectId(\\d+)/keywords-analytics',
  analyticsAdGroup: '/analytics/:projectId(\\d+)/adgroup-analytics',
  analyticsCampaign: '/analytics/:projectId(\\d+)/campaign-analytics',

  analyticsDetailsByIp: '/analytics/:projectId(\\d+)/analytics/details/ip/:ip',
  analyticsDetailsByFingerprint: '/analytics/:projectId(\\d+)/analytics/details/fingerprint/:fingerprint',

  // Reports
  reportsClickFraud: '/reports/:projectId(\\d+)/click-fraud',
  reportsDeviceAnalytics: '/reports/:projectId(\\d+)/device-analytics',
  reportsIPAnalytics: '/reports/:projectId(\\d+)/ip-analytics',
  reportsIPGroupAnalytics: '/reports/:projectId(\\d+)/ip-range-analytics',
  reportsFraudCountry: '/reports/:projectId(\\d+)/fraud-country-analytics',
  reportsKeywords: '/reports/:projectId(\\d+)/keywords-analytics',
  reportsAdGroup: '/reports/:projectId(\\d+)/adgroup-analytics',
  reportsCampaign: '/reports/:projectId(\\d+)/campaign-analytics',
  // misc
  domainSetup: '/projects/:projectId(\\d+)/domain-setup'
};

/**
 * Put parameters into route pattern to get a real URL
 *
 * @param routePattern
 * @param params e.g. {projectId: 23}
 * @returns string
 */
export const getRoute = (routePattern, params) => {
  _.each(params, (value, parameter) => {
    routePattern = routePattern.replace(`:${parameter}(\\d+)`, value);
    routePattern = routePattern.replace(`:${parameter}?`, value);
    routePattern = routePattern.replace(`:${parameter}`, value);
  })
  // remove not used, optional params
  routePattern = routePattern.replace(`:.*?`, '');
  return routePattern;
}

export const isRoute = (uri, route) => {

}

export default routes;