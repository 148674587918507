import React, {useCallback, useEffect, useState} from 'react';
import routes, {getRoute} from './routes/routes';
import {matchPath, Route, Switch, useHistory, useLocation, useParams} from 'react-router-dom';
import ProjectState from './context/CF/ProjectContext/ProjectState';
import NotificationsState from './context/Notifications/NotificationsState';
import AddNewProject from "./components/CF/AddNewProject/AddNewProject";
import TopNavBar from "./components/layout/TopNavBar";
import Callback from './components/Callback';
import auth0Client from "./Auth";
import SecuredRoute from "./components/SecuredRoute/SecuredRoute";
import './App.scss';
import {LoaderElem} from "./components/CF/Loader";
import {Dashboard} from "./components/CF/dashboard/Dashboard";
import {LeftNavigation} from "./components/CF/appLayout/leftNavigation";
import {ProjectSettings} from './components/CF/ProjectSettings/ProjectSettings';
import VisitsSearch from "./components/CF/VisitsSearch/VisitsSearch";
import ProjectSettingsState from "./context/CF/ProjectSettingsEditor/ProjectSettingsState";
import {Account} from './components/CF/Account/Account';
import {Analytics} from './components/CF/Analytics/Analytics';
import {Reports} from './components/CF/Reports/Reports';
import {AnalyticsIpDetails} from "./components/CF/Analytics/AnalyticsIpDetails";
import {AnalyticsFingerprintDetails} from "./components/CF/Analytics/AnalyticsFingerprintDetails";
import {DomainSetup} from './components/CF/ProjectSettings/DomainSetup';
import Moment from 'react-moment';
import _ from "lodash";

function App() {
  const history = useHistory();
  const location = useLocation();
  const [isAuthorizing, setIsAuthorizing] = useState(true);
  const renderAppAfterCheckAuth = useCallback(() => {
    setIsAuthorizing(false);
  }, []);

  const isFirstLogin = () => auth0Client.getUser().meta.firstLogin === true;
  const isForceAddCard = () => auth0Client.getUser().meta.forceAddPaymentMethod === true;
  let {projectId: projectIdFromUrl} = useParams();
  const [defaultProjectId, setDefaultProjectId] = useState(projectIdFromUrl || null);
  const isPathInRouteGroup = (routesGroup, path) => {
    const groups = {
      'first_login': [routes.addProject, routes.addProjectContinue, routes.addPaymentMethod],
      'force_add_payment_method': [routes.addPaymentMethod]
    }
    if (groups[routesGroup].indexOf(path) !== -1) {
      return true;
    }

    // we need this to support parameters in route
    let found = false;
    _.forEach(groups[routesGroup], (route) => {
      if (matchPath(path, {path: route})) {
        found = true;
        return false;
      }
    })
    return found;
  }

  /**
   * Force-redirect user to add payment method or project.
   * If he's on / or /callback redirect to default project.
   *
   * @param currentPath
   * @param defaultRedirect if set user will be redirected there if no forced redirects are done
   **/
  const initDefaultProjectAndRedirect = (currentPath, defaultRedirect) => {
    if (!auth0Client.isAuthenticated()) {
      return;
    }

    let user = auth0Client.getUser();
    // check if there are obligatory redirects for the logged user
    if (isForceAddCard() && !isPathInRouteGroup('force_add_payment_method', currentPath)) {
      history.push(getRoute(routes.addPaymentMethod));
      return;
    } else if (isFirstLogin()) {
      if (!isPathInRouteGroup('first_login', currentPath)) {
        if (user.defaultProjectId) {
          // user doesn't have any project yet but already started creating one
          history.push(getRoute(routes.addProjectContinue, {projectId: user.defaultProjectId, stepId: ''}));
        } else {
          // no project yet
          history.push(getRoute(routes.addProject));
        }
      } else {
        // user is either on projects/new or projects/XXX/new, leave him as is
      }
      return;
    }

    let shouldRedirect = currentPath === '/' || currentPath === '/callback';
    // redirect to default project?
    let projectIdToRedirect = null;
    if (!defaultProjectId) {
      if (
        localStorage.getItem('activeProjectId') &&
        parseInt(localStorage.getItem('userId')) === user.id
      ) {
        projectIdToRedirect = localStorage.getItem('activeProjectId');
      } else {
        // no info in local storage, try to redirect from user data
        if (user.defaultProjectId) {
          if (user.defaultProjectStatus === 'active') {
            projectIdToRedirect = user.defaultProjectId;
          } else if (user.defaultProjectStatus === 'setup') {
            // continue setup of the first created project
            history.push(getRoute(routes.addProjectContinue, {projectId: user.defaultProjectId, stepId: ''}));
            return;
          }
        } else {
          // TODO: user doesn't have any active projects but has at least one in setup state.
          // redirect to add new project for user.defaultProjectId, first need to do deep linking
          // and state init for half-finished setup there.
          // for now, just redirect to new project
          history.push(routes.addProject);
          return;
        }
      }
      setDefaultProjectId(projectIdToRedirect);
    }

    if (shouldRedirect) {
      if (projectIdToRedirect) {
        // console.log('Redirect to default project: ', projectIdToRedirect);

        history.push(getRoute(routes.project, {projectId: projectIdToRedirect}));
      } else if (defaultRedirect) {
        // console.log('Redirect to default url: ', defaultRedirect);

        history.push(defaultRedirect);
      }
    }
  }

  useEffect(() => {
    // on load verify JWT with auth0
    async function checkAuth() {
      if (location.pathname === '/callback') return;
      try {
        await auth0Client.silentAuth().then(
          () => {
            let user = auth0Client.getUser();
            // user is logged, reload
            // console.log(user);

            // TODO: make it editable in user settings
            Moment.globalTimezone = user.settings.timezone || 'Europe/Warsaw';
            Moment.globalFormat = 'D/M/Y HH:mm';
            // force redirect user to add payment or project
            initDefaultProjectAndRedirect(location.pathname);
            renderAppAfterCheckAuth();
          }
        );
      } catch (err) {
        // user not logged in
        if (err.error !== 'login_required') {
          // console.log(err.error);
        }
        renderAppAfterCheckAuth();
      }
    }

    checkAuth();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="App">
      {window.innerWidth < 1920 ? <>
        <div className={`scpc--resolution-overlay`} style={{'display': 'none'}}>
          <div className={`scpc--resolution-overlay-inner`}>
            <i className={`linear-desktop`}/>
            <h1>Screen size to small</h1>
            <p>Your screen has to be at least <strong>1920px</strong> wide</p>
          </div>
        </div>
      </> : ''}
      <Switch>
        <Route exact path='/callback' render={(props) => (
          <Callback
            setIsAuthorizing={setIsAuthorizing}
            performRedirects={initDefaultProjectAndRedirect}
          />
        )}/>
        {isAuthorizing ? <>
            <LoaderElem loading={isAuthorizing}/>
          </> :
          <>
            <ProjectState defaultProjectId={defaultProjectId}>
              <NotificationsState>
                <SecuredRoute exact path={routes.projectSettings} component={() => {
                  return (
                    <>
                      <TopNavBar title={'Manage detection rules'}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <ProjectSettingsState>
                          <ProjectSettings/>
                        </ProjectSettingsState>
                      </main>
                    </>
                  );
                }}/>
                <SecuredRoute path={routes.projectSettingsClickFraud} component={() => {
                  return (
                    <>
                      <TopNavBar title={'Manage detection rules'}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <ProjectSettingsState>
                          <ProjectSettings show={'click-fraud'}/>
                        </ProjectSettingsState>
                      </main>
                    </>
                  );
                }}/>
                <SecuredRoute path={routes.projectSettingsIpBased} component={() => {
                  return (
                    <>
                      <TopNavBar title={'Manage detection rules'}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <ProjectSettingsState>
                          <ProjectSettings show={'ip-based'}/>
                        </ProjectSettingsState>
                      </main>
                    </>
                  );
                }}/>
                <SecuredRoute path={routes.projectSettingsManualBan} component={() => {
                  return (
                    <>
                      <TopNavBar title={'Manage detection rules'}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <ProjectSettingsState>
                          <ProjectSettings show={'manual-ban'}/>
                        </ProjectSettingsState>
                      </main>
                    </>
                  );
                }}/>
                <SecuredRoute path={routes.projectSettingsVpnBlocking} component={() => {
                  return (
                    <>
                      <TopNavBar title={'Manage detection rules'}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <ProjectSettingsState>
                          <ProjectSettings show={'vpn-blocking'}/>
                        </ProjectSettingsState>
                      </main>
                    </>
                  );
                }}/>
                <SecuredRoute path={routes.projectSettingsBehaviorAnalysis} component={() => {
                  return (
                    <>
                      <TopNavBar title={'Manage detection rules'}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <ProjectSettingsState>
                          <ProjectSettings show={'behavior-analysis'}/>
                        </ProjectSettingsState>
                      </main>
                    </>
                  );
                }}/>
                <SecuredRoute path={routes.projectSettingsMonitorMode} component={() => {
                  return (
                    <>
                      <TopNavBar title={'Manage detection rules'}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <ProjectSettingsState>
                          <ProjectSettings show={'monitor-mode'}/>
                        </ProjectSettingsState>
                      </main>
                    </>
                  );
                }}/>
                {/*<Route exact path="/" render={props => (*/}
                {/*  <>*/}
                {/*    <TopNavBar/>*/}
                {/*    <LeftNavigation/>*/}
                {/*    <main className={'scpc--main'}>*/}
                {/*    </main>*/}
                {/*  </>*/}
                {/*)}*/}
                {/*/>*/}

                <SecuredRoute exact path={`/`} component={() => {
                  return (
                    <>
                      <TopNavBar/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Dashboard/>
                      </main>
                    </>
                  );
                }}/>

                <SecuredRoute exact path={routes.addProject} component={() => {
                  return (
                    <>
                      <main>
                        <AddNewProject/>
                      </main>
                    </>
                  );
                }}/>

                <SecuredRoute exact path={routes.addProjectContinue} component={() => {
                  return (
                    <>
                      <main>
                        <AddNewProject/>
                      </main>
                    </>
                  );
                }}/>

                <SecuredRoute exact path={routes.project} component={() => {
                  return (
                    <>
                      <TopNavBar/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Dashboard/>
                      </main>
                    </>
                  );
                }}/>

                <SecuredRoute exact path={routes.visits} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Fraud Analytics`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <VisitsSearch/>
                      </main>
                    </>);
                }}/>

                <SecuredRoute exact path={routes.account} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Account settings`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Account load={`account`}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute exact path={routes.addPaymentMethod} component={() => {
                  const isForced = () => {
                    if (auth0Client.getUser()) {
                      return auth0Client.getUser().meta.forceAddPaymentMethod === true;
                    } else {
                      return false;
                    }
                  }
                  return (
                    <>
                      <TopNavBar title={`Account settings`} forcedPaymentsDetails={isForced()}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Account load={'payments'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute exact path={routes.manageNotifications} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Account settings`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Account load={'notifications'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute exact path={getRoute(routes.manageInvoices)} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Account settings`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Account load={'invoices'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute exact path={getRoute(routes.support)} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Account settings`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Account load={'support'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute exact path={getRoute(routes.contact)} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Account settings`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Account load={'contact'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={getRoute(routes.analyticsClickFraud)} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Click Fraud Analytics`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Analytics load={'click-fraud'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={getRoute(routes.analyticsDeviceAnalytics)} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Device Analytics`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Analytics load={'device-analytics'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.analyticsIPAnalytics} component={() => {
                  return (
                    <>
                      <TopNavBar title={`IP Analytics`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Analytics load={'ip-analytics'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.analyticsIPGroupAnalytics} component={() => {
                  return (
                    <>
                      <TopNavBar title={`IP Range Analytics`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Analytics load={'ip-range-analytics'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.analyticsFraudCountry} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Fraud by Country`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Analytics load={'fraud-country-analytics'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.analyticsKeywords} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Keyword Analytics`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Analytics load={'keywords-analytics'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.analyticsAdGroup} component={() => {
                  return (
                    <>
                      <TopNavBar title={`AdGroup Analytics`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Analytics load={'adgroup-analytics'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.analyticsCampaign} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Campaign Analytics`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Analytics load={'campaign-analytics'}/>
                      </main>
                    </>
                  )
                }}/>

                <SecuredRoute path={routes.analyticsDetailsByIp} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Details`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <AnalyticsIpDetails/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.analyticsDetailsByFingerprint} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Details`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <AnalyticsFingerprintDetails/>
                      </main>
                    </>
                  )
                }}/>

                <SecuredRoute path={routes.reportsClickFraud} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Report center`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Reports load={'click-fraud'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.reportsDeviceAnalytics} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Report center`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Reports load={'devices-analytics'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.reportsIPAnalytics} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Report center`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Reports load={'ip-analytics'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.reportsIPGroupAnalytics} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Report center`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Reports load={'ip-groups'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.reportsFraudCountry} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Report center`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Reports load={'fraud-country'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.reportsKeywords} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Report center`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Reports load={'keywords'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.reportsAdGroup} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Report center`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Reports load={'ad-group'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.reportsCampaign} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Report center`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <Reports load={'campaign'}/>
                      </main>
                    </>
                  )
                }}/>
                <SecuredRoute path={routes.domainSetup} component={() => {
                  return (
                    <>
                      <TopNavBar title={`Domain setup`}/>
                      <LeftNavigation/>
                      <main className={'scpc--main'}>
                        <DomainSetup/>
                      </main>
                    </>
                  )
                }}/>
              </NotificationsState>
            </ProjectState>
          </>
        }
      </Switch>
    </div>
  );
}

export default App;
