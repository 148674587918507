import React, {useEffect, useState} from 'react';
import moment from 'moment';

export const CalendarDatesStripe = (props) => {
  const {uuid} = require('uuidv4');

  const updateInitialDays = () => {
    if (props.days.length === 0) {
      props.updateWeekDays(props.getWeekDaysArray());
    }
  }
  const isDateToday = (date) => {
    return date.isSame(moment(), "day");
  }
  const isDateInFuture = (date) => {
    return date.isAfter(moment(), "day");
  }
  const isSingleDayMode = () => {
    return props.startDate.isSame(props.endDate, "day");
  }
  const addTodayClass = (date) => {
    return isDateToday(date) ? 'scpc--calendar-stripe-today' : '';
  }
  const addFutureClass = (date) => {
    return isDateInFuture(date) ? 'scpc--calendar-stripe-future' : '';
  }
  const addActiveClass = (date) => {
    if (isSingleDayMode() && date.isSame(props.startDate, 'day')) {
      return 'scpc--calendar-stripe-active';
    }
    return '';
  }
  const onCalendarStripeDayClick = (e, day) => {
    if (isDateInFuture(day)) {
      return false;
    }
    props.onChange({
      'startDate': day,
      'endDate': day
    });
  }
  useEffect(() => {
    updateInitialDays();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <>
      {props.days.length ? props.days.map((day) => {
        return (
          <React.Fragment key={uuid()}>
            <div
              className={`scpc--calendar-stripe-day ${addTodayClass(day)} ${addFutureClass(day)} ${addActiveClass(day)}`}
              onClick={(e) => onCalendarStripeDayClick(e, day)}
            >
              <div className={`scpc--calendar-stripe-day-inner`}>
                <strong>{day.clone().format('ddd').toString()}</strong>
                <small>{day.clone().format('DD').toString()}</small>
              </div>
            </div>
          </React.Fragment>
        );
      }) : 'Loading...'}
    </>
  )
}

export const CalendarStripe = (props) => {
  const [activeWeekStart, setActiveWeekStart] = useState(moment().startOf('week'));
  const [activeWeekEnd, setActiveWeekEnd] = useState(moment().endOf('week'));
  const [activeMonth, setActiveMonth] = useState(moment());
  const [weekNumber, setWeekNumber] = useState(moment().week());
  const [weekDays, setWeekDays] = useState([]);
  const currentDayWeekNumber = moment().week();

  const changeWeekNumber = (direction) => {
    if (direction === 'prev') {
      setWeekNumber(weekNumber - 1 <= 1 ? weekNumber : weekNumber - 1);
    }
    if (direction === 'next') {
      setWeekNumber(weekNumber + 1 > currentDayWeekNumber ? weekNumber : weekNumber + 1);
    }
    updateWeekStatesBasedOnWeekNumber(weekNumber);
  }

  const updateWeekStatesBasedOnWeekNumber = (numberOfAWeek) => {
    let weeksDiff = currentDayWeekNumber - weekNumber;
    let dateInThePast = moment().subtract(weeksDiff, 'weeks').startOf('week');

    let firstWeekDay = moment().year(dateInThePast.year()).month(dateInThePast.month()).week(dateInThePast.week()).day(0);
    let lastWeekDay = moment().year(dateInThePast.year()).month(dateInThePast.month()).week(dateInThePast.week()).day(6);

    setWeekDays(getWeekDaysArray(firstWeekDay, lastWeekDay));

    setActiveWeekStart(firstWeekDay);
    setActiveWeekEnd(lastWeekDay);
    setActiveMonth(dateInThePast);
  }
  const getWeekDaysArray = (start = moment().startOf('week'), end = moment().endOf('week')) => {
    let days = [];
    let day = start;
    while (day <= end) {
      days.push(day);
      day = day.clone().add(1, 'd');
    }
    return days;
  }

  const updateWeekDays = (arrayOfDaysObjects) => {
    setWeekDays(arrayOfDaysObjects);
  }

  return (
    <>
      <div className={`scpc--calendar-stripe`}>
        <div className={`scpc--calendar-stripe-inner`}>
          <div className={`scpc--calendar-stripe-dates`}>
            <div>{activeMonth.clone().format('MM').toString()} /
              <span>{activeWeekStart.clone().format('DD').toString()}-{activeWeekEnd.clone().format('DD').toString()}</span>
            </div>
            <ul className={`scpc--calendar-stripe-dates-buttons`}>
              <li>
                <button onClick={() => changeWeekNumber('prev')}><i className={`linear-chevron-left`}/></button>
              </li>
              <li>
                <button onClick={() => changeWeekNumber('next')}><i className={`linear-chevron-right`}/></button>
              </li>
            </ul>
          </div>
          <div className={`scpc--calendar-stripe-singles`}>
            <CalendarDatesStripe
              days={weekDays}
              updateWeekDays={updateWeekDays}
              getWeekDaysArray={getWeekDaysArray}
              onChange={props.onChange}
              startDate={props.startDate}
              endDate={props.endDate}
            />
          </div>
        </div>
      </div>
    </>
  );
}