import React, {useReducer} from 'react';
import ProjectContext from './ProjectContext';
import ProjectReducer from './ProjectReducer';
import * as ProjectsApi from '../../../api/projects';

import {PROJECT_LOADING_STATE, PROJECTS_LOADING_STATE, UPDATE_PROJECT_STATE, UPDATE_PROJECTS_STATE} from "../../types"
import auth0Client from "../../../Auth";

const ProjectState = (props) => {
  const defaultProjectId = parseInt(props.defaultProjectId);

  const initialState = {
    // project Id that's set in URL, localStorage or defaultProjectId from user
    // it's determined after loading in app.js
    defaultProjectId: defaultProjectId,
    projectId: null,
    project: null,
    projects: null,
    isProjectLoading: true,
    isProjectsLoading: true,
    defaultProjectLoadInitialized: false
  };

  const [state, dispatch] = useReducer(ProjectReducer, initialState);
  const setProjectLoading = () => dispatch({type: PROJECT_LOADING_STATE});
  const setProjectsLoading = () => dispatch({type: PROJECTS_LOADING_STATE});

  const setProject = async (project) => {
    dispatch({type: UPDATE_PROJECT_STATE, payload: project});
  }

  const loadProject = async (projectId, forceReload) => {
    return new Promise((resolve, reject) => {
      if (projectId !== state.projectId || forceReload) {
        setProjectLoading();
        ProjectsApi.getProject(projectId)
          .then((project) => {
            setProject(project);
            if (project.status === 'active') {
              // only set project as default if it's active, otherwise it's in setup state
              // and the redirect should be handled by app.js
              localStorage.setItem('activeProjectId', projectId);
            }
            localStorage.setItem('userId', auth0Client.getUserId());
            resolve(project);
          });
      } else {
        resolve(state.project);
      }
    });
  };

  const loadProjects = (forceReload) => {
    return new Promise((resolve, reject) => {
      if (!state.projects || forceReload) {
        setProjectsLoading();
        return ProjectsApi.getProjects()
          .then((result) => {
            dispatch({type: UPDATE_PROJECTS_STATE, payload: result.results});
            resolve(result.results);
          });
      } else {
        resolve(state.projects);
      }
    });
  };

  if (!state.defaultProjectLoadInitialized && defaultProjectId) {
    loadProject(defaultProjectId);
  }

  return <ProjectContext.Provider
    value={{
      defaultProjectId: state.defaultProjectId,
      projectId: state.projectId,
      project: state.project,
      projects: state.projects,
      isProjectLoading: state.isProjectLoading,
      isProjectsLoading: state.isProjectsLoading,
      loadProject,
      setProject,
      loadProjects
    }}
  >
    {props.children}
  </ProjectContext.Provider>
};

export default ProjectState;
