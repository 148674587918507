import React from "react";
import {Link} from "react-router-dom";
import routes, {getRoute} from "../../../../routes/routes";
import {Alert, Timeline, Card, Tag} from 'antd';
import parse from 'html-react-parser';
/**
 * List visits for IP/fingerprint details view
 * @param params
 * @returns {*}
 * @constructor
 */
export const VisitsList = (params) => {
  const {visits, projectId} = params;
  if (visits.length) {
    return (<>

        {/*<h4>Clicks</h4>*/}
        <div className={`container-fluid scpc--details-view-mobile mt-40 visible-xs`}>
          <div className={`row`}>
            <div className={`col-lg-12 col-sm-12 col-xs-12 col-md-12 pl-0 pr-0`}>
              {visits.map((visit) => (
                <Card size="small" title={<Link to={getRoute(routes.analyticsDetailsByIp, {projectId, ip: visit.ip})}><i className="fal fa-chart-network"/> IP: {visit.ip}</Link>} style={{ width: '100%', textAlign: 'left' }}>
                  <table className={`table table-borderless table-hover table-striped table-visits-mobile`}>
                    <tbody>
                    <tr><td>Logged at</td></tr>
                    <tr><td><i className="fal fa-calendar"/> {visit.loggedAt}</td></tr>
                    <tr><td>GCLID:</td></tr>
                    <tr>
                      <td>{visit.gclid ? visit.gclid : '-'}</td>
                    </tr>
                    <tr><td>Fingerprint:</td></tr>
                    <tr>
                      <td>
                        {visit.fingerprint ? <Link to={getRoute(routes.analyticsDetailsByFingerprint, {projectId, fingerprint: visit.fingerprint})}><i className="fal fa-fingerprint" /> {visit.fingerprint}</Link> : '-'}</td>
                    </tr>
                    <tr><td>Campaign ID:</td></tr>
                    <tr>
                      <td>{visit.campaignId ? visit.campaignId : '-'}</td>
                    </tr>
                    <tr><td>Country:</td></tr>
                    <tr>
                      <td>{visit.country ? visit.country : '-'} {visit.countryCode ? `(${visit.countryCode})` : '' }</td>
                    </tr>
                    <tr><td>AD Placement: </td></tr>
                    <tr>
                      <td>{visit.ad_placement ? visit.ad_placement : '-'}</td>
                    </tr>
                    <tr><td>Browser: </td></tr>
                    <tr>
                      <td>{visit.browser_agent ? visit.browser_agent : '-'}</td>
                    </tr>
                    <tr> <td>Keywords: </td></tr>
                    <tr>
                      <td>{visit.keywords ? <Tag color="#2db7f5">{visit.keywords}</Tag> : '-'}</td>
                    </tr>
                    <tr><td>AD Group ID: </td></tr>
                    <tr>
                      <td>{visit.adwordsAdGroupId ? visit.adwordsAdGroupId : '-'}</td>
                    </tr>
                    <tr><td>Match type: </td></tr>
                    <tr>
                      <td>{visit.matchType ? <Tag color="#2db7f5">{visit.matchType}</Tag> : '-'}</td>
                    </tr>
                    <tr><td>Device</td></tr>
                    <tr>
                      <td>{visit.device ? visit.device : '-'}</td>
                    </tr>
                    </tbody>
                  </table>
                </Card>
              ))}
            </div>
          </div>
        </div>
        <div className={`container-fluid scpc--details-view-timeline mt-40 hidden-xs`}>
          <div className={`row`}>
            <div className={`col-lg-12 col-sm-12 col-xs-12 col-md-12`}>
              <Timeline mode={`left`}>
                {visits.map((visit) => (
                  <Timeline.Item key={visit.id} label={parse(`<i class="fal fa-calendar" ></i> Logged at:<br /> ${visit.loggedAt}`)}>
                    <Card size="small" title={<Link to={getRoute(routes.analyticsDetailsByIp, {projectId, ip: visit.ip})}><i className="fal fa-chart-network"/> IP: {visit.ip}</Link>} style={{ width: '100%', textAlign: 'left' }}>
                      <table className={`table table-borderless table-hover`}>
                        <tbody>
                        <tr>
                          <td>GCLID:</td>
                          <td>{visit.gclid}</td>
                        </tr>
                        <tr>
                          <td>Fingerprint:</td>
                          <td><Link to={getRoute(routes.analyticsDetailsByFingerprint, {projectId, fingerprint: visit.fingerprint})}><i className="fal fa-fingerprint" /> {visit.fingerprint}</Link></td>
                        </tr>
                        <tr>
                          <td>Campaign ID:</td>
                          <td>{visit.campaignId}</td>
                        </tr>
                        <tr>
                          <td>Country:</td>
                          <td>{visit.country} ({visit.countryCode})</td>
                        </tr>
                        <tr>
                          <td>AD Placement: </td>
                          <td>{visit.ad_placement}</td>
                        </tr>
                        <tr>
                          <td>Browser: </td>
                          <td>{visit.browser_agent}</td>
                        </tr>
                        <tr>
                          <td>Keywords: </td>
                          <td><Tag color="#2db7f5">{visit.keywords}</Tag></td>
                        </tr>
                        <tr>
                          <td>AD Group ID: </td>
                          <td>{visit.adwordsAdGroupId}</td>
                        </tr>
                        <tr>
                          <td>Match type: </td>
                          <td><Tag color="#2db7f5">{visit.matchType}</Tag></td>
                        </tr>
                        <tr>
                          <td>Device</td>
                          <td>{visit.device}</td>
                        </tr>
                        </tbody>
                      </table>
                    </Card>
                  </Timeline.Item>
                ))}
              </Timeline>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <Alert
        message="No tracked visits"
        description="We can't find any visits for given IP address or Fingerprint"
        type="info"
        showIcon
      />
    );
  }
}