import React, {useState} from 'react';
import * as ProjectsApi from '../../../api/projects';
import InputMask from "react-input-mask";
import {ToastContainer} from 'react-toastify';
import {LoaderElem} from '../Loader';
import {useForm} from 'react-hook-form';
import HtmlParser from 'react-html-parser';

const LinkAdWords = (props) => {
  const {handleSubmit, register, errors} = useForm();
  const {nextStep, state} = props;
  const [clientId, setClientId] = useState(null);
  const [loading, setLoading] = useState(false);

  const submit = data => {
    setLoading(true);
    ProjectsApi.linkAdWordsAccount(state.cfProject.id, clientId)
      .then((result) => {
        nextStep({
          ...state,
          clientCustomerId: clientId
        })
      })
      .catch((error) => {
        setLoading(false);
      });
    //   switch (error.response.data.error) {
    //     case 'AdWordsAccountDoesNotExist':
    //       toast('Google Ads account does not exists', {
    //         onClose: () => {
    //           setLoading(false)
    //         },
    //         type: toast.TYPE.ERROR
    //       });
    //       break;
    //     case 'AdWordsAccountAlreadyManaged':
    //       toast('Google Ads account already managed', {
    //         onClose: () => {
    //           setLoading(false)
    //         },
    //         type: toast.TYPE.ERROR
    //       });
    //       break;
    //     case 'AdWordsAccountAlreadyInvited':
    //       toast('Google Ads account already invited', {
    //         onClose: () => {
    //           setLoading(false)
    //         },
    //         type: toast.TYPE.ERROR
    //       });
    //       break;
    //     case 'AdWordsNotATestAccount':
    //       toast('This Google Ads account not a test one. Until into production only test accounts can be used.', {
    //         onClose: () => {
    //           setLoading(false)
    //         },
    //         type: toast.TYPE.ERROR
    //       });
    //       break;
    //
    //     default:
    //       toast('Error connecting to AdWords, please try again in a moment', {
    //         onClose: () => {
    //           setLoading(false)
    //         },
    //         type: toast.TYPE.ERROR
    //       });
    //   }
    // });
  };

  return (
    <>
      {/*link adwords*/}
      {/*<form onSubmit={handleSubmit(submitForm)}>*/}
      {/*  <input*/}
      {/*    name="clientCustomerId"*/}
      {/*    ref={*/}
      {/*      register({*/}
      {/*        required: "Required",*/}
      {/*        validate: validateAdWordsCustomerId*/}
      {/*      })*/}
      {/*    }*/}
      {/*  />*/}
      {/*  {errors.domain ?*/}
      {/*    <div className="error">*/}
      {/*      <ErrorMessage errors={errors} name="clientCustomerId" className="error"/>*/}
      {/*    </div>*/}
      {/*    : null*/}
      {/*  }*/}

      {/*  <br/>*/}
      {/*  <button type="submit">next</button>*/}

      {/*</form>*/}
      {/*<button onClick={() => prevStep()}>prev</button>*/}
      <ToastContainer/>
      <div className="wizard_right-step--1">
        <div className="wizard_right-content wizard_right-content--fixed">
          <div className="wizard_right-content--header text-center">
            <h3>Google Ads Account ID</h3>
          </div>
          <div className="wizard_right-content--inner">
            <p>
              In order to configure your account for real-time Google ADS protection,
              please submit your Google Ads Account ID. You can find it in your
              Google Ads Account in the top right corner. <button
                                                             className="scpc--inline-link color-blue">Show
              me
              where it is</button>
            </p>
            <form className={`scpc--forms-form`} onSubmit={handleSubmit(submit)}>
              <LoaderElem loading={loading}/>
              <div className={`wizard_right-step--1-adwordsid`}>
                <div
                  className={`scpc--forms-input-holder input-main ${errors.clientId ? 'has-error' : ''}`}>
                  <InputMask
                    mask={`999-999-9999`}
                    maskPlaceholder="_"
                    placeholder={`___-___-____`}
                    onChange={e => setClientId(e.target.value)}
                  >
                    <input type="text" placeholder="Your domain" name="clientId"
                           ref={register({
                             required: true,
                             pattern: /^[0-9]{3}-[0-9]{3}-[0-9]{4}$/i
                           })}
                           className={`scpc--forms-input-text input-large`}
                    />
                  </InputMask>
                  {errors.clientId && HtmlParser(
                    "<small class='scpc--forms-input-error'>Wrong GoogleADS Account ID</small>")}
                </div>
              </div>
              <div className="wizard_right-step--1-button">
                <button
                  className="scpc--button solid-button gradient-button blue-gradient with-icon rounded-button">
                  <img src="/svg/plus.svg" alt={`plus`}/>
                  <span>Submit Google ID</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default LinkAdWords;