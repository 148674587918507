import React from 'react';

export const RightContent = (props) => {
  return (
    <>
      <section className={`scpc--rightcontent`}>
        {props.children}
      </section>
    </>
  );
};