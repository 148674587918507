import React from "react";
import ReactApexChart from "react-apexcharts";

export const BanReason = (props) => {
  const desktopOptions = {
    chart: {
      type: 'donut',
    },
    plotOptions: {
      pie: {
        donut: {
          size: '80%'
        }
      }
    },
    labels: ['other'],
    dataLabels: {
      enabled: false
    },
    legend: {
      position: 'right',
      offsetY: 0,
      height: 230,
    }
  };

  const mobileOptions = {
    chart: {
      type: 'bar',
    },
    labels: [],
    dataLabels: {
      enabled: true
    },
    responsive: [
      {
        breakpoint: 1400,
        options: {
          height: 300,
          chart: {
            type: 'bar',
            height: '300px'
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          },
          legend: {
            position: "bottom"
          },
          xaxis: {
            categories: []
          }
        }
      },
      {
        breakpoint: 1099,
        options: {
          height: 300,
          chart: {
            type: 'bar',
            height: '300px'
          },
          plotOptions: {
            bar: {
              horizontal: false,
            }
          }
        }
      }
    ],
    legend: {
      position: 'right',
      offsetY: 0,
      height: 230,
    }
  };

  let options;
  let series;
  let graphType;
  if (props.isMobile) {
    graphType = 'bar';
    series = [{data: props.series}];
    options = {
      ...mobileOptions,
      labels: props.labels
    };
    // for bar graph alternative
    options.responsive[0].options.xaxis.categories = props.labels;
  } else {
    graphType = 'donut';
    series = props.series;
    options = {
      ...desktopOptions,
      labels: props.labels
    };
  }


  return (
    <ReactApexChart
      options={options}
      series={series}
      type={graphType}
    />

  )
}
