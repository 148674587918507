import {BAN_SOURCE_MANUAL, BAN_SOURCE_MONITOR} from "../../../context/types";

/**
 * Get text description of ban reason
 *
 * @param banReasonId
 * @returns {{description: string}}
 */
export const getBanReasonDescription = (banReasonId) => {
  const reasons = {
    1: {
      description: "IP seen too many times"
    },
    2: {
      description: "Device seen too many times"
    },
    3: {
      description: "An already banned device was seen on new IP"
    },
    4: {
      description: "New device seen on an already banned IP"
    },
    5: {
      description: "Banned device seen on a new IP"
    }
  };

  return reasons[parseInt(banReasonId)] || {
    description: "Other"
  }
}

/**
 * Get ban source name
 * @param banSource
 * @returns {string}
 */
export const getBanSource = (banSource) => {
  const sources = {
    [BAN_SOURCE_MONITOR]: "Tracking",
    [BAN_SOURCE_MANUAL]: "Manually set"
  };
  return sources[banSource] || "Other";
}

