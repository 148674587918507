import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { LeftMainBoxes } from "../appLayout/LeftMainBoxes";
import { RightContent } from "../appLayout/RightContent";
import { ContentBox } from "../box/ContentBox";
import { Button } from '../../layout/Button';
import ReactApexChart from "react-apexcharts";
import { Calendar } from '../Calendar/Calendar';
import { CalendarStripe } from '../Calendar/CalendarStripe';
import moment from 'moment';
import * as ProjectsApi from "../../../api/projects";
import { uuid } from "uuidv4";
import _ from "lodash";
import auth0Client from "../../../Auth";
import { Skeleton } from 'antd';
import routes, { getRoute } from '../../../routes/routes';
import { isMobileSafari } from 'react-device-detect';

export const StatBox = (props) => {
  return (
    <>
      <div className={`scpc--stats-inner`}>
        <div className={`scpc--stats-inner-holder`}>
          {props.loading ? <>
            <Skeleton active paragraph={{ rows: 1 }}/>
          </> : <>
            <h5>
              {props.title}
            </h5>
            <h3>
              {props.stat}
            </h3>
          </>}
        </div>
        <i className={props.icon}/>
      </div>
    </>
  );
}

export const KeywordsBox = (props) => {
  const { uuid } = require('uuidv4');
  let history = useHistory();
  return (
    <>
      <div className={`scpc--stats-keywords`}>
        <div className={`scpc--stats-keywords-inner`}>
          <div className={`scpc--stats-keywords-header`}>
            <h5>{props.title}</h5>
          </div>
          {props.loading ? <>
            <Skeleton active paragraph={{ rows: 3 }}/>
          </> : <>
            <div className={`scpc--stats-keywords-list`}>
              {props.keywords.map((keyword) => {
                return (
                  <div key={uuid()} className={`scpc--stats-keywords-keyword`}>
                    {keyword}
                  </div>
                );
              })}
            </div>
            {props.showMore ? <>
              <div className={`scpc--stats-button-holder`}>
                <Button flat={true} size={`medium`} color={`blue`} rounded={`default`}
                        onClick={() => {
                          history.push(`/analytics/${localStorage.getItem('activeProjectId')}/keywords-analytics`)
                        }}>Show more</Button>
              </div>
            </> : ''}
          </>}
        </div>
      </div>
    </>
  )
}

export const Dashboard = () => {
  const { projectId } = useParams();
  const [calendarStartDate, setStartDate] = useState(moment());
  const [calendarEndDate, setEndDate] = useState(moment());
  const [stats, setStats] = useState({
    charts: {
      counters: {}
    }
  });

  let fraudulentKeywords = [];
  if (stats.charts.keywords !== undefined) {
    fraudulentKeywords = _.map(stats.charts.keywords.data, (item) => {
      return item.keywords;
    });
  }

  const [adClickVisitors, setAdClicksVisitors] = useState(null);
  const [fraudCount, setFraudCount] = useState(null);
  const [clickFraudRatio, setClickFraudRatio] = useState(null);
  const [statsLoading, setStatsLoading] = useState(true);


  const activeProjectId = () => {
    return localStorage.getItem('activeProjectId');
  }
  /**
   *  Visual related settings
   */
  const leftNavBoxes = [
    {
      box: {
        'id': uuid(),
        'icon': 'linear-desktop',
        'name': 'Know more about fraudulent devices',
        'link': `/analytics/${activeProjectId()}/device-analytics`,
        'size' : 'full'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-mouse-left',
        'name': 'Know more about fraudulent clicks',
        'link': `/analytics/${activeProjectId()}/click-fraud`
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-chart-bars',
        'name': 'Generate report about fraud for Google',
        'link': getRoute(routes.reportsClickFraud,{'projectId': localStorage.getItem('activeProjectId')})
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-brain',
        'name': 'Use the best settings for my campaign',
        'link' : getRoute(routes.projectSettings,{'projectId': localStorage.getItem('activeProjectId')})
      }
    },
    // {
    //   box: {
    //     'id': uuid(),
    //     'icon': 'linear-aim',
    //     'name': 'Switch settings to the extreme mode'
    //   }
    // },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-glass',
        'name': 'Know more about auto-pause settings',
        'link': '/account/support'
      }
    }
  ];

  const [secondChart, setSecondChart] = useState(null);
  const [secondChartMobileSafari, setSecondChartMobileSafari] = useState(null);

  const dashboardChartSettings = (stats) => {
    return {
      series: stats.charts.clicksAndDetections.series,
      options: {
        chart: {
          height: 350,
          type: 'area',
          dropShadow: {
            enabled: true,
            enabledOnSeries: undefined,
            top: 15,
            left: 1,
            blur: 25,
            color: ['#f6f6f9', '#0296ff'],
            opacity: 1
          },

        },
        noData: {
          text: '',
          align: 'center',
          verticalAlign: 'middle',
          offsetX: 1,
          offsetY: 1,
          style: {
            color: undefined,
            fontSize: '14px',
            fontFamily: undefined
          }
        },
        legend: {
          position: 'bottom',
          fontSize: '14px',
          fontFamily: '"Work Sans",Helvetica, sans-serif',
          fontWeight: 400,
          inverseOrder: true
        },
        responsive: [
          {
            breakpoint: 991,
            options: {
              title: {
                text: 'Weekly overview - visitors vs fraud clicks',
                align: 'left',
                margin: 10,
                offsetX: 0,
                offsetY: -10,
                floating: false,
                style: {
                  fontSize: '15px',
                  fontWeight: '400',
                  fontFamily: '"Work Sans",Helvetica, sans-serif',
                  color: '#263238'
                },
              },
              chart: {
                toolbar: {
                  show: false
                }
              }
            },
            yaxis: [{
              labels: {
                show: false
              }
            }, {
              labels: {
                show: false
              }
            }]
          }
        ],
        title: {
          text: 'Weekly overview - visitors vs fraud clicks',
          align: 'left',
          margin: 10,
          offsetX: 0,
          offsetY: 0,
          floating: false,
          style: {
            fontSize: '20px',
            fontWeight: '400',
            fontFamily: '"Work Sans",Helvetica, sans-serif',
            color: '#263238'
          },
        },
        grid: {
          strokeDashArray: 2,
          borderColor: '#d6ebfe',

          row: {
            colors: ['transparent', 'transparent'],
            opacity: 0,
          },
          column: {
            colors: ['#f8f8f8', 'transparent'],
            borderColor: '#DDD'
          }
        },
        colors: ['#ff6360', '#0296ff'],
        fill: {
          type: ['gradient', 'gradient'],
          gradient: {
            shade: 'light',
            type: "horizontal",
            shadeIntensity: 0.5,
            gradientToColors: undefined, // optional, if not defined - uses the shades of same color in series
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            colorStops: [[
              {
                offset: 0,
                color: "#e7c88b",
                opacity: 1
              },
              {
                offset: 20,
                color: "#ff6162",
                opacity: 1
              },
              {
                offset: 60,
                color: "#fea311",
                opacity: 1
              },
              {
                offset: 80,
                color: "#ff6162",
                opacity: 1
              },
              {
                offset: 100,
                color: "#f6b639",
                opacity: 1
              }
            ], [
              {
                offset: 0,
                color: "#069ffe",
                opacity: 1
              },
              {
                offset: 20,
                color: "#3cdcfe",
                opacity: 1
              },
              {
                offset: 60,
                color: "#11b2ff",
                opacity: 1
              },
              {
                offset: 80,
                color: "#23d9ff",
                opacity: 1
              },
              {
                offset: 100,
                color: "#079ffe",
                opacity: 1
              }
            ]]
          }
        },
        markers: {
          size: [3, 7],
          colors: ['#ff6360', '#ffffff'],
          strokeColors: ['#ff6360', '#0296ff'],
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: false,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          colors: ['#ff6360', '#0296ff']
        },
        xaxis: {
          type: 'datetime',
          categories: stats.charts.clicksAndDetections.xaxis,
          tickAmount: 6,
          labels: {
            style: {
              colors: ['#313971'],
              fontSize: '14px',
              fontFamily: '"Work Sans",Helvetica, sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-xaxis-label',
            }
          },
          axisBorder: {
            show: false,
            color: '#78909C',
            height: 1,
            width: '100%',
            offsetX: 1,
            offsetY: 1
          },
          axisTicks: {
            show: false,
            borderType: 'solid',
            color: '#78909C',
            height: 6,
            offsetX: 1,
            offsetY: 1
          }//313971
        },
        yaxis: [{
          type: 'datetime',
          tickAmount: 6,
          labels: {
            style: {
              colors: ['#313971'],
              fontSize: '14px',
              fontFamily: 'Work Sans", sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            }
          },
          axisBorder: {
            show: false,
            color: '#78909C',
            height: 1,
            width: '100%',
            offsetX: 1,
            offsetY: 1
          },
          axisTicks: {
            show: false,
            borderType: 'solid',
            color: '#78909C',
            height: 6,
            offsetX: 1,
            offsetY: 1
          }
        }, {
          type: 'datetime',
          tickAmount: 6,
          opposite: true,
          labels: {
            style: {
              colors: ['#313971'],
              fontSize: '14px',
              fontFamily: 'Work Sans", sans-serif',
              fontWeight: 400,
              cssClass: 'apexcharts-yaxis-label',
            }
          },
          axisBorder: {
            show: false,
            color: '#78909C',
            height: 1,
            width: '100%',
            offsetX: 1,
            offsetY: 1
          },
          axisTicks: {
            show: false,
            borderType: 'solid',
            color: '#78909C',
            height: 6,
            offsetX: 1,
            offsetY: 1
          }
        }],
        tooltip: {
          style: {
            fontSize: '14px',
            fontFamily: 'Work Sans", sans-serif',
            fontWeight: 400,
          },
          x: {
            format: 'dd/MM/yy HH:mm'
          },
        },
      },
    }
  }

  const dashboardChartMobileSafariSettings = (stats) => {
    let seriesData = stats.charts.clicksAndDetections.series;
    if (!isMobileSafari) {
      return {};
    }
    seriesData.map(s => s.type = 'bar');
    return {
      // series: stats.charts.clicksAndDetections.series,
      series: seriesData,
      options: {
        chart: {
          type: 'bar',
          height: 350
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%',
            endingShape: 'rounded'
          },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 2,
          colors: ['transparent']
        },

        markers: {
          size: [3, 7],
          colors: ['#ff6360', '#ffffff'],
          strokeColors: ['#ff6360', '#0296ff'],
          strokeWidth: 2,
          strokeOpacity: 0.9,
          strokeDashArray: 0,
          fillOpacity: 1,
          shape: "circle",
          radius: 2,
          offsetX: 0,
          offsetY: 0,
          onClick: undefined,
          onDblClick: undefined,
          showNullDataPoints: false,
          hover: {
            size: undefined,
            sizeOffset: 3
          }
        },
        xaxis: {
          categories: stats.charts.clicksAndDetections.xaxis,
          crosshairs: {
            width: 1
          },
        },
        yaxis: {
          title: {
            text: 'Clicks/Frauds'
          },
          crosshairs: {
            width: 1
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val
            }
          }
        }
      }
      // options: {
      //   chart: {
      //     type: 'bar',
      //     height: 350,
      //     stacked: false,
      //     toolbar: {
      //       show: false
      //     },
      //     zoom: {
      //       enabled: true
      //     }
      //   },
      //   responsive: [{
      //     breakpoint: 480,
      //     options: {
      //       legend: {
      //         position: 'bottom',
      //         offsetX: -10,
      //         offsetY: 10
      //       }
      //     }
      //   }],
      //   plotOptions: {
      //     bar: {
      //       horizontal: false,
      //       columnWidth: '55%',
      //       endingShape: 'rounded'
      //     },
      //   },
      //   xaxis: {
      //     type: 'datetime',
      //     categories: stats.charts.clicksAndDetections.xaxis,
      //   },
      //   legend: {
      //     position: 'right',
      //     offsetY: 40
      //   },
      //   fill: {
      //     opacity: 1
      //   }
      // },
    }
  }


  const updateStats = (stats) => {
    setStats(stats);
    setSecondChart(dashboardChartSettings(stats));
    setSecondChartMobileSafari(dashboardChartMobileSafariSettings(stats));
    setAdClicksVisitors(stats.charts.counters.clickCount);
    setFraudCount(stats.charts.counters.fraudCount);
    setClickFraudRatio(stats.charts.counters.clickFraudRatio);
    setStatsLoading(false);
  }

  useEffect(() => {
    ProjectsApi.getStats(projectId).then((stats) => {
      updateStats(stats);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const setCalendarStateAndUpdateDataDates = (event) => {
    setStatsLoading(true);
    let startDate = moment(event ? event.startDate : calendarStartDate)
    .format('YYYY-MM-DD')
    .toString();
    let endDate = moment(event ? event.endDate : calendarEndDate).format('YYYY-MM-DD').toString();

    ProjectsApi.getStats(projectId, startDate, endDate).then((stats) => {
      setSecondChart(dashboardChartSettings(stats));
      setSecondChartMobileSafari(dashboardChartMobileSafariSettings(stats));
      updateStats(stats);
    });

    if (event) {
      setStartDate(event.startDate);
      setEndDate(event.endDate);
    }
  }
  const onCalendarStripeChange = (event) => {
    setCalendarStateAndUpdateDataDates(event);
  }
  const onCalendarSave = () => {
    setCalendarStateAndUpdateDataDates();
    // let startDate = moment(calendarStartDate).format('YYYY-MM-DD').toString();
    // let endDate = moment(calendarEndDate).format('YYYY-MM-DD').toString();
    // ProjectsApi.getStats(projectId, startDate, endDate).then((stats) => {
    //   setSecondChart(dashboardChartSettings(stats));
    //   setSecondChartMobileSafari(dashboardChartMobileSafariSettings(stats));
    //   updateStats(stats);
    // });
  };


  let history = useHistory();
  return (
    <>
      <LeftMainBoxes
        boxes={leftNavBoxes}
        cols={2}
        boxTextCenter={true}
      >
        <div className={`scpc--dashboard-baner`}>
          <div className={`scpc--dashboard-baner-superman`}>
            <img src={`/images/superman.png`} alt={`superman`}/>
          </div>
          <div className={`scpc--dashboard-baner-inner`}>
            <h4>Hey {auth0Client.getUser().givenName || null}</h4>
            <p>
              You are a <strong>SUPERHERO</strong> now!
              Want to know how to use
              your powers?
            </p>
            <ul>
              <li>
                <button>
                  Teach Me
                </button>
              </li>
              <li>
                <button>
                  Maybe later
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className={`scpc--dashboard-iwantto`}>
          <h4>I want to: </h4>
          <Button
            flat={true}
            color={`blue`}
            rounded={`default`}
            width={`full`}
            textAlign={`center`}
            hasIcon={true}
            icon={`linear-plus-circle`}
            style={{ 'width': '100%' }}
            onClick={() => {
              history.push(getRoute(routes.addProject))
            }}
          >
            Protect another domain
          </Button>
        </div>

      </LeftMainBoxes>
      <RightContent>
        <div className={`container-fluid`}>
          <div className={`row`}>
            <div className={`col-12`}>
              <div className={`scpc--dashboard-calendar-holder`}>
                <div className={`container-fluid`}>
                  <div className={`row`}>
                    <div className={`col-8`}>
                      <CalendarStripe
                        singleDate={false}
                        startDate={calendarStartDate}
                        endDate={calendarEndDate}
                        onChange={onCalendarStripeChange}
                      />
                    </div>
                    <div className={`col-4`}>
                      <Calendar
                        startDate={calendarStartDate}
                        endDate={calendarEndDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        onCalendarSave={onCalendarSave}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`row`}>
            <div className={`col-12 ${isMobileSafari ? 'isMobileSafari' : ''}`}>
              <svg xmlns="http://www.w3.org/2000/svg" version="1"
                   style={{ 'position': 'absolute' }}>
                <defs>
                  <linearGradient id="gradientLineTest" x1="40" y1="210" x2="460" y2="210"
                                  gradientUnits="userSpaceOnUse"
                                  gradientTransform="rotate(90)">
                    <stop stopColor="rgba(2, 150, 255, 0.05)" offset="0"/>
                    <stop stopColor="#0296ff" offset="1"/>
                  </linearGradient>
                </defs>
              </svg>

              {(secondChart && secondChartMobileSafari) ?
                <ReactApexChart
                  options={isMobileSafari ? secondChartMobileSafari.options : secondChart.options}
                  series={isMobileSafari ? secondChartMobileSafari.series : secondChart.series}
                  type={isMobileSafari ? "bar" : "area"} height={400}
                />
                : 'Loading...'}
            </div>
          </div>
        </div>
        <div className={`container-fluid mt-40 scpc--dashboard-stats-container`}>
          <div className={`row scpc--dashboard-stats-row`}>
            <div className={`col-8 scpc--dashboard-stats-firstcol`}>
              <div className={`container-fluid`}>

                <div className={`row`}>
                  <div className={`col-4`}>
                    <ContentBox
                      gradient={true}
                      shadow={`small-2`}
                      leftGradient={`blue`}
                      margin={`mb-30`}
                    >
                      <StatBox
                        title={`Google Ads Visitors`}
                        stat={adClickVisitors}
                        icon={`linear-mouse-left`}
                        loading={statsLoading}
                      />
                    </ContentBox>
                  </div>
                  <div className={`col-4`}>
                    <ContentBox
                      gradient={true}
                      shadow={`small-2`}
                      leftGradient={`purple`}
                      margin={`mb-30`}
                    >
                      <StatBox
                        title={`Detected Fraud Clicks`}
                        stat={fraudCount}
                        icon={`linear-aim`}
                        loading={statsLoading}
                      />
                    </ContentBox>
                  </div>
                  <div className={`col-4`}>
                    <ContentBox
                      gradient={true}
                      shadow={`small-2`}
                      leftGradient={`darkblue`}
                      margin={`mb-30`}
                    >
                      <StatBox
                        title={`Click / Detection ratio`}
                        stat={clickFraudRatio}
                        icon={`linear-desktop`}
                        loading={statsLoading}
                      />
                    </ContentBox>
                  </div>
                </div>


                <div className={`row`}>
                  <div className={`col-4`}>
                    <ContentBox
                      gradient={true}
                      shadow={`small-2`}
                      leftGradient={`green`}
                      margin={`mb-30`}
                    >
                      <StatBox
                        title={`Blocked devices`}
                        stat={stats.charts.counters.fingerprintBanCount || '-'}
                        icon={`linear-mouse-left`}
                        loading={statsLoading}
                      />
                    </ContentBox>
                  </div>
                  <div className={`col-4`}>
                    <ContentBox
                      gradient={true}
                      shadow={`small-2`}
                      leftGradient={`orange`}
                      margin={`mb-30`}
                    >
                      <StatBox
                        title={`VPNs detected`}
                        stat={stats.charts.counters.vpnCount || '-'}
                        icon={`linear-aim`}
                        loading={statsLoading}
                      />
                    </ContentBox>
                  </div>
                  <div className={`col-4`}>
                    <ContentBox
                      gradient={true}
                      shadow={`small-2`}
                      leftGradient={`inverseblue`}
                      margin={`mb-30`}
                    >
                      <StatBox
                        title={`Blocked devices`}
                        stat={stats.charts.counters.blockedDevicesRatio || '-'}
                        icon={`linear-desktop`}
                        loading={statsLoading}
                      />
                    </ContentBox>
                  </div>
                </div>

              </div>
            </div>
            <div
              className={`col-4 scpc--dashboard-stats-secondcol`}
              style={
                {
                  'display': 'flex',
                  'paddingRight': '30px',
                  'paddingLeft': '0'
                }
              }>
              <ContentBox
                gradient={true}
                shadow={`small-2`}
                leftGradient={`inverseblue`}
                margin={`mb-30`}
              >
                <KeywordsBox
                  title={`Most fraudulent keywords`}
                  keywords={fraudulentKeywords}
                  showMore={'/reports/keywords'}
                  loading={statsLoading}
                />
              </ContentBox>
            </div>
          </div>
        </div>

      </RightContent>
    </>
  )
};