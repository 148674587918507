import React, {useEffect} from 'react';
import {useHistory, withRouter} from 'react-router-dom';
import auth0Client from '../Auth';

/**
 * Handle auth0 callback and redirect to / after success
 * TODO: redirect to requested URI
 *
 * @param props
 * @returns {*}
 * @constructor
 */
function Callback(props) {
  const history = useHistory();
  const {setIsAuthorizing, performRedirects} = props;
  // console.log(props);
  useEffect(() => {
    auth0Client.handleAuthentication().then(() => {
      setIsAuthorizing(false);
      performRedirects(props.location.pathname, '/');
    }).catch((err) => {
      history.push('/');
      // setIsAuthorizing(false);
      // performRedirects(props.location.pathname, '/callback');
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <></>
}

export default withRouter(Callback);