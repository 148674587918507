import React, {useState} from 'react';
import {Link, useHistory, useParams} from "react-router-dom";
import routes, {getRoute} from '../../routes/routes';
import auth0Client from '../../Auth';
import ReactTooltip from "react-tooltip";
import {DomainSelector} from "../CF/DomainSelector/DomainSelector";
import {RightNavigation} from "../CF/RightNavigation/RightNavigation";
import {NotificationsTooltip} from "../CF/Notifications/NotificationsTooltip";
import {Modal} from "../CF/Modal/Modal";
import NotificationsContext from "../../context/Notifications/NotificationsContext";
import ReactJoyride, {ACTIONS, EVENTS, STATUS} from 'react-joyride';
import {ToastContainer} from 'react-toastify';

const TopNavBar = (props) => {
  // eslint-disable-next-line no-unused-vars
  const signOut = () => {
    auth0Client.signOut();
    props.history.replace('/');
  };
  // const projectContext = useContext(ProjectContext);

  let history = useHistory();
  let {projectId} = useParams();

  const [activeFirstDrawer, setActiveFirstDrawer] = useState(false);
  const [isNotificationTooltipActive, setIsNotificationsTooltipActive] = useState(false);
  const [newNotifications, setNewNotifications] = useState(true);
  const [addDomainModalActive, setAddDomainModalActive] = useState(false);
  const [joyrideRun, setJoyrideRun] = useState(false);
  // TODO: does it have to be a state?
  const [sidebarOpen] = useState(false);
  const [stepIndex, setStepIndex] = useState(0);

  // TODO: does it have to be a state?
  // eslint-disable-next-line no-unused-vars
  const [steps, setSteps] = useState(
    [
      {
        target: '.scpc--header-domainselect',
        content: 'Here, you can select between all of your projects',
        disableBeacon: true
      },
      {
        target: '.scpc--header-rightnav-toggle',
        content: 'Here, you have quick navigation through all of the important things about your account',
        disableBeacon: true
      },
      {
        target: '.scpc--dashboard-calendar-holder',
        content: 'You can select a date range for each view in this type of input',
        disableBeacon: true
      }, {
      target: '.scpc--leftmainboxes-holder',
      content: 'On the left side of each view, you have access to the most important things based on the context of current view',
      disableBeacon: true
    }, {
      target: '.scpc--nav-left-menu-list',
      content: 'Here, you have navigation through the all system functions like analytics, reports, domain settings and more',
      disableBeacon: true
    }, {
      target: '.scpc--nav-left-menu-list li:nth-child(1)',
      content: 'Dashboard for the current project charts and basic statistics in one place',
      disableBeacon: true
    }, {
      target: '.scpc--nav-left-menu-list li:nth-child(2)',
      content: 'All analytics regarding the current project. You can preview most fraudulent clicks, keywords, IPs, devices and more',
      disableBeacon: true
    }, {
      target: '.scpc--nav-left-menu-list li:nth-child(3)',
      content: 'Here you can manage all of the project settings, like block thresholds, groups, auto pause settings and more',
      disableBeacon: true
    }, {
      target: '.scpc--nav-left-menu-list li:nth-child(4)',
      content: 'Here you can manage all of yours account settings, like payments, account details, notifications and more',
      disableBeacon: true
    }, {
      target: '.scpc--nav-left-menu-list li:nth-child(6)',
      content: 'Here you can generate reports for every type of analytics we have',
      disableBeacon: true
    }, {
      target: '.scpc--header-right-nav li:nth-child(2)',
      content: 'If you have any questions, or want to know more about the system, here you should start seeking for answers',
      disableBeacon: true
    }
    ]
  );
  const handleClickStart = e => {
    e.preventDefault();
    setJoyrideRun(true);
  };
  // const [notificationsList, setNotificationsList] = useState([
  //   {
  //     'id': 1,
  //     'type': 'blocked',
  //     'icon': 'icon-0432-desktop',
  //     'read': false,
  //     'content': {
  //       'title': 'New device blocked',
  //       'browser': 'Chrome',
  //       'browserVersion': '81.0.3.13',
  //       'mobile': true,
  //       'vpn': true,
  //       'ip': '87.41.21.191',
  //       'seen': '8',
  //       'ipMaskBlocked': false
  //     }
  //   },
  //   {
  //     'id': 2,
  //     'type': 'trend',
  //     'icon': 'icon-0508-chart-growth',
  //     'read': false,
  //     'content': {
  //       'title': 'Campaign clicks trend has changed',
  //       'campaignName': '[TEST] - my campaign name',
  //       'trendContent': 'Has noticeable <strong>41%</strong> growth of fraudulent clicks since last week'
  //     }
  //   },
  //   {
  //     'id': 3,
  //     'type': 'trialReminder',
  //     'icon': 'icon-calendar',
  //     'read': false,
  //     'content': {
  //       'title': 'Your trial will expire in 6 days!',
  //       'text': 'switch to pro now with 10% discount',
  //       'icon': 'icon-time-reverse'
  //     }
  //   },
  //   {
  //     'id': 4,
  //     'type': 'blocked',
  //     'icon': 'icon-0432-desktop',
  //     'read': false,
  //     'content': {
  //       'title': 'New device blocked',
  //       'browser': 'Firefox',
  //       'browserVersion': '81.0.3.13',
  //       'mobile': true,
  //       'vpn': true,
  //       'ip': '87.41.21.191',
  //       'seen': '8',
  //       'ipMaskBlocked': false
  //     }
  //   },
  //   {
  //     'id': 5,
  //     'type': 'trend',
  //     'icon': 'icon-0508-chart-growth',
  //     'read': false,
  //     'content': {
  //       'title': 'Campaign clicks trend has changed',
  //       'campaignName': '[TEST] - my campaign name',
  //       'trendContent': 'Has noticeable <strong>41%</strong> growth of fraudulent clicks since last week'
  //     }
  //   },
  //   {
  //     'id': 6,
  //     'type': 'trialReminder',
  //     'icon': 'icon-calendar',
  //     'read': false,
  //     'content': {
  //       'title': 'Your trial will expire in 6 days!',
  //       'text': 'switch to pro now with 10% discount',
  //       'icon': 'icon-time-reverse'
  //     }
  //   }
  // ]);

  /*
   *   To show and hide modal, you have to pass as a prop two functions,
   *   - one modalActiveStatus which is from your state to show modal ( true / false )
   *   - two modalClose method, which alter the state you passed through the first prop
   *   You add content as a children of the <Modal></Modal> component
   *   Within the Modal component, there is no state to set. It's using booleans from props to show and hide
   */
  const modalToggleAddNewDomain = () => setAddDomainModalActive(!addDomainModalActive);
  // End of modal

  const toggleFirstDrawer = () => {
    if (activeFirstDrawer) {
      document.body.classList.remove('fixed');
    } else {
      document.body.classList.add('fixed');
    }

    setActiveFirstDrawer(!activeFirstDrawer);
  }

  // useEffect(() => {
  //   setNotificationPooler(setInterval(()=> {
  //     UserApi.getNotifications().then((res) => {
  //         setNotificationsList(res.notifications);
  //       }
  //     );
  //   }, 10000));
  // }, []);
  const handleJoyrideCallback = data => {
    // const { joyride } = this.props;
    // console.log(data);
    // eslint-disable-next-line no-unused-vars
    const { action, index, type, status } = data;
    // console.log(status);

    if (status === STATUS.FINISHED || status === STATUS.SKIPPED || type === EVENTS.TOUR_END) {
      setJoyrideRun(false);
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      setStepIndex(stepIndex + (action === ACTIONS.PREV ? -1 : 1));
    }
  };

  return (
    <>
      <ToastContainer/>
      <ReactJoyride
        continuous
        showProgress
        showSkipButton
        stepIndex={stepIndex}
        sidebarOpen={sidebarOpen}
        scrollToFirstStep={true}
        steps={steps} run={joyrideRun}
        callback={handleJoyrideCallback}
        styles={{
          options: {
            arrowColor: '#e3ffeb',
            backgroundColor: '#FFFFFF',
            overlayColor: 'rgba(0, 0, 0, 0.4)',
            primaryColor: '#0296ff',
            textColor: '#313971',
            width: 400,
            zIndex: 1000,
          }
        }}
      />
      <Modal modalActiveStatus={addDomainModalActive} modalClose={modalToggleAddNewDomain}>
        {
          <>
            <div className={'scpc--modal-header'}>
              <div className={'scpc--modal-browserimage'}>
                <div className={'scpc--modal-browser-top-bar'}>
                  <div className={'scpc--modal-browser-address'}>
                    <span>www.yoursite.com</span>
                  </div>
                  <div className={'scpc--modal-browser-top-buttons'}>
                    <span/>
                    <span/>
                  </div>
                </div>
                <div className={'scpc--modal-browser-content'}>
                  <i className={'icon-add-new-domain'}/>
                </div>
              </div>
            </div>
            <div className={'scpc--modal-content'}>
              <h3>
                Add new domain
              </h3>
              <p>
                Now you'll start creating new project in SafeCPC. Make sure you have access to the Google Ads account.
              </p>
            </div>
            <div className={'scpc--modal-footer'}>
              <Link to={getRoute(routes.addProject)}
                    className={'scpc--button-flat color-blue button-rounded button-wide'}>
                Go to domain wizard
              </Link>

            </div>
          </>
        }
      </Modal>
      <header className={`scpc--header ${activeFirstDrawer ? 'active-firstDrawer' : ''}`}>
        <div className="scpc--header-left">
          <div className="scpc--header-pagename">
            <h1>
              {props.title ? props.title : `Dashboard`}

            </h1>
          </div>
          {!props.forcedPaymentsDetails ? <>
            {auth0Client.isAuthenticated() && <>
              <DomainSelector/>
              <button
                className="scpc--header-addnew"
                onClick={modalToggleAddNewDomain}
              >
                <i className="icon-circle-plus"/>
                <span>Add new domain</span>
              </button>
            </>}
          </> : ''}
        </div>
        <div className="scpc--header-right">
          {auth0Client.isAuthenticated() && <>
            <ul className="scpc--header-right-nav">
              <li>
                <button onClick={(e) => handleClickStart(e)}>Overview</button>
              </li>
              <li><Link to={getRoute(routes.support, { projectId })}>Need help?</Link></li>
              {!props.forcedPaymentsDetails ?
                <>
                  <li className="scpc--header-right-nav-iconlink">
                    <Link to={getRoute(routes.projectSettings, { projectId })}>
                      <i className="icon-cog"/>
                    </Link>
                  </li>
                  <li
                    className="scpc--header-right-nav-iconlink scpc--notifications-tooltip-toggle">
                    <div>
                      <span
                        className={`scpc--notifications-new ${newNotifications ? ' active' : ''}`}/>
                      <i className="icon-bell" onClick={() => {
                        setIsNotificationsTooltipActive(!isNotificationTooltipActive);
                        setNewNotifications(false);
                      }}/>
                    </div>
                    <NotificationsContext.Consumer>
                      {({ notifications, isLoading }) => (
                        isLoading ? <></> :
                          <NotificationsTooltip
                            notificationsList={notifications}
                            activeNotifications={isNotificationTooltipActive}
                            projectId={projectId}
                          />
                      )}
                    </NotificationsContext.Consumer>
                  </li>
                </>
                : ''}
            </ul>
            <div
              className="scpc--header-avatar"
              onClick={() => history.push(getRoute(routes.account, { projectId }))}
            >
              <ReactTooltip/>
              <img src="./images/user.png" data-tip="My Account" alt={`my account`}/>
            </div>
            {!props.forcedPaymentsDetails ?
              <div className="scpc--header-rightnav-toggle">
                <button
                  className="scpc--button-flat color-blue"
                  onClick={() => {
                    toggleFirstDrawer();
                  }}>
                  <i className="icon-dot"/>
                </button>
              </div>
              : ''}
          </>}
          {!auth0Client.isAuthenticated() &&
          <button className="scpc--button-border rounded-default button-small color-blue"
                  onClick={auth0Client.signIn}>Sign In</button>}
        </div>
      </header>
      <RightNavigation
        activeFirstDrawer={activeFirstDrawer}
        toggleFirstDrawer={toggleFirstDrawer}
        forcePaymentsDetails={props.forcedPaymentsDetails}
      />
    </>

  );
};
export default TopNavBar;