import React, {useState} from 'react';
import * as ProjectsApi from '../../../api/projects';
import {Button} from 'antd';
import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const ApproveAdWordsLink = (props) => {
  const {nextStep, prevStep, state} = props;
  const [isLoading, setIsLoading] = useState(false);
  const submit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    ProjectsApi.updateAdWordsAccountLinkStatus(state.cfProject.id)
      .then((result) => {
        if (result.isAccountLinked) {
          nextStep({});
        } else {
          // TODO: reasonable error message

          toast.error('Account is not linked yet', {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
        setIsLoading(false);
      });
  };

  return (
    <>
      <ToastContainer/>
      <div className="wizard_right-step--2">
        <div className="wizard_right-content">
          <div className="wizard_right-content--timeline">
            <div className="wizard_right-content--timeline-elem">
										<span>
											Sign in to your<br/>
											Google ADS Account
										</span>
            </div>
            <div className="wizard_right-content--timeline-elem">
										<span>
											Click on <strong>Tools</strong>,<br/>
											then <strong>Account Access</strong>
										</span>
            </div>
            <div className="wizard_right-content--timeline-elem">
										<span>
											Switch to the<br/>
											<strong>Managers Tab</strong>
										</span>
            </div>
            <div className="wizard_right-content--timeline-elem">
										<span>
											Approve request from<br/>
											<strong>SafeCPC Clients Center</strong>
										</span>
            </div>
          </div>
          <div className="wizard_right-content--header">

          </div>
          <div className="wizard_right-content--inner">
            <div className="browser--main-window with-shadow light">
              <div className="browser--top-part">
                <div className="browser--top-part-buttons">
                  <div className="browser--top-part-button"></div>
                  <div className="browser--top-part-button"></div>
                  <div className="browser--top-part-button"></div>
                </div>
                <div className="browser--address-bar">
                  <span>https://account.google.com</span>
                </div>
              </div>
              <div className="browser--content">
                <div className="browser--content-inner">

                </div>
              </div>
            </div>
          </div>
          <div className="wizard_right-content--buttons">
            <div className="container-fluid">
              <div className="row">
                <div className="col-md-5"></div>
                <div className="col-md-7">
                  <div className="wizard_right-content--buttons-inner">
                    <button onClick={prevStep}
                       className="scpc--button-border button-open button-border color-gray button-rounded">
                      Go back
                    </button>
                    <Button type="primary" loading={isLoading} onClick={submit} shape="round" size={`large`}>Next step</Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ApproveAdWordsLink;