import {RightContent} from '../appLayout/RightContent';
import {Calendar} from '../Calendar/Calendar';
import {ContentBox, ContentBoxHeader} from '../box/ContentBox';
import {Button, Select, Switch, Tag} from 'antd';
import {DownloadOutlined} from '@ant-design/icons';
import React, {useState} from 'react';
import {ReportsLeft} from './ReportsLeft';
import moment from 'moment';
import {useParams} from 'react-router-dom';
import * as ProjectsApi from '../../../api/projects';

export const ReportGenerator = (props) => {
  const REPORT_SIMPLIFIED = 'simplified';
  const REPORT_FULL = 'full';
  const REPORT_CUSTOM = 'custom';

  const [calendarStartDate, setStartDate] = useState(moment().subtract(1, "week"));
  const [calendarEndDate, setEndDate] = useState(moment());
  const [showDownloadButton, setShowDownloadButton] = useState(false);
  const [downloadReportUrl, setDownloadReportUrl] = useState(null);
  const [reportType, setReportType] = useState(REPORT_SIMPLIFIED);

  const {reportColumns, simpleReportColumnsIds} = props;
  const simpleReportColumns = reportColumns.filter((el) => simpleReportColumnsIds.includes(el.id));

  const [customReportSelectedValues, setCustomReportSelectedValues] = useState([]);

  const {projectId} = useParams();

  const updateReportType = (val) => {
    setReportType(val);
    setShowDownloadButton(false);
  }

  const {Option} = Select;
  const downloadReport = () => {
    let params = {
      reportType,
      fromDate: moment(calendarStartDate).format('YYYY-MM-DD').toString(),
      toDate: moment(calendarEndDate).format('YYYY-MM-DD').toString()
    }

    switch (reportType) {
      case REPORT_SIMPLIFIED:
        // a simple report is actually a custom one with pre-defined column list
        params.reportType = REPORT_CUSTOM;
        params.customReportColumns = simpleReportColumns.map((el) => el.id);
        break;

      case REPORT_FULL:
        break;

      case REPORT_CUSTOM:
        params.customReportColumns = customReportSelectedValues;
        break;
      default:
        break;
    }

    ProjectsApi.generateReport(projectId, params).then(res => {
      setDownloadReportUrl(res.url);
      setShowDownloadButton(true);
    });
  }

  return (
    <>
      <ReportsLeft load={props.load} project={projectId}/>
      <>
        <RightContent>
          <div className={`container-fluid`}>
            <div className={`row justify-content-center`}>
              <div className={`col-8 col-xs-12 col-sm-12 col-md-12 col-lg-8`}>
                <div className={`scpc--account-baner`}>
                  <div className={`scpc--account-baner-content`}>
                    <h4>Generate reports</h4>
                    <p>
                      You can generate reports about anything from our analytics dashboard.
                      You can send to the Google as a proof of fraud which occurred during your
                      campaign.
                    </p>
                  </div>
                  <img
                    src={`/svg/reportsbig.svg`}
                    className={`scpc--account-baner-image`}
                    alt={`Payments baner`}
                  />
                </div>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                  <ContentBoxHeader title={props.title}>
                    <Calendar
                      startDate={calendarStartDate}
                      endDate={calendarEndDate}
                      setStartDate={setStartDate}
                      setEndDate={setEndDate}
                      onCalendarSave={() => {
                        setShowDownloadButton(false);
                      }}
                    />
                  </ContentBoxHeader>
                  <div className={`container-fluid`}>
                    <div className={`row`}>
                      <div
                        className={`col-lg-8 col-md-12 col-sm-12 pl-0 scpc--reports-left-col`}>
                        <div className="scpc--contentbox-content">
                          <h3 className={`mb-20`}>
                            <i className={`linear-chart-bars`}/>
                            <span>Report settings</span>
                          </h3>
                        </div>
                        <div className={`scpc--reports-settingsrow`}
                             style={{'paddingTop': '0'}}>
                          <div className={`scpc--contentbox-switcherrow`}>
                            <Switch
                              className={`scpc--controls-switch`}
                              onChange={(value) => updateReportType(REPORT_SIMPLIFIED)}
                              checked={reportType === REPORT_SIMPLIFIED}
                              defaultChecked={reportType === REPORT_SIMPLIFIED}
                            />
                            <p className={`mr-10`}>Simplified report </p>
                            <span> | columns: </span>
                          </div>
                          <div style={{'marginTop': '5px'}}>
                            {simpleReportColumns.map((col) =>
                              <Tag key={col.id}>
                                {col.name}
                              </Tag>)}
                          </div>
                        </div>
                        <div className={`scpc--reports-settingsrow`}>
                          <div className={`scpc--contentbox-switcherrow`}>
                            <Switch
                              className={`scpc--controls-switch`}
                              onChange={(value) => updateReportType(REPORT_FULL)}
                              checked={reportType === REPORT_FULL}
                            />
                            <p className={`mr-10 mb-0`}>Full report </p>
                            <span> | all columns </span>
                          </div>
                        </div>
                        <div className={`scpc--reports-settingsrow`}>
                          <div className={`scpc--contentbox-switcherrow`}
                               style={{'flexFlow': 'row wrap'}}>
                            <Switch
                              className={`scpc--controls-switch`}
                              onChange={(value) => updateReportType(REPORT_CUSTOM)}
                              checked={reportType === REPORT_CUSTOM}
                            />
                            <p
                              className={`mr-10 mb-0 ${reportType.custom ? 'mb-20' : ''}`}>Custom
                              report </p>
                            <div style={{
                              'width': '100%',
                              'display': `${reportType === REPORT_CUSTOM ? 'flex' : 'none'}`,
                              'marginTop': '20px'
                            }}>
                              <Select
                                mode="multiple"
                                style={{width: '100%'}}
                                placeholder="Select columns"
                                onChange={(val) => {
                                  setCustomReportSelectedValues(val);
                                  setShowDownloadButton(false);
                                }}
                                optionLabelProp="label"
                              >
                                {reportColumns.map(column =>
                                  <Option key={column.id}>
                                    {column.name}
                                  </Option>)}
                              </Select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`col-lg-4 col-md-12 col-sm-12 col-xs-12 pr-0`}>
                        <div className="scpc--contentbox-content">
                          <h3 className={`mb-20`}>
                            <i className={`linear-floppy-disk`}/>
                            <span>Generate report</span>
                          </h3>
                          <p>Download report in CSV format</p>
                          {!showDownloadButton && <Button
                            type="primary"
                            shape="round"
                            icon={<DownloadOutlined/>}
                            size={`medium`}
                            className={`mb-15`} onClick={() => downloadReport('csv')}>
                            Generate Report
                          </Button>}

                          {showDownloadButton && <Button
                            type="primary"
                            shape="round"
                            con={<DownloadOutlined/>}
                            href={downloadReportUrl}
                            size={`medium`}
                            className={`mb-15`}>
                            Download report
                          </Button>}
                        </div>
                      </div>
                    </div>
                  </div>
                </ContentBox>
                {/*<ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>*/}
                {/*  <ContentBoxHeader title={`Send report via e-mail`}/>*/}
                {/*  <Form*/}
                {/*    name="send-report"*/}
                {/*    className="login-form"*/}
                {/*    initialValues={{remember: true}}*/}
                {/*    onFinish={onFinish}*/}
                {/*    layout="inline"*/}
                {/*  >*/}
                {/*    <Form.Item*/}
                {/*      name="email"*/}
                {/*      rules={[{required: true, message: 'Please input your e-mail address!'}]}*/}
                {/*    >*/}
                {/*      <Input prefix={<UserOutlined className="site-form-item-icon"/>}*/}
                {/*             placeholder="E-mail address" size={`large`}/>*/}
                {/*    </Form.Item>*/}
                {/*    <Form.Item>*/}
                {/*      <Button type="primary" htmlType="submit" className="login-form-button"*/}
                {/*              size={`large`}>*/}
                {/*        Send*/}
                {/*      </Button>*/}
                {/*    </Form.Item>*/}
                {/*  </Form>*/}
                {/*</ContentBox>*/}
              </div>
            </div>
          </div>
        </RightContent>
      </>
    </>
  );
}

