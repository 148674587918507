import React, { useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { RightNavBox } from '../box/RightNavBox';
import { MyAccount } from "./MyAccount";
import { Payments } from "./Payments";
import { Notifications } from "./Notifications";
import { Support } from "./Support";
import { Invoices } from "./Invoices";
import { ContactUs } from "./ContactUs";
import Scrollbar from "react-scrollbars-custom";
import auth0Client from '../../../Auth';
import { DomainSelector } from '../DomainSelector/DomainSelector';
import routes, { getRoute } from '../../../routes/routes';
import { Modal } from '../Modal/Modal';
import { Button } from 'antd';
import { PlusCircleOutlined } from '@ant-design/icons';
import { isMobileSafari } from 'react-device-detect';
import { SavedWithUs } from '../Account/SavedWithUs';

export const RightNavigation = ({ activeFirstDrawer, toggleFirstDrawer }, props) => {
  let history = useHistory();
  const signOut = () => {
    auth0Client.signOut();
    history.push('/');
  };
  // const [userName, setUserName] = useState('Alex');
  // const [savingValue, setSavingValue] = useState('$3 000,00');
  const [activeSecondDrawer, setActiveSecondDrawer] = useState(false);
  const [activeSecondComponent, setActiveSecondComponent] = useState('');
  const [addDomainModalActive, setAddDomainModalActive] = useState(false);
  const [rightNavBoxes, setRightNavBoxes] = useState([
      {
        'id': 1,
        'name': 'My account',
        'link': '#',
        'icon': 'icon-user',
        'active': false,
        'componentName': 'MyAccount'
      },
      {
        'id': 2,
        'name': 'Payments',
        'link': '#',
        'icon': 'icon-0346-credit-card',
        'active': false,
        'componentName': 'Payments'
      },
      {
        'id': 3,
        'name': 'Notifications',
        'link': '#',
        'icon': 'icon-bell',
        'active': false,
        'componentName': 'Notifications'
      },
      {
        'id': 4,
        'name': 'Invoices',
        'link': '#',
        'icon': 'icon-0217-document',
        'active': false,
        'componentName': 'Invoices'
      },
      {
        'id': 5,
        'name': 'Support',
        'link': '#',
        'icon': 'icon-help',
        'active': false,
        'componentName': 'Support'
      },
      {
        'id': 6,
        'name': 'Contact Us',
        'link': '#',
        'icon': ' icon-0152-envelope-open',
        'active': false,
        'componentName': 'ContactUs'
      }
    ]
  );
  // eslint-disable-next-line no-unused-vars
  const [quickLinks, setQuickLinks] = useState([
      {
        'id': 1,
        'name': 'Change account details',
        'link': '/account'
      },
      // {
      //   'id': 2,
      //   'name': 'Change password',
      //   'link': '#'
      // },
      {
        'id': 3,
        'name': 'Download last invoice',
        'link': '#'
      },
      {
        'id': 4,
        'name': 'Change payement details',
        'link': '/account/add-payment-method'
      }
    ]
  );
  const toggleSecondDrawer = (activeElem) => {
    let currentActive = rightNavBoxes.find(({ active }) => active === true);
    if (currentActive) {
      if (currentActive.id === activeElem.id) {
        currentActive.active = !currentActive.active;
        setActiveSecondDrawer(false);
        setRightNavBoxes(rightNavBoxes);
        return;
      } else {
        currentActive.active = !currentActive.active;
      }
    }
    rightNavBoxes.find(({ id }) => id === activeElem.id).active = true;
    setActiveSecondDrawer(true);
    setRightNavBoxes(rightNavBoxes);
    setActiveSecondComponent(activeElem.componentName);
  };

  const renderSecondComponent = (activeSecondComponent) => {
    switch (activeSecondComponent) {
      case 'MyAccount':
        return <MyAccount/>;
      case 'Payments':
        return <Payments/>;
      case 'Invoices':
        return <Invoices/>;
      case 'Notifications':
        return <Notifications/>;
      case 'Support':
        return <Support/>;
      case 'ContactUs':
        return <ContactUs/>;
      default:
        return null;
    }
  };
  const closeDrawers = () => {
    // Turn off drawers
    toggleFirstDrawer();
    setActiveSecondDrawer(false);
    // Clear active drawers components
    setActiveSecondComponent('');
    let currentActive = rightNavBoxes.find(({ active }) => active === true);
    if (currentActive) {
      currentActive.active = !currentActive.active;
    }
    // Update second drawer active boxes
    setRightNavBoxes(rightNavBoxes);

  };
  /*
  *   To show and hide modal, you have to pass as a prop two functions,
  *   - one modalActiveStatus which is from your state to show modal ( true / false )
  *   - two modalClose method, which alter the state you passed through the first prop
  *   You add content as a children of the <Modal></Modal> component
  *   Within the Modal component, there is no state to set. It's using booleans from props to show and hide
  */
  const modalToggleAddNewDomain = () => setAddDomainModalActive(!addDomainModalActive);
  // End of modal

  return (
    <>
      <Modal modalActiveStatus={addDomainModalActive} modalClose={modalToggleAddNewDomain}>
        {
          <>
            <div className={'scpc--modal-header'}>
              <div className={'scpc--modal-browserimage'}>
                <div className={'scpc--modal-browser-top-bar'}>
                  <div className={'scpc--modal-browser-address'}>
                    <span>www.yoursite.com</span>
                  </div>
                  <div className={'scpc--modal-browser-top-buttons'}>
                    <span/>
                    <span/>
                  </div>
                </div>
                <div className={'scpc--modal-browser-content'}>
                  <i className={'icon-add-new-domain'}/>
                </div>
              </div>
            </div>
            <div className={'scpc--modal-content'}>
              <h3>
                Add new domain
              </h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor
                incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices
                gravida.
                Risus commodo viverra maecenas accumsan lacus vel facilisis.
              </p>
            </div>
            <div className={'scpc--modal-footer'}>
              <Link to={getRoute(routes.addProject)}
                    className={'scpc--button-flat color-blue button-rounded button-wide'}>
                Go to domain wizard
              </Link>

            </div>
          </>
        }
      </Modal>
      <div className={`scpc--right-nav-backdrop ${(activeFirstDrawer) ? 'active' : ''}`}
           onClick={() => {
             closeDrawers()
           }}>
        <div className="scpc--right-nav-backdrop-additional">&nbsp;</div>
        <div className="scpc--right-nav-backdrop-additional-top">&nbsp;</div>
      </div>
      <aside
        className={`scpc--right-nav ${activeFirstDrawer ? 'active' : ''} ${activeSecondDrawer ? 'active-second' : ''} ${isMobileSafari ? 'isMobileSafari' : ''}`}>
        <div className="scpc--right-nav-header-toggle">
          <button className={`scpc--button-flat-close-second`} onClick={() => {
            // console.log('Close second');
            setActiveSecondDrawer(false);
          }}>
            <i className="fal fa-chevron-left"/>
          </button>
          <button className="scpc--button-flat color-blue" onClick={() => {
            closeDrawers()
          }}>
            <i className="icon-cross-circle"/>
          </button>
        </div>
        <Scrollbar disableTracksWidthCompensation style={{ position: "", 'height': '100vh' }}>
          <div className={`${isMobileSafari ? 'isMobileSafari--scroll-padding' : ''}`}>

            <div className="scpc--right-nav-header">
              <div className="scpc--right-nav-header-user">
                <img
                  src="./images/user.png"
                  alt=""
                  style={{ 'maxWidth': '100px', 'marginTop': '-3px' }}
                />
                <div className="scpc--right-nav-header-user-content">
                  <h3>Hey {auth0Client.getUser().givenName || null}!</h3>
                  <button onClick={() => signOut()}>
                    <i className="linear-lock"/> <span>Logout</span>
                  </button>
                </div>
              </div>
            </div>
            <div className={`scpc--right-nav-mobile-domainselector`}>
              {auth0Client.isAuthenticated() && <>
                <DomainSelector/>
                <Button type="primary" shape="round" icon={<PlusCircleOutlined/>} size={`medium`}
                        block onClick={() => {
                  history.push(getRoute(routes.addProject))
                }}>Add new domain</Button>
                <button
                  className="scpc--header-addnew"
                  onClick={modalToggleAddNewDomain}
                >
                  <i className="icon-circle-plus"/>
                  <span>Add new domain</span>
                </button>
              </>}
            </div>
            <div className="scpc--right-nav-links">
              <div className="scpc--right-nav-links-holder">
                <div className="scpc--right-nav-links-title">
                  <h4>Navigation</h4>
                </div>
                <div className="scpc--right-nav-links-list">
                  {rightNavBoxes.map((box) => {
                    return (
                      <div className="scpc--right-nav-links-boxholder"
                           onClick={() => toggleSecondDrawer(box)} key={box.id}>
                        <RightNavBox
                          box={box}
                          key={box.id}
                          active={box.active}
                        />
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="scpc--right-nav-quicklinks">
              <div className="scpc--right-nav-quicklinks-title">
                <h4>Quick links</h4>
              </div>
              <div className="scpc--right-nav-quicklinks-holder">
                <ul className="scpc--right-nav-quicklinks-list">
                  {quickLinks.map((link) => {
                    return (
                      <li key={link.id}>
                        <Link to={link.link}>
                          {link.name}
                        </Link>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <SavedWithUs/>
          </div>


        </Scrollbar>
        <div className="scpc--right-nav-secondDrawer">
          {renderSecondComponent(activeSecondComponent)}
        </div>
      </aside>
    </>
  );
};