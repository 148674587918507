import React from 'react';
import Scrollbar from "react-scrollbars-custom";
import {ContentBox, ContentBoxHeader} from '../box/ContentBox';
import {Button} from '../../layout/Button';
import {Controller, useForm} from 'react-hook-form';
import HtmlParser from 'react-html-parser';
import ReactFlagsSelect from 'react-flags-select';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import * as UsersApi from '../../../api/users';

import {Select} from 'antd';
import auth0Client from "../../../Auth";

const {Option} = Select;

export const MyAccountInner = (props) => {
  // eslint-disable-next-line no-unused-vars
  const {handleSubmit, register, errors, control, setValue, getValues } = useForm();

  const user = auth0Client.getUser();

  const onSubmit = data => {
    UsersApi.updateUserAccount(data).then(res => {
      auth0Client.setUser(res.user);
    });
  }

  return (
    <>
      <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
        <ContentBoxHeader title={`Billing Details`}/>
        <form onSubmit={handleSubmit(onSubmit)}
              autoComplete={'off'}
              className={`scpc--myaccount-form ${props.standalone  ? 'scpc--myaccount-form-standalone' : ''}`}
        >
          {
            /**
             *
             * FIELDS
             * - Country
             * - Address line 1
             * - Address line 2
             * - Postal code
             * - City
             * - Phone number
             * - Invoice prefix
             * - Next invoice sequence (?)
             * - Tax status
             *    - Taxable
             *    - Exempt
             *    - Reverse Charge
             * - VAT ID NUMBER
             *    - Per country selector
             *    - Per selector mask
             */
          }
          <div className={`scpc--forms-input-holder ${errors.name ? 'has-error' : ''}`}>
            <label>Name</label>
            <input
              type={`text`}
              name={`name`}
              defaultValue={user.name}
              ref={register({
                required: true
              })}
              className={`scpc--forms-input-text input-default input-full`}
            />
            {errors.name && HtmlParser(
              "<small class='scpc--forms-input-error'>This field is required</small>")}
          </div>

          <div className={`scpc--forms-input-holder ${errors.companyName ? 'has-error' : ''}`}>
            <label>Company Name</label>
            <input
              type={`text`}
              name={`companyName`}
              defaultValue={user.companyName}
              ref={register({
                required: false
              })}
              className={`scpc--forms-input-text input-default input-full`}
            />
            {errors.company_name && HtmlParser(
              "<small class='scpc--forms-input-error'>This field is required</small>")}
          </div>

          <div className={`scpc--forms-input-holder`}>
            <label>Country:</label>

            <Controller
              as={
                <ReactFlagsSelect
                  searchable={true}
                  searchPlaceholder="Country"
                  onSelect={(country) => setValue("country", country)}
                />
              }
              control={control}
              // rules={{ required: true }}
              name={`country`}
              defaultCountry={user.country}
              defaultValue={user.country}
            />

          </div>
          <div className={`scpc--forms-input-holder ${errors.address1 ? 'has-error' : ''}`}>
            <label>Address Line 1</label>
            <input
              type={`text`}
              name={`address1`}
              defaultValue={user.address1}
              ref={register({
                required: true
              })}
              className={`scpc--forms-input-text input-default input-full`}
            />
            {errors.address1 && HtmlParser(
              "<small class='scpc--forms-input-error'>This field is required</small>")}
          </div>
          <div className={`scpc--forms-input-holder ${errors.address2 ? 'has-error' : ''}`}>
            <label>Address Line 2</label>
            <input
              type={`text`}
              name={`address2`}
              defaultValue={user.address2}
              ref={register({
                required: false
              })}
              className={`scpc--forms-input-text input-default input-full`}
            />
            {errors.address2 && HtmlParser(
              "<small class='scpc--forms-input-error'>This field is required</small>")}
          </div>
          <div className={`scpc--forms-input-holder ${errors.zip ? 'has-error' : ''}`}>
            <label>Postal Code</label>
            <input
              type={`text`}
              name={`zip`}
              defaultValue={user.zip}
              ref={register({
                required: true
              })}
              className={`scpc--forms-input-text input-default input-full`}
            />
            {errors.zip && HtmlParser(
              "<small class='scpc--forms-input-error'>This field is required</small>")}
          </div>
          <div className={`scpc--forms-input-holder ${errors.city ? 'has-error' : ''}`}>
            <label>City</label>
            <input
              type={`text`}
              name={`city`}
              defaultValue={user.city}
              ref={register({
                required: true
              })}
              className={`scpc--forms-input-text input-default input-full`}
            />
            {errors.city && HtmlParser(
              "<small class='scpc--forms-input-error'>This field is required</small>")}
          </div>
          <div className={`scpc--forms-input-holder ${errors.phone ? 'has-error' : ''}`}>
            <label>Phone number</label>

            <Controller
              as={
                <PhoneInput
                  // value={phoneNumber}
                  // onChange={setPhoneNumber}
                  // enableSearch={true}
                  // defaultValue={user.phone}
                />
              }
              control={control}
              // rules={{ required: true }}
              name={`phone`}
              defaultValue={user.phone}
            />

            {errors.phone && HtmlParser(
              "<small class='scpc--forms-input-error'>This field is required</small>")}
          </div>
          <div className={`scpc--forms-input-holder ${errors.invoicePrefix ? 'has-error' : ''}`}>
            <label>Invoice prefix</label>
            <input
              type={`text`}
              name={`invoice_prefix`}
              defaultValue={user.invoicePrefix}
              ref={register({
                required: false
              })}
              className={`scpc--forms-input-text input-default input-full`}
            />
            {errors.invoicePrefix && HtmlParser(
              "<small class='scpc--forms-input-error'>This field is required</small>")}
          </div>
          <div className={`scpc--forms-input-holder ${errors.taxStatus ? 'has-error' : ''}`}>
            <label>Tax status</label>

            <Controller
              as={
                <Select
                  showSearch
                  style={{width: '100%'}}
                  name={`taxStatus`}
                  placeholder="Tax status"
                  optionFilterProp="children"
                >
                  <Option value={`taxable`}>Taxable</Option>
                  <Option value={`not_taxable`}>Not Taxable</Option>
                </Select>
              }
              control={control}
              // rules={{ required: true }}
              name={`tax_status`}
              defaultValue={user.taxStatus}
            />
          </div>

          <div className={`scpc--forms-input-holder ${errors.taxId ? 'has-error' : ''}`}>
            <label>Tax ID Number</label>
            <input
              type={`text`}
              name={`taxId`}
              defaultValue={user.taxId}
              ref={register({
                required: false
              })}
              className={`scpc--forms-input-text input-default input-full`}
            />
            {errors.taxId && HtmlParser(
              "<small class='scpc--forms-input-error'>This field is required</small>")}
          </div>
          <div className={`scpc--forms-input-holder scpc--forms-input-holder-submit`}>
            <Button
              icon={`linear-checkmark-circle`}
              color={`blue`}
              flat={true}
              rounded={`default`}
              onClick={() => {}}
            >
              Update Billing Details
            </Button>
          </div>
        </form>
      </ContentBox>
    </>
  )
}

export const MyAccount = (props) => {
  return (
    <>
      {props.type !== 'standalone' ? <>
        <Scrollbar disableTracksWidthCompensation style={{position: "", 'height': '100vh', 'padding': '40px'}}>
          <div style={{ 'width': '100%', 'padding': '20px' }}>
            <h1 className={`mt-10 mb-60`}>My Account</h1>
            <MyAccountInner />
          </div>
        </Scrollbar>
      </> : <>
        <MyAccountInner standalone={props.type === 'standalone'}/>
      </>}
    </>
  );
};