import React from 'react';
import {Route} from 'react-router-dom';
import auth0Client from '../../Auth';


function SecuredRoute(props) {
  let {component: Component, path, ...rest} = props;
  return (
    <>
      <Route
        path={path}
        {...rest}
        render={() => {
          document.body.classList.remove('fixed');
          if (!auth0Client.isAuthenticated()) {
            auth0Client.signIn();
            return <div/>;
          }

          return <Component/>
        }}/>
    </>
  );
}

export default SecuredRoute;