import {apiGET, apiPATCH, apiPOST} from './api';
import {CAMPAIGN_GROUP_TYPE_ADVANCED, CAMPAIGN_GROUP_TYPE_DEFAULT, PROJECT_GROUP_MODE_SIMPLE} from "../context/types";
import _ from "lodash";

export const getProjects = async () => {
  return apiGET('/cf/projects');
};

export const getProject = async (projectId) => {
  return apiGET(`/cf/projects/${projectId}`);
};

export const linkAdWordsAccount = async (projectId, clientCustomerId) => {
  return apiPOST(`/cf/projects/${projectId}/adwords-link`, {clientCustomerId: clientCustomerId});
};

export const updateAdWordsAccountLinkStatus = async (projectId) => {
  return apiPATCH(`/cf/projects/${projectId}/adwords-link`, {});
};

/**
 * Update list of AdWords campaign list
 * @param projectId
 * @returns {Promise<* | undefined>}
 */
export const updateAdWordsCampaigns = async (projectId) => {
  return apiPATCH(`/cf/projects/${projectId}/campaigns`);
};

/**
 *
 * @param projectId
 * @returns {Promise<* | undefined>}
 */
export const getEmbedCode = async (projectId) => {
  return apiGET(`/cf/projects/${projectId}/embed-code`);
};

/**
 * Check https://[project-domain] (and http://[project-domain] if first fails) for embed code
 *
 * @param projectId
 * @returns {Promise<* | undefined>} returns {embedCodeVerified: bool}
 */
export const verifyEmbedCodeInstallation = async (projectId) => {
  return apiPATCH(`/cf/projects/${projectId}/embed-code`);
};

/**
 * Get stats for project for given period od time.
 *
 * @param projectId
 * @param fromDate format Y-m-d, if null -oneWeek() will be used
 * @param toDate format Y-m-d, if null today() will be used
 * @returns {Promise<* | undefined>}
 */
export const getStats = async (projectId, fromDate, toDate) => {
  return apiGET(
    `/cf/projects/${projectId}/stats`,
    {
      fromDate,
      toDate
    }
  );
};

export const getVisits = async (projectId, searchParams) => {
  return apiGET(`/cf/projects/${projectId}/visits`, searchParams);
};

export const getVisitorDetails = async (projectId, searchParams) => {
  return apiGET(`/cf/projects/${projectId}/visits/details`, searchParams);
};

export const updateProject = async (project) => {
  let newProject = _.cloneDeep(project);

  if (newProject.groupMode === PROJECT_GROUP_MODE_SIMPLE) {
    // simple group mode, send only default campaign group
    newProject.campaignGroups = _.pickBy(
      project.campaignGroups,
      {type: CAMPAIGN_GROUP_TYPE_DEFAULT}
    );
  } else {
    // advanced group mode, send only advanced campaign groups
    newProject.campaignGroups = _.pickBy(
      project.campaignGroups,
      {type: CAMPAIGN_GROUP_TYPE_ADVANCED}
    );
  }
  return apiPATCH(`/cf/projects/${project.id}`, newProject);
};

export const changeBanStatus = async (projectId, applyBan, banParams) => {
  return apiPATCH(`/cf/projects/${projectId}/bans`, {applyBan, banParams});
};

export const generateReport = async (projectId, params) => {
  return apiPOST(`/cf/projects/${projectId}/reports`, params);
};

export const manuallyBanIp = async (projectId, ip) => {
  return apiPATCH(`/cf/projects/${projectId}/bans`, {
    operation: 'add',
    banParams: {
      ips: [ip]
    }
  });
};

export const manuallyBanFingerprint = async (projectId, fingerprint) => {
  return apiPATCH(`/cf/projects/${projectId}/bans`, {
    operation: 'add',
    banParams: {
      fingerprints: [fingerprint]
    }
  });
};

export const manuallyUnbanIp = async (projectId, ip) => {
  return apiPATCH(`/cf/projects/${projectId}/bans`, {
    operation: 'remove',
    banParams: {
      ips: [ip]
    }
  });
};

export const manuallyUnbanFingerprint = async (projectId, fingerprint) => {
  return apiPATCH(`/cf/projects/${projectId}/bans`, {
    operation: 'remove',
    banParams: {
      fingerprints: [fingerprint]
    }
  });
};
