import React, {useReducer} from 'react';
import ProjectSettingsContext from './ProjectSettingsContext';
import ProjectSettingsReducer from './ProjectSettingsReducer';
import * as ProjectsApi from '../../../api/projects';
import {uuid} from "uuidv4";
import {
  CAMPAIGN_GROUP_TYPE_ADVANCED,
  CAMPAIGN_GROUP_TYPE_DEFAULT,
  PROJECT_GROUP_MODE_SIMPLE,
  UPDATE_PROJECT_SETTINGS_STATE,
  UPDATE_PROJECT_SETTINGS_UI_STATE
} from '../../types';
import _ from "lodash";

const ProjectSettingsState = (props) => {
  const initialState = {
    project: null,
    ui: {
      campaignGroups: {},
    },
    isProjectLoading: true
  };

  const [state, dispatch] = useReducer(ProjectSettingsReducer, initialState);

  const setProject = (project) => {
    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: project});
  };

  const setCampaignGroupName = (campaignGroupId, value) => {
    let newProject = _.cloneDeep(state.project);
    _.set(
      newProject,
      `campaignGroups[${campaignGroupId}].name`,
      value);

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  }

  const deleteClickRule = (campaignGroupId, ruleId) => {
    let newProject = _.cloneDeep(state.project);
    _.unset(newProject, `campaignGroups[${campaignGroupId}].rules.clickRules[${ruleId}]`);

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  };

  const setClickRuleField = (campaignGroupId, clickRuleId, field, value) => {
    let newProject = _.cloneDeep(state.project);
    _.set(
      newProject,
      `campaignGroups[${campaignGroupId}].rules.clickRules[${clickRuleId}].${field}`,
      value);

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  };

  const setPauseRuleField = (campaignGroupId, pauseRuleId, field, value) => {
    let newProject = _.cloneDeep(state.project);
    _.set(
      newProject,
      `campaignGroups[${campaignGroupId}].rules.pauseRules[${pauseRuleId}].${field}`,
      value);

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  };

  const setCampaignGroupRuleField = (campaignGroupId, field, value) => {
    let newProject = _.cloneDeep(state.project);
    _.set(
      newProject,
      `campaignGroups[${campaignGroupId}].rules[${field}]`,
      value);

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  }

  const setCampaignGroupField = (campaignGroupId, field, value) => {
    // console.log(campaignGroupId, field, value);
    let newProject = _.cloneDeep(state.project);
    _.set(
      newProject,
      `campaignGroups[${campaignGroupId}].[${field}]`,
      value);

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  }

  const addCampaignGroupGeotargetingListItem = (campaignGroupId, countryCode) => {
    let newProject = _.cloneDeep(state.project);
    if (!_.isArray(newProject.campaignGroups[campaignGroupId].rules.geotargetingList)) {
      newProject.campaignGroups[campaignGroupId].rules.geotargetingList = [];
    }
    newProject.campaignGroups[campaignGroupId].rules.geotargetingList.push(countryCode);

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  }

  const removeCampaignGroupGeotargetingListItem = (campaignGroupId, countryCode) => {
    let newProject = _.cloneDeep(state.project);
    _.remove(newProject.campaignGroups[campaignGroupId].rules.geotargetingList, (el) => (el === countryCode));

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  }

  const setProjectGroupMode = (groupMode) => {
    let newProject = _.cloneDeep(state.project);
    if (groupMode === CAMPAIGN_GROUP_TYPE_ADVANCED && _.size(newProject.campaignGroups) === 1) {
      // if we turn to expert mode for the first time, create a new advanced group. This adds it to existing project
      // before creating a deep clone and dispatching it with changed groupMode
      const newGroup = getNewGroup();
      newProject.campaignGroups[newGroup.id] = newGroup;
    }
    newProject.groupMode = groupMode;
    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  }

  const setSettingsField = (field, value) => {
    let newProject = _.cloneDeep(state.project);
    newProject.settings[field] = value;

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  }

  const getManuallyBannedIps = () => {
    return state.project.manuallyBannedIps.join("\n");
  }

  const addClickRule = (campaignGroupId) => {
    let newProject = _.cloneDeep(state.project);
    let newRule = {
      id: uuid(),
      maxClicks: 5,
      period: 600
    };

    // make sure the rules list is an object. When it's empty and returned by API setting an index won't work
    // this probably shouldn't even happen as there's always supposed to be at least one rule but it came out
    // during development
    let clickRulesKey = `campaignGroups[${campaignGroupId}].rules.clickRules`;
    if (_.isArray(_.get(newProject, clickRulesKey))) {
      _.set(newProject, clickRulesKey, {});
    }

    // actually add the new rule
    _.set(newProject, `${clickRulesKey}[${newRule.id}]`, newRule);

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  };

  const getDefaultCampaignRules = () => {
    let clickRuleId = uuid();
    let pauseRuleId = uuid();
    return {
      banTime: 600,
      clickRules: {
        [clickRuleId]: {
          id: clickRuleId,
          maxClicks: 5,
          period: 600
        }
      },
      geotargeting: false,
      geotargetingOnlyAllow: true,
      geotargetingList: [],
      pauseUnderAttack: false,
      pauseRules: {
        [pauseRuleId]: {
          id: pauseRuleId,
          maxFrauds: 5,
          period: 600
        }
      },
      pauseTime: 60 * 60
    };
  }

  const getNewGroup = () => {
    return {
      isNew: true,
      id: uuid(),
      name: "Expert mode group",
      type: CAMPAIGN_GROUP_TYPE_ADVANCED,
      rules: getDefaultCampaignRules(),
      campaignInclusionMode: "include_all",
      campaigns: [],
    };
  };

  const addGroup = () => {
    let newProject = _.cloneDeep(state.project);
    // actually add the new rule
    const newGroup = getNewGroup();
    newProject.campaignGroups[newGroup.id] = newGroup;
    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  };

  const deleteGroup = (campaignGroupId) => {
    console.log(campaignGroupId);
    let newProject = _.cloneDeep(state.project);

    // remove campaigns from group
    newProject.campaigns = _.forEach(newProject.campaigns, (campaign, key) => {
      campaign.campaignGroups = _.filter(
        campaign.campaignGroups,
        (v) => (v !== campaignGroupId)
      );
    });

    _.unset(newProject, `campaignGroups[${campaignGroupId}]`);
    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  };

  /**
   * Get campaign groups for current project mode
   * @returns {Object[]}
   */
  const getGroups = () => {
    if (state.project) {
      if (state.project.groupMode === PROJECT_GROUP_MODE_SIMPLE) {
        return _.filter(state.project.campaignGroups, {type: CAMPAIGN_GROUP_TYPE_DEFAULT});
      } else {
        return _.filter(state.project.campaignGroups, {type: CAMPAIGN_GROUP_TYPE_ADVANCED});
      }
    }
  }

  const setManuallyBannedIps = (manuallyBannedIps) => {
    let newProject = _.cloneDeep(state.project);
    newProject.manuallyBannedIps = manuallyBannedIps.split(/[\n, ]+/);

    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  };

  const getGroupCampaigns = (campaignGroupId) => {
    if (!state.project) {
      return;
    }

    return _.filter(state.project.campaigns, (campaign) => {
      return isCampaignInGroup(campaignGroupId, campaign.id);
    });
  };

  const isCampaignInGroup = (campaignGroupId, campaignId) => {
    return state.project.campaignGroups[campaignGroupId].campaigns.includes(campaignId);
  };

  const addCampaignToGroup = (campaignGroupId, campaignId) => {
    console.log(campaignGroupId, campaignId);

    if (!isCampaignInGroup(campaignGroupId, campaignId)) {
      let newProject = _.cloneDeep(state.project);
      // update campaign group list of included campaigns
      newProject.campaignGroups[campaignGroupId].campaigns.push(campaignId);
      // update symmetric list of campaign groups (actually, it's zero or one group) for the campaign
      newProject.campaigns[campaignId].campaignGroups.push(campaignGroupId);
      dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
    }
  };

  const removeCampaignFromGroup = (campaignGroupId, campaignId) => {
    let newProject = _.cloneDeep(state.project);
    // update campaign group list of included campaigns
    newProject.campaignGroups[campaignGroupId].campaigns = _.filter(
      newProject.campaignGroups[campaignGroupId].campaigns,
      (v) => (v !== campaignId)
    );

    // update symmetric list of campaign groups (actually, it's zero or one group) for the campaign
    newProject.campaigns[campaignId].campaignGroups = _.filter(
      newProject.campaigns[campaignId].campaignGroups,
      (v) => (v !== campaignGroupId)
    );
    dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
  };

  const setUiData = (key, value) => {
    let newUi = _.cloneDeep(state.ui);
    _.set(newUi, key, value);
    dispatch({type: UPDATE_PROJECT_SETTINGS_UI_STATE, payload: newUi});
  };

  const getUiData = (key, defaultValue) => {
    return _.get(state.ui, key, defaultValue);
  }

  const getCampaignsNotInAnyGroup = () => {
    if (!state.project) {
      return [];
    }

    return _.filter(
      state.project.campaigns,
      (campaign) => !campaign.campaignGroups.length
    );
  }

  const getCampaigns = () => {
    return state.project.campaigns;
  }

  const updateProject = () => {
    return ProjectsApi
      .updateProject(state.project)
      .then((newProjectDetails) => {
        setProject(newProjectDetails);
        return newProjectDetails;
      });
  }

  const refreshCampaignList = () => {
    return ProjectsApi.updateAdWordsCampaigns(state.project.id).then((result) => {
      if (result.campaignsChanged) {
        let newCampaignList = {};
        // adWords campaigns changes, we need to update the project state
        _.each(result.campaigns, (campaign) => {
          if (state.project.campaigns[campaign.id]) {
            // campaign existed already, use the campaign from *state*, not the one
            // that just arrived to preserve potentially changed campaignGroups.
            // This way we use can do some changes, change campaign name in Adwords
            // hit refresh campaigns before saving project settings and get the updated name
            // without losing other changes.
            newCampaignList[campaign.id] = state.project.campaigns[campaign.id];
            newCampaignList[campaign.id].name = campaign.name;
          } else {
            // a new campaign, add it to list
            newCampaignList[campaign.id] = campaign;
          }
        });

        let newCampaignsIds = _.keys(newCampaignList).map(campaignId => parseInt(campaignId));
        let oldCampaignIds = _.keys(state.project.campaigns).map(campaignId => parseInt(campaignId));

        // find ids of campaigns that were removed, i.e. existed in state but weren't returned
        // by the API call
        let removedCampaignIds = _.filter(
          oldCampaignIds,
          (campaignId) => (!_.includes(newCampaignsIds, campaignId))
        );

        // now we need to do several updates at once to the project state
        let newProject = _.cloneDeep(state.project);
        newProject.campaigns = newCampaignList;

        // console.log("removed campaigns", removedCampaignIds);
        // delete references to removed campaigns from campaignGroups
        _.each(newProject.campaignGroups, (campaignGroup, campaignGroupId) => {
          newProject.campaignGroups[campaignGroupId].campaigns = _.filter(
            campaignGroup.campaigns,
            (campaignId) => (!_.includes(removedCampaignIds, campaignId))
          );
        });

        dispatch({type: UPDATE_PROJECT_SETTINGS_STATE, payload: newProject});
      }

      return result;
    });
  }

  return <ProjectSettingsContext.Provider
    value={{
      project: state.project,
      isProjectLoading: state.isProjectLoading,
      setProject,
      getGroups,
      getCampaigns,
      getCampaignsNotInAnyGroup,
      getGroupCampaigns,
      isCampaignInGroup,
      addCampaignToGroup,
      removeCampaignFromGroup,
      deleteClickRule,
      setClickRuleField,
      setPauseRuleField,
      addClickRule,
      addGroup,
      deleteGroup,
      setProjectGroupMode,
      setManuallyBannedIps,
      setUiData,
      getUiData,
      updateProject,
      setCampaignGroupName,
      setSettingsField,
      setCampaignGroupRuleField,
      setCampaignGroupField,
      addCampaignGroupGeotargetingListItem,
      removeCampaignGroupGeotargetingListItem,
      refreshCampaignList,
      getManuallyBannedIps
    }}
  >
    {props.children}
  </ProjectSettingsContext.Provider>
};

export default ProjectSettingsState;
