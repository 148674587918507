import React, {useContext, useEffect, useState} from 'react';
import ProjectContext from "../../../context/CF/ProjectContext/ProjectContext";
import {DomainSelectorItem} from './DomainSelectorItem';
import {useHistory, useParams} from "react-router-dom";
import {useComponentVisible} from '../../../hooks/useComponentVisible';
import Scrollbar from "react-scrollbars-custom";
import {Spin} from 'antd';

export const DomainSelector = (props) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);
  const projectContext = useContext(ProjectContext);

  const history = useHistory();
  // eslint-disable-next-line no-unused-vars
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [activeProjectDomain, setActiveProjectDomain] = useState('Select project...');

  // eslint-disable-next-line no-unused-vars
  const setActiveProject = (projectId, projectName) => {
    setIsDropdownActive(false);
    projectContext.loadProject(projectId);
    history.push(`/projects/${projectId}`);
  };

  let {projectId} = useParams();
  // eslint-disable-next-line no-unused-vars
  const setProjectIfNone = (project) => {
    // console.log('setProjectIfNone');
    // console.log(projectContext);
    if (projectContext.project === null) {
      if (project.id === projectId) {
        // console.log(project);
        setActiveProject(project.id, project.name);
      }
    }
  }

  useEffect(() => {
    // trigger load list of projects when displaying for the first time
    projectContext.loadProjects().then(() => {
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // TODO: verify, cleanup
  useEffect(() => {
    // initialize selected project after first page load
    if (projectId || projectContext.projectId) {
      if (!projectContext.isProjectLoading
        && projectContext.project
        && activeProjectDomain !== projectContext.project.domain) {
        setActiveProjectDomain(projectContext.project.domain);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectContext.projects, projectContext.project])

  return (
    <ProjectContext.Consumer>
      {({isProjectsLoading}) => (
        <>
          {isProjectsLoading ? <>
            <Spin/>
          </> : <>

            <div className="scpc--header-domainselect">
              <div className="scpc--forms-select-holder">
                <div className="scpc--forms-select-input rounded-default has-icon has-dropdown">
                  <i className="icon-web"/>
                  <span className="scpc--forms-select-input-placeholder rouned-default">
                                        {activeProjectDomain}
                                    </span>
                  <div
                    className={`scpc--forms-select-input-dropdownbutton ${isComponentVisible ? 'inactive' : ''} ${projectContext.projects.length > 1 ? 'havelength' : 'nolength'}`}
                    onClick={() => setIsComponentVisible(true)}>
                    <i className="icon-chevron-down"/>
                  </div>
                  <div
                    className={`scpc--forms-select-input-dropdown ${isComponentVisible ? 'active' : ''}`}
                    ref={ref}>
                    <ul>

                        <div style={{'width': '100%', 'padding': '10px'}}>
                          <li className="activeElem"
                              onClick={() => setIsComponentVisible(false)}>
                            <div className="scpc--forms-select-input-elem-wrapper">
                              <div className="scpc--forms-select-input-dropdown-elem">
                                <i className="icon-web"/>
                                <span>{activeProjectDomain}</span>
                              </div>
                              <div className={`scpc--forms-select-input-dropdown-actions`}

                              >
                                <button className="scpc--forms-select-toggleDropdown"
                                        onClick={() => setIsComponentVisible(false)}>
                                  <i className="linear-chevron-up"/>
                                </button>
                              </div>
                            </div>
                          </li>
                          {/*{*/}
                          {/*  (!projectContext.loading && projectContext.project) ? setActiveProjectDomain(*/}
                          {/*    projectContext.project.domain) : ''*/}
                          {/*}*/}
                          {

                            (!projectContext.loading && projectContext.project) ?
                            projectContext.projects
                              .filter(project => project.domain !== projectContext.project.domain)
                              .map((project) => {

                                if(project.status !== 'setup'){
                                  return (
                                    <DomainSelectorItem project={project} key={project.id}
                                                        setActiveProject={setActiveProject} status={project.status}/>
                                  )
                                }
                              }) : projectContext.projects.map((project) => {
                                if(project.status !== 'setup'){
                                  return (
                                    <DomainSelectorItem project={project} key={project.id}
                                                        setActiveProject={setActiveProject} status={project.status}/>
                                  )
                                }
                            })
                          }
                        </div>

                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </>
          }
        </>
      )}
    </ProjectContext.Consumer>
  );
};