import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {Table} from 'antd';
import { Link, useParams } from "react-router-dom";
import {Calendar} from '../Calendar/Calendar';
import {RightContent} from "../appLayout/RightContent";
import {ContentBox, ContentBoxHeader} from "../box/ContentBox";
import {AnalyticsLeft} from './AnalyticsLeft';
import {BanReason} from "./Graphs/BanReason";
import {isMobile} from "react-device-detect";


import ProjectContext from "../../../context/CF/ProjectContext/ProjectContext";
import * as ProjectApi from "../../../api/projects";
import 'antd/dist/antd.css';
import ReactApexChart from "react-apexcharts";
import _ from "lodash";
import routes, { getRoute } from '../../../routes/routes';
// eslint-disable-next-line no-unused-vars
const {Column, ColumnGroup} = Table;

export const AnalyticsDevices = (props) => {
  const [calendarStartDate, setStartDate] = useState(moment().subtract(1, 'week'));
  const [calendarEndDate, setEndDate] = useState(moment());
  const {projectId} = useParams();

  const defaultSearchParams = {
    fromDate: calendarStartDate.format('YYYY-MM-DD').toString(),
    endDate: calendarEndDate.format('YYYY-MM-DD').toString(),
    mode: 'devices',
    page: 1,
    pageSize: 20,
    ip: null,
    fingerprint: null,
    gclid: null,
    country: null,
    deviceId: null,
    city: null,
    adPlacement: null,
    browserAgent: null,
    url: null,
    isp: null,
  };

  const [visits, setVisits] = useState({isLoading: true, params: {}, data: []});
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const projectContext = useContext(ProjectContext);

  useEffect(() => {
    projectContext.loadProject(projectId).then((project) => {
      search();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * usage: search({ stuff you want to set, ...searchParams}. After API results searchParams will be updated
   * with new fields if provided etc.
   **/
  const search = (params) => {
    setVisits({...visits, isLoading: true});
    ProjectApi.getVisits(projectId, {...searchParams, ...params})
      .then(results => {
        setSearchParams({
          ...results.params,
          ...params
        });
        setVisits({...results, isLoading: false});

        let newMostFraudulentDevices = _.cloneDeep(mostFraudulentDevices);
        newMostFraudulentDevices.series[0].data = results.charts.mostFraudulentDevices.series[0].data;
        newMostFraudulentDevices.options.xaxis = results.charts.mostFraudulentDevices.options.xaxis;
        setMostFraudulentDevices(newMostFraudulentDevices);
      });
  }

  const pagination = {
    total: visits.total || 0,
    defaultPageSize: visits.params.pageSize || defaultSearchParams.pageSize,
    page: visits.params.page,
    onChange: (page, pageSize) => {
      search({
        ...searchParams,
        page,
        pageSize
      });
    }
  };

  const columns = [
    {
      title: "device ID",
      dataIndex: "fingerprint",
      render: (fingerprint) => {
        return (
          <Link to={getRoute(routes.analyticsDetailsByFingerprint,
            { projectId, fingerprint })}>{fingerprint}</Link>
        )
      }
    },
    {
      title: "Click Count",
      dataIndex: "click_count"
    },
    {
      title: "Fraud Count",
      dataIndex: "fraud_count",
    },
    {
      title: "Last Visit",
      dataIndex: "last_visit",
    },
    {
      title: "IPs",
      dataIndex: "ips",
      render: (value) => {
        let ips = value.replace(/,/g, "<br/>");
        return <span dangerouslySetInnerHTML={{__html: ips}}/>
      }
    },
  ];

  const [mostFraudulentDevices, setMostFraudulentDevices] = useState({
    series: [{
      data: []
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
      }
    }
  });

  const onCalendarSave = () => {
    let startDate = moment(calendarStartDate).format('YYYY-MM-DD').toString();
    let endDate = moment(calendarEndDate).format('YYYY-MM-DD').toString();
    search({fromDate: startDate, toDate: endDate, page: 1});
  }

  return (
    <ProjectContext.Consumer>
      {({isProjectLoading}) => (
        <>
          <AnalyticsLeft load={props.load}/>
          {isProjectLoading ? <div></div> :
            <>
              <RightContent>
                <div className={`container-fluid chartboxes-header`}>
                  <div className={`row`}>
                    <div className={`col-6`}>
                      <h3>Devices analytics</h3>
                    </div>
                    <div className={`col-3 chartboxes-calendar`}>
                      <h4>
                        <i className={`linear-calendar-31`}/>
                        Pick a date range
                      </h4>
                    </div>
                    <div className={`col-3 chartboxes-calendar`}>
                      <Calendar
                        startDate={calendarStartDate}
                        endDate={calendarEndDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        onCalendarSave={onCalendarSave}
                      />
                    </div>
                  </div>

                </div>
                <div className={`container-fluid`}>
                  <div className={`row`}>
                    <div
                      className={`col-lg-8 col-md-12 col-sm-12 col-xs-12 pl-0 analytics-chart-col`}>
                      <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                        <ContentBoxHeader title={`Most fraudulent devices`}/>
                        <ReactApexChart options={mostFraudulentDevices.options}
                                        series={mostFraudulentDevices.series} type="bar"
                                        height={350}/>
                      </ContentBox>
                    </div>
                    <div
                      className={`col-lg-4 col-md-12 col-sm-12 col-xs-12 pr-0 analytics-chart-col`}>
                      <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                        <ContentBoxHeader title={`Ban by reason`}/>
                        {!visits.isLoading &&
                        <BanReason
                          labels={visits.charts.banReason.labels}
                          series={visits.charts.banReason.series}
                          isMobile={isMobile}
                        />
                        }
                        {/*<ReactApexChart options={byBanReasonMobile.options}*/}
                        {/*                series={byBanReasonMobile.series} type="bar"*/}
                        {/*                className={`hide-desktop`}/>*/}
                      </ContentBox>
                    </div>
                  </div>
                </div>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>

                  <ContentBoxHeader title={`Page clicks data`}/>
                  <Table
                    dataSource={visits.data}
                    rowKey={'fingerprint'}
                    // expandable={expandable}
                    pagination={pagination}
                    size="medium"
                    columns={columns}
                    scroll={{x: true}}
                    loading={visits.isLoading}
                  >
                  </Table>
                </ContentBox>
              </RightContent>
            </>
          }
        </>
      )}
    </ProjectContext.Consumer>
  );
};
