import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {Table} from 'antd';
import {useParams} from "react-router-dom";
import {Calendar} from '../Calendar/Calendar';
import {RightContent} from "../appLayout/RightContent";
import {ContentBox, ContentBoxHeader} from "../box/ContentBox";
import {AnalyticsLeft} from './AnalyticsLeft';

import ProjectContext from "../../../context/CF/ProjectContext/ProjectContext";
import * as ProjectApi from "../../../api/projects";
import 'antd/dist/antd.css';
import ReactApexChart from "react-apexcharts";
import _ from "lodash";
import {BanReason} from "./Graphs/BanReason";
import {isMobile} from "react-device-detect";
// eslint-disable-next-line no-unused-vars
const {Column, ColumnGroup} = Table;

export const AnalyticsFraudCountry = (props) => {
  const [calendarStartDate, setStartDate] = useState(moment().subtract(1, 'week'));
  const [calendarEndDate, setEndDate] = useState(moment());
  const {projectId} = useParams();

  const defaultSearchParams = {
    fromDate: calendarStartDate.format('YYYY-MM-DD').toString(),
    endDate: calendarEndDate.format('YYYY-MM-DD').toString(),
    mode: 'countries',
    page: 1,
    pageSize: 20,
    ip: null,
    fingerprint: null,
    gclid: null,
    country: null,
    deviceId: null,
    city: null,
    adPlacement: null,
    browserAgent: null,
    url: null,
    isp: null,
  };
  const [visits, setVisits] = useState({isLoading: true, params: {}, data: []});
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const projectContext = useContext(ProjectContext);
  useEffect(() => {
    projectContext.loadProject(projectId).then((project) => {
      search();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const pagination = {
    total: visits.total || 0,
    defaultPageSize: visits.params.pageSize || defaultSearchParams.pageSize,
    page: visits.params.page,
    onChange: (page, pageSize) => {
      search({
        ...searchParams,
        page,
        pageSize
      });
    }
  };

  const columns = [
    {
      title: "Country",
      dataIndex: "country",
      render : (country, data) => {
        // console.log(country);
        // console.log(data);
        return data.country ? data.country : data.country_code;
      }
    },
    {
      title: "Click Count",
      dataIndex: "click_count"
    },
    {
      title: "Fraud Count",
      dataIndex: "fraud_count",
    },
    {
      title: "Last Visit",
      dataIndex: "last_visit",
    },
  ];

  /**
   * usage: search({ stuff you want to set, ...searchParams}. After API results searchParams will be updated
   * with new fields if provided etc.
   **/
  const search = (params) => {
    setVisits({...visits, isLoading: true});
    ProjectApi.getVisits(projectId, {...searchParams, ...params})
      .then(results => {
        setSearchParams({
          ...results.params,
          ...params
        });
        setVisits({...results, isLoading: false});

        let newMostFraudulentCountries = _.cloneDeep(mostFraudulentCountries);
        newMostFraudulentCountries.series[0].data = results.charts.mostFraudulentCountries.series[0].data;
        newMostFraudulentCountries.options.xaxis = results.charts.mostFraudulentCountries.options.xaxis;

        setMostFraudulentCountries(newMostFraudulentCountries);
      });
  }

  const [mostFraudulentCountries, setMostFraudulentCountries] = useState({
    series: [{
      data: []
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
      }
    }
  });

  const onCalendarSave = () => {
    let startDate = moment(calendarStartDate).format('YYYY-MM-DD').toString();
    let endDate = moment(calendarEndDate).format('YYYY-MM-DD').toString();
    search({fromDate: startDate, toDate: endDate, page: 1});
  }

  return (
    <ProjectContext.Consumer>
      {({isProjectLoading}) => (
        <>
          <AnalyticsLeft load={props.load}/>
          {isProjectLoading ? <div></div> :
            <>
              <RightContent>
                <div className={`container-fluid chartboxes-header pl-10 pr-10`}>
                  <div className={`row`}>
                    <div className={`col-6`}>
                      <h3>Fraud by country</h3>
                    </div>
                    <div className={`col-3 chartboxes-calendar`}>
                      <h4>
                        <i className={`linear-calendar-31`}/>
                        Pick a date range
                      </h4>
                    </div>
                    <div className={`col-3 chartboxes-calendar`}>
                      <Calendar
                        startDate={calendarStartDate}
                        endDate={calendarEndDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        onCalendarSave={onCalendarSave}
                      />
                    </div>
                  </div>
                </div>
                <div className={`container-fluid`}>
                  <div className={`row`}>
                    <div
                      className={`col-lg-8 col-md-12 col-sm-12 col-xs-12 pl-0 analytics-chart-col`}>
                      <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                        <ContentBoxHeader title={`Most fraudulent countries`}/>
                        <ReactApexChart options={mostFraudulentCountries.options}
                                        series={mostFraudulentCountries.series} type="bar"
                                        height={350}/>
                      </ContentBox>
                    </div>
                    <div
                      className={`col-lg-4 col-md-12 col-sm-12 col-xs-12 pr-0 analytics-chart-col`}>
                      <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                        <ContentBoxHeader title={`Ban by reason`}/>
                        {!visits.isLoading &&
                        <BanReason
                          labels={visits.charts.banReason.labels}
                          series={visits.charts.banReason.series}
                          isMobile={isMobile}
                        />
                        }
                      </ContentBox>
                    </div>
                    {/*<div className={`col-lg-12 pl-0 pr-0`}>*/}
                    {/*  <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>*/}
                    {/*    <ContentBoxHeader title={`Fraud by country`}/>*/}
                    {/*  </ContentBox>*/}
                    {/*</div>*/}
                  </div>


                </div>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>

                  <ContentBoxHeader title={`Fraud by country`}/>
                  {console.log(visits.data)}
                  <Table
                    dataSource={visits.data}
                    rowKey={'fingerprint'}
                    // expandable={expandable}
                    pagination={pagination}
                    size="medium"
                    columns={columns}
                    scroll={{x: true}}
                    loading={visits.isLoading}
                  >
                  </Table>
                </ContentBox>

              </RightContent>
            </>
          }
        </>
      )}
    </ProjectContext.Consumer>
  );
}