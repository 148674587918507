import React, {useState} from 'react';

export const Button = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [buttonStyle, setButtonStyle] = useState([]);

  const border = () => props.border ? ' scpc--button-border' : '';
  const flat = () => props.flat ? ' scpc--button-flat' : '';
  const textAlign = () => props.textAlign ? `scpc--button-text-${props.textAlign}` : '';
  const color = () => props.color ? `color-${props.color}` : '';
  const hasIcon = () => props.icon ? `has-icon` : '';
  // const iconOptions = () => {
  //   if (props.iconOptions) {
  //     setButtonStyle([
  //       ...buttonStyle,
  //       props.iconOptions
  //     ])
  //   }
  // }
  const width = () => {
    if (props.width === 'auto') {
      return 'auto-width';
    }
    if (props.width === 'full') {
      return 'full-width';
    }
  }
  const rounded = () => {
    if (props.rounded === 'default') {
      return 'button-rounded-default';
    }
    if (props.rounded === 'max') {
      return 'button-rounded';
    }
    return '';
  };
  const icon = () => {
    if (props.icon) {
      return (
        <>
          <div className={`icon-holder`}>
            <i className={props.icon}/>
          </div>
        </>
      );
    }
  };
  const size = () => {
    switch (props.size) {
      case 'small':
        return 'scpc--button-size-small';
      case 'medium':
        return 'scpc--button-size-medium';
      case 'large':
        return 'scpc--button-size-large';
      case 'xxl':
        return 'scpc--button-size-xxl';
      default:
        return 'scpc--button-size-default';
    }
  }
  const inline = () => {
    return props.inline ? 'scpc--button-inline' : '';
  }
  return (
    <>
      <button
        className={`scpc--button component-button ${border()} ${textAlign()} ${color()} ${flat()} ${rounded()} ${hasIcon()} ${width()} ${size()} ${inline()}`}
        onClick={props.onClick ? props.onClick : () => {
        }}
        style={{buttonStyle}}
      >
        {icon()}
        {props.children}
      </button>
    </>
  );
};