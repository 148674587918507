import ReactApexChart from "react-apexcharts";
import React from "react";

export const SparkSummaryGraphs = (props) => {

  const {isLoading, isMobileSafari, graphData} = props;

  const clickGraphOptions = {

    chart: {
      id: 'spark2',
      group: 'sparks',
      type: isMobileSafari ? 'bar' : 'line',
      height: 80,
      foreColor: '#ccc',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 2,
        opacity: 0.2,
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    grid: {
      padding: {
        top: 20,
        bottom: 10,
        left: 110
      },
      borderColor: "#535A6C",
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        labels: {
          minWidth: 40
        }
      }
    },
    xaxis: isMobileSafari ? {
      crosshairs: {
        width: 1
      },
    } : {},
    yaxis: {
      labels: {
        minWidth: 40
      }
    },
    markers: {
      size: 0
    },
    colors: ['#fff'],
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function formatter(val) {
            return '';
          }
        }
      }
    }

  };

  const FraudGraphOptions = {
    chart: {
      id: 'spark2',
      group: 'sparks',
      type: isMobileSafari ? 'bar' : 'line',
      height: 80,
      foreColor: '#ccc',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 2,
        opacity: 0.2,
      }
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    grid: {
      padding: {
        top: 20,
        bottom: 10,
        left: 110
      },
      borderColor: "#535A6C",
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        labels: {
          minWidth: 40
        }
      }
    },
    markers: {
      size: 0
    },
    xaxis: isMobileSafari ? {
      crosshairs: {
        width: 1
      },
    } : {},
    yaxis: {
      labels: {
        minWidth: 40
      }
    },
    colors: ['#fff'],
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function formatter(val) {
            return '';
          }
        }
      }
    }
  };

  const vpnGraphOptions = {
    chart: {
      id: 'spark2',
      group: 'sparks',
      type: isMobileSafari ? 'bar' : 'line',
      height: 80,
      foreColor: '#ccc',
      toolbar: {
        show: false
      },
      sparkline: {
        enabled: true
      },
      dropShadow: {
        enabled: true,
        top: 1,
        left: 1,
        blur: 2,
        opacity: 0.2,
      }
    },
    plotOptions: isMobileSafari ? {
      bar: {
        columnWidth: '80%'
      }
    } : {},
    dataLabels: {
      enabled: false
    },
    stroke: {
      curve: 'smooth',
      width: 3
    },
    grid: {
      padding: {
        top: 20,
        bottom: 10,
        left: 110
      },
      borderColor: "#535A6C",
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        labels: {
          minWidth: 40
        }
      }
    },
    markers: {
      size: 0
    },
    xaxis: isMobileSafari ? {
      crosshairs: {
        width: 1
      },
    } : {},
    yaxis: {
      labels: {
        minWidth: 40
      }
    },
    colors: ['#fff', '#FFF'],
    tooltip: {
      theme: 'dark',
      x: {
        show: false
      },
      y: {
        title: {
          formatter: function formatter(val) {
            return '';
          }
        }
      }
    }
  };

  return (
    <>
      <div className={`col-3`}>
        {!isLoading &&

        <div className={`chartbox box-2`}>
          <div className={`chartbox--details`}>
            <h4>{graphData.totalClicks}</h4>
            <p>Clicks</p>
          </div>
          <ReactApexChart
            options={clickGraphOptions}
            series={[{data: graphData.clicks}]}
            type={isMobileSafari ? `bar` : `line`}
            height={80}
          />
        </div>
        }
      </div>
      <div className={`col-3`}>
        {!isLoading &&
        <div className={`chartbox box-3`}>

          <div className={`chartbox--details`}>
            <h4>{graphData.totalFrauds}</h4>
            <p>Frauds</p>
          </div>
          <ReactApexChart
            options={FraudGraphOptions}
            series={[{data: graphData.frauds}]}
            type={isMobileSafari ? `bar` : `line`}
            height={80}
          />
        </div>
        }
      </div>
      <div className={`col-3`}>
        {!isLoading &&
        <div className={`chartbox box-4`}>
          <div className={`chartbox--details`}>
            <h4>{graphData.totalVpns}</h4>
            <p>VPNs detected</p>
          </div>
          <ReactApexChart
            options={vpnGraphOptions}
            series={[{data: graphData.vpns}]}
            type={isMobileSafari ? `bar` : `line`}
            height={80}
          />
        </div>
        }
      </div>
    </>
  )
};