import React from 'react';
import {LeftMainBoxes} from '../appLayout/LeftMainBoxes';
import {uuid} from 'uuidv4';
// import auth0Client from '../../../Auth';
import {useParams} from "react-router-dom";

export const ReportsLeft = (props) => {
  // let user = auth0Client.getUser();
  let {projectId} = useParams();
  const leftNavBoxes = [
    {
      box: {
        'id': uuid(),
        'icon': 'linear-mouse-left',
        'name': 'Click Fraud Analytics',
        'link': `/reports/${projectId}/click-fraud`,
        'active': props.load === 'click-fraud',
        'size' : 'full'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-desktop',
        'name': 'Devices analytics',
        'link': `/reports/${projectId}/device-analytics`,
        'active': props.load === 'devices-analytics'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-radar',
        'name': 'IP Analytics',
        'link': `/reports/${projectId}/ip-analytics`,
        'active': props.load === 'ip-analytics'
      }
    },
    // {
    //   box: {
    //     'id': uuid(),
    //     'icon': 'linear-radar',
    //     'name': 'IP Range Analytics',
    //     'link': `/reports/${projectId}/ip-range-analytics`,
    //     'active': props.load === 'ip-groups'
    //   }
    // },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-location',
        'name': 'Fraud by country analytics',
        'link': `/reports/${projectId}/fraud-country-analytics`,
        'active': props.load === 'fraud-country'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-feather',
        'name': 'Keyword fraud analytics',
        'link': `/reports/${projectId}/keywords-analytics`,
        'active': props.load === 'keywords'
      }
    },
    // {
    //   box: {
    //     'id': uuid(),
    //     'icon': 'linear-battery-low-3',
    //     'name': 'AdGroup level analytics',
    //     'link': `/reports/${projectId}/adgroup-analytics`,
    //     'active': props.load === 'ad-group'
    //   }
    // },
    // {
    //   box: {
    //     'id': uuid(),
    //     'icon': 'linear-battery-full',
    //     'name': 'Campaign level analytics',
    //     'link': `/reports/${projectId}/campaign-analytics`,
    //     'active': props.load === 'campaign'
    //   }
    // }
  ];
  return (
    <>
      {props.defaultLoaded ? console.log('default'):''}
      <LeftMainBoxes cols={2} boxTextCenter={true} boxesSize={'full'} boxes={leftNavBoxes}>
        <div className={`mt-20`}>
          <div className={`scpc--reports-baner`}>
            <img src={`/svg/reports2.svg`} alt={`Reports`}/>
            <h4>Reports center</h4>
            <p>
              Here you have access to the full analytics of your account. You can browse devices,
              IPs and many more
            </p>
          </div>

          {/*<ReactApexChart options={donutChart.options} series={donutChart.series} type="area" height={160} />*/}
        </div>
      </LeftMainBoxes>
    </>
  )
}