import React, {useContext, useEffect, useState} from 'react';
import SetDomain from "./SetDomain";
import LinkAdWords from "./LinkAdWords";
import ApproveAdWordsLink from "./ApproveAdWordsLink"
import InstallTrackingCode from "./InstallTrackingCode"
import {useHistory, useParams} from "react-router-dom";
import routes, {getRoute} from "../../../routes/routes";
import ProjectContext from "../../../context/CF/ProjectContext/ProjectContext";
import auth0Client from "../../../Auth";
import _ from "lodash";

const AddNewProject = (props) => {

  let history = useHistory();
  let {projectId, stepId} = useParams();

  /**
   * Get steps object with `enabled` flags set for given step + all ealier ones.
   * Used to enable navigation steps in form as user progresses through the wizard
   *
   * @param steps
   * @param newStepId
   * @returns {*}
   */
  const getStepsWithEnabledFlags = (steps, newStepId) => {
    switch (newStepId) {
      case "linkAdWords":
        steps.linkAdWords.enabled = true;
        break;

      case "approveLink":
        steps.linkAdWords.enabled = true;
        steps.approveLink.enabled = true;
        break;

      case "installTracking":
        steps.linkAdWords.enabled = true;
        steps.approveLink.enabled = true;
        steps.installTracking.enabled = true;
        break;

      default:
    }
    return steps;
  }

  // flags on submit in LinkAdWords
  const steps = {
    setDomain: {nextStep: "linkAdWords", prevStep: null, enabled: true},
    linkAdWords: {nextStep: "approveLink", prevStep: "setDomain", enabled: false},
    approveLink: {nextStep: "installTracking", prevStep: "linkAdWords", enabled: false},
    installTracking: {nextStep: null, prevStep: "approveLink", enabled: false}
  };

  let initialState = {
    stepId: 'setDomain',
    clientCustomerId: null,
    cfProject: null,
    subscription: null,
    isLoading: false
  };

  if (projectId !== undefined) {
    // continue setup of a partially created project
    initialState.isLoading = true;

    if (stepId !== undefined) {
      initialState.stepId = stepId;
    } else {
      // this can be updated immediately after project is loaded.
      // it won't be visible until isLoading if false so user won't see step blinking into another
      initialState.stepId = 'linkAdWords';
    }
  }
  // update enabled flags in case use continues to fill a form started earlier. In this case we start not from
  // the first step and need to enable previous ones.
  initialState.steps = getStepsWithEnabledFlags(steps, initialState.stepId);

  const [state, setState] = useState(initialState);

  useEffect(() => {
    if (projectId) {
      projectContext.loadProject(projectId).then((cfProject) => {
        let newState = {
          ...state,
          cfProject,
          isLoading: false
        };

        if (cfProject.hasLinkedAdwordsAccount) {
          newState.stepId = 'installTracking';
        } else {
          // adWords not connected yet
          if (cfProject.clientCustomerId) {
            // adwords client id already entered
            newState.stepId = 'approveLink';
            newState.clientCustomerId = cfProject.clientCustomerId;
          } else {
            newState.stepId = 'linkAdWords';
          }
        }

        newState.steps = getStepsWithEnabledFlags(_.cloneDeep(state.steps), newState.stepId);
        setState(newState);
      });
    }
    // eslint-disable-next-line no-unused-vars
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projectContext = useContext(ProjectContext);
  const nextStep = (newState) => {
    if (state.steps[state.stepId].nextStep === null) {
      // end of process, redirect to project dashboard
      // TODO: this is a hack, maybe it would be a good idea to reload object entirely?

      auth0Client.getUser().meta.firstLogin = null;

      projectContext.loadProject(state.cfProject.id).then(() => {
        history.push(getRoute(routes.project, {projectId: state.cfProject.id}));
      });
      return;
    }
    newState = {...state, ...newState};
    newState.stepId = state.steps[state.stepId].nextStep;
    newState.steps = getStepsWithEnabledFlags(_.cloneDeep(state.steps), newState.stepId);

   // console.log(newState);
    setState(newState);
  };

  const prevStep = () => {
    setState({
      ...state,
      stepId: state.steps[state.stepId].prevStep
    });
  };

  const goToStep = (stepId) => {
    if (stepId !== state.stepId) {
      if (isStepEnabled(stepId)) {
        setState({
          ...state,
          stepId: stepId
        });
      }
    }
  };

  const isStepEnabled = (stepId) => {
    return state.steps[stepId].enabled;
  }

  let stepContent = null;
  switch (state.stepId) {
    case "setDomain":
      stepContent = <SetDomain
        nextStep={nextStep}
        prevStep={prevStep}
        setWizardState={setState}
        state={state}
      />;
      break;

    case "linkAdWords":
      stepContent = <LinkAdWords
        nextStep={nextStep}
        prevStep={prevStep}
        setWizardState={setState}
        state={state}
      />;
      break;

    case "approveLink":
      stepContent = <ApproveAdWordsLink
        nextStep={nextStep}
        prevStep={prevStep}
        setWizardState={setState}
        state={state}
      />;
      break;

    case "installTracking":
      stepContent = <InstallTrackingCode
        nextStep={nextStep}
        prevStep={prevStep}
        setWizardState={setState}
        state={state}
      />;
      break;

    default:
  }

  if (state.isLoading) {
    return (<></>);
  }

  return (
    <>
      <div className="wizard_holder">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-5">
              <div className="wizard_left-holder">
                <div className="wizard_left-logo">
                  <img src="/svg/logo-wizard.svg" className="wizard_left-logo--elem"
                       alt="SafeCPC logo"/>
                </div>
                <div className="wizard_left-hero">
                  <h1>
                    Setup Wizard.<br/>
                    We are building your<br/>
                    safety now.
                  </h1>
                </div>
              </div>
            </div>
            <div className="col-lg-7">
              <div className="wizard_right-holder">
                <div className="wizard_right-holder--header-buttons">
                </div>
                <div className="wizard_right-navigation">
                  <ul>
                    <li>
                      <button disabled={!isStepEnabled("linkAdWords")} onClick={() => goToStep("linkAdWords")}>
                        <div className="wizard_right-navigation--elem default--shadow">
                          <div className="wizard_right-navigation--number">
                            <span>1</span>
                          </div>
                          <div className="wizard_right-navigation--title">
                            Submit
                            Google ADS ID
                          </div>
                        </div>
                      </button>
                    </li>
                    <li>
                      <button disabled={!isStepEnabled("approveLink")} onClick={() => goToStep("approveLink")}>
                        <div className="wizard_right-navigation--elem default--shadow">
                          <div className="wizard_right-navigation--number">
                            <span>2</span>
                          </div>
                          <div className="wizard_right-navigation--title">
                            Approve Google ADS
                            Access
                          </div>
                        </div>
                      </button>
                    </li>
                    <li>
                      <button disabled={!isStepEnabled("installTracking")} onClick={() => goToStep("installTracking")}>
                        <div className="wizard_right-navigation--elem default--shadow">
                          <div className="wizard_right-navigation--number">
                            <span>3</span>
                          </div>
                          <div className="wizard_right-navigation--title">
                            Install
                            Tracking Code
                          </div>
                        </div>
                      </button>
                    </li>
                    <li>
                      <button disabled={true} href="#">
                        <div className="wizard_right-navigation--elem default--shadow">
                          <div className="wizard_right-navigation--number">
                            <span>4</span>
                          </div>
                          <div className="wizard_right-navigation--title">
                            Success!
                            We got you covered
                          </div>
                        </div>
                      </button>
                    </li>
                  </ul>
                </div>
                {stepContent}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddNewProject;