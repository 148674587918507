import {PROJECT_LOADING_STATE, PROJECTS_LOADING_STATE, UPDATE_PROJECT_STATE, UPDATE_PROJECTS_STATE} from "../../types"

export default (state, action) => {
  switch (action.type) {
    case PROJECT_LOADING_STATE:
      return {
        ...state,
        isProjectLoading: true,
        defaultProjectLoadInitialized: true,
      };

    case PROJECTS_LOADING_STATE:
      return {
        ...state,
        isProjectsLoading: true
      };

    case UPDATE_PROJECT_STATE:
      let project = action.payload;
      return {
        ...state,
        projectId: project.id,
        project: project,
        defaultProjectLoadInitialized: true,
        isProjectLoading: false
      };

    case UPDATE_PROJECTS_STATE:
      return {
        ...state,
        projects: action.payload,
        isProjectsLoading: false
      };

    default:
      return state;
  }
}