import React, {useEffect, useState} from 'react';
import {ContentBox, ContentBoxHeader} from '../box/ContentBox';
import {Result, Table, Tag} from 'antd';
import {Button} from '../../layout/Button';
import * as UserApi from "../../../api/users";
import Scrollbar from 'react-scrollbars-custom';

export const InvoicesInner = (props) => {

  const invoicesColumns = () => {
    if (!props.standalone) {
      return [
        {
          title: 'Status', dataIndex: 'status', key: 'status', render: (status) => {
            // let icon = status === 'open' ? 'linear-loading' : 'linear-checkmark-circle';
            let color = status === 'open' ? 'red' : 'green';
            return (
              <>
                <Tag color={color}>{status}</Tag>
              </>
            );
          }
        },
        {title: 'No.', dataIndex: 'number', key: 'number'},
        {title: 'Date', dataIndex: 'date', key: 'date'}
      ]
    } else {
      return [
        {
          title: 'Status', dataIndex: 'status', key: 'status', render: (status) => {
            // let icon = status === 'open' ? 'linear-loading' : 'linear-checkmark-circle';
            let color = status === 'open' ? 'red' : 'green';
            return (
              <>
                <Tag color={color}>{status}</Tag>
              </>
            );
          }
        },
        {title: 'No.', dataIndex: 'number', key: 'number'},
        {title: 'Date', dataIndex: 'date', key: 'date'},
        {title: 'Price', dataIndex: 'price', key: 'price'},
      ]
    }
  }

  // const convertDate = (date) => {
  //  return date;
  // }

  return (
    <>

      <div className={`scpc--account-invoices`}>
        <Table
          columns={invoicesColumns()}
          loading={props.isLoading}
          scroll={{x: true}}
          expandable={{
            expandedRowRender: (record) => {
              if (record.status === 'paid') {
                return (
                  <>
                    <div className={`scpc--account-invoice-inner`}>
                      <Result
                        status="success"
                        title="Payment status: success"
                        subTitle={`Invoice number: ${record.number}. Amount: ${record.price}`}
                      />
                      <div className={`scpc--account-invoice-actions`}>
                        <Button border={true} color={'blue'} onClick={() => {
                          Object.assign(document.createElement('a'), {target: '_blank', href: record.print}).click();
                        }}>Print</Button>
                        <Button border={true} color={'blue'} onClick={() => {
                          Object.assign(document.createElement('a'), {target: '_blank', href: record.link}).click();
                        }}>View</Button>
                      </div>
                    </div>
                  </>
                )
              } else {
                return (
                  <>
                    <div className={`scpc--account-invoice-inner`}>
                      <Result
                        title="We cannot charge you automatically, please finalize this order."
                        subTitle={`Invoice number: ${record.number}. Amount: ${record.price}`}
                      />

                      <div className={`scpc--account-invoice-actions`}>
                        {record.print ?
                          <Button border={true} color={'blue'} onClick={() => {
                            Object.assign(document.createElement('a'), {target: '_blank', href: record.print}).click();
                          }}>Print</Button> : ''}
                        {record.link ?
                          <Button border={true} color={'blue'} onClick={() => {
                            Object.assign(document.createElement('a'), {target: '_blank', href: record.link}).click();
                          }}>Pay now</Button> : ''}
                      </div>
                    </div>
                  </>);
              }
            },
            rowExpandable: () => {
              return true
            }
          }}
          dataSource={props.data}
        />
      </div>

    </>
  )
}

export const Invoices = (props) => {
  const [invoices, setInvoices] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const convertInvoicesResponse = (invoicesResponse) => {
    let convertedInvoices = [];
    invoicesResponse.map((invoice) => {
      let invoiceModel = {
        key: invoice.id,
        number: invoice.number,
        date: '',
        price: '',
        link: invoice.hosted_invoice_url,
        print: invoice.invoice_pdf,
        status: invoice.status,
        currency: ''
      }

      let currencySymbol;
      switch (invoice.currency) {
        case 'usd':
          currencySymbol = '$';
          break;
        case 'pln':
          currencySymbol = 'PLN';
          break;
        default:
          currencySymbol = '';
      }
      invoiceModel.currency = currencySymbol;

      const showPrice = (amount, currency) => {
        if (currency === '$') {
          return currency + amount + '.00';
        } else if (currency === 'PLN') {
          return amount + '.00 ' + currency;
        }
      }
      let amountFromResponse = invoice.paid ? invoice.amount_paid : invoice.amount_remaining;
      invoiceModel.price = showPrice(amountFromResponse, currencySymbol);

      invoiceModel.date = new Date(invoice.created * 1000).toLocaleDateString('en-US', {
        month: 'long',
        day: '2-digit',
        year: 'numeric'
      });

      convertedInvoices.push(invoiceModel);
      return null;
    });
    // console.log(convertedInvoices);
    return convertedInvoices;
  }

  useEffect(() => {
    UserApi.getInvoices().then((resp) => {
      setInvoices(convertInvoicesResponse(resp.invoices.data));
      // console.log(invoices);
      setIsLoading(false);
    })
  }, []);

  return (
    <>
      {!props.standalone ?
        <>
          <Scrollbar disableTracksWidthCompensation style={{position: "", 'height': '100vh', 'padding': '40px'}}>
            <div style={{'width': '100%', 'padding': '20px'}}>
              <h1 className={`mt-10 mb-60`}>Invoices</h1>
              <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                <ContentBoxHeader title={`Your Invoices`}>
                </ContentBoxHeader>
                <InvoicesInner data={invoices} isLoading={isLoading}/>
              </ContentBox>
            </div>
          </Scrollbar>
        </>
        :
        <>
          <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
            <ContentBoxHeader title={`Your Invoices`}>
            </ContentBoxHeader>
            <InvoicesInner data={invoices} standalone={props.standalone} isLoading={isLoading}/>
          </ContentBox>
        </>
      }
    </>
  )
};