import {
  PROJECT_SETTINGS_LOADING_STATE,
  UPDATE_PROJECT_SETTINGS_STATE,
  UPDATE_PROJECT_SETTINGS_UI_STATE
} from "../../types"

export default (state, action) => {
  switch (action.type) {
    case PROJECT_SETTINGS_LOADING_STATE:
      return {
        ...state,
        isProjectLoading: true
      };

    case UPDATE_PROJECT_SETTINGS_STATE:
      let project = action.payload;
      return {
        ...state,
        project: project,
        defaultProjectId: project.id,
        projectId: project.id,
        isProjectLoading: false
      };

    case UPDATE_PROJECT_SETTINGS_UI_STATE:
      let ui = action.payload;
      return {
        ...state,
        ui: ui
      };

    default:
      return state;
  }
}