import React from 'react';
import CardForm from '../../CF/Stripe/CardOption';
// import {loadStripe} from "@stripe/stripe-js";
import auth0Client from '../../../Auth';

export const AddPaymentMethod = (props) => {
  // const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  const isForceAddCard = () => {
    if (auth0Client.getUser()) {
      return auth0Client.getUser().meta.forceAddPaymentMethod === true
    } else {
      return false;
    }
  }
  return (
    <>
      <div className={`container-fluid`}>
        <div className={`row justify-content-center`}>
          <div className={`col-lg-8 col-md-10 col-sm-10 col-xs-12`}>
            <div className={`scpc--account-baner`}>
              <div className={`scpc--account-baner-content`}>
                {isForceAddCard() ? <>
                  <h4>Add payment method</h4>
                  <p>
                    We need to authorize your payment method before your trial subscription start.
                    Please note, that we <strong>will not</strong> charge your account until the
                    trial ends.
                    We'll inform you 2 days before your trial end, so no worries.
                  </p>
                </> : <>
                  <h4>Add or edit payment method</h4>
                  <p>
                    Here you have your payment methods. You can edit existing, remove them and add
                    new.
                    Please note that we need you to have one working payment method to provide our
                    services for you.
                  </p>
                </>}
              </div>
              <img
                src={`/svg/payments_1.svg`}
                className={`scpc--account-baner-image`}
                alt={`Payments baner`}
              />
            </div>
            <CardForm type={`standalone`}/>
            {/*<Elements stripe={stripePromise}>*/}
            {/*  */}
            {/*</Elements>*/}
          </div>
        </div>
      </div>
    </>
  )
}