import React, {useState} from 'react';
import {useForm} from 'react-hook-form';
import {addNewDomain} from '../../../api/wizard';
import {LoaderElem} from '../Loader';
import {toast, ToastContainer} from 'react-toastify';
import HtmlParser from "react-html-parser";
import 'react-toastify/dist/ReactToastify.css';

const SetDomain = (props) => {
  const {handleSubmit, register, errors} = useForm();
  const {state, nextStep} = props;
  const [loading, setLoading] = useState(false);
  const onSubmit = data => {
    setLoading(true);
    addNewDomain({
      domain: data.domain,
      payment_method: 'default',
      'plan_id': 1,
      'trial': true
    }).then((res) => {
      setLoading(false);
      nextStep({
        ...state,
        cfProject: res.cfProject,
        'subscription': res.subscription
      });
    }).catch(error => {
      switch (error.response.data.error) {
        case 'DomainAlreadyManaged':
          toast('This domain is already managed in SafeCPC', {
            onClose: () => {
              setLoading(false);
            },
            type: toast.TYPE.ERROR
          });
          break;
        default:
          break;
      }
    });
  };

  return (
    <>
      <ToastContainer/>
      <div className="wizard_right-holder scpc--forms-center-form-holder">
        <div className={`scpc--forms-center-form-holder-inner container`}>

          <form onSubmit={handleSubmit(onSubmit)}
                className={`scpc--forms-adddomain scpc--forms-form`}
                autoComplete={'off'}
          >
            <LoaderElem loading={loading}/>
            <div className={`scpc--forms-inner-hero text-center`}>
              <h3>Add new domain</h3>
              <p>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                Pellentesque tellus magna, consectetur vulputate dolor a,
                sollicitudin rhoncus tellus.
              </p>
            </div>
            <div
              className={`scpc--forms-input-holder input-main input-with-icon ${errors.domain ? 'has-error' : ''}`}>
              <i className={'icon-web'}/>
              <input type="text" placeholder="Your domain" name="domain"
                     ref={register({
                       required: true,
                       pattern: /^(([a-zA-Z]{1})|([a-zA-Z]{1}[a-zA-Z]{1})|([a-zA-Z]{1}[0-9]{1})|([0-9]{1}[a-zA-Z]{1})|([a-zA-Z0-9][a-zA-Z0-9-_]{1,61}[a-zA-Z0-9]))\.([a-zA-Z]{2,6}|[a-zA-Z0-9-]{2,30}\.[a-zA-Z]{2,3})$/i
                     })}
                     className={`scpc--forms-input-text input-large`}
              />
              {errors.domain && HtmlParser(
                "<small class='scpc--forms-input-error'>Please enter a valid domain name, without https://</small>")}
            </div>
            <div className="scpc--forms-input-holder input-submit input-center py-5">
              <input type="submit" value={'Add this domain'}
                     className={'scpc--button-border color-blue button-rounded'}/>
            </div>
          </form>
        </div>
      </div>

    </>
  );
};

export default SetDomain;