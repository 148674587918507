import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {Table, Tag} from 'antd';
import {Link, useParams} from "react-router-dom";
import {UAParser} from "../Helpers/UAParser";
import {Calendar} from '../Calendar/Calendar';
import {RightContent} from "../appLayout/RightContent";
import {ContentBox, ContentBoxHeader} from "../box/ContentBox";
import {AnalyticsLeft} from './AnalyticsLeft';
import {isMobileSafari} from 'react-device-detect';
import ProjectContext from "../../../context/CF/ProjectContext/ProjectContext";
import * as ProjectApi from "../../../api/projects";
import 'antd/dist/antd.css';
import routes, {getRoute} from "../../../routes/routes";
import {SparkSummaryGraphs} from "./Graphs/SparkSummaryGraphs";

export const AnalyticsClickFraud = (props) => {
  const [calendarStartDate, setStartDate] = useState(moment().subtract(1, 'week'));
  const [calendarEndDate, setEndDate] = useState(moment());
  const {projectId} = useParams();

  const defaultSearchParams = {
    fromDate: calendarStartDate.format('YYYY-MM-DD').toString(),
    endDate: calendarEndDate.format('YYYY-MM-DD').toString(),
    mode: 'frauds',
    page: 1,
    pageSize: 20,
    ip: null,
    fingerprint: null,
    gclid: null,
    country: null,
    deviceId: null,
    city: null,
    adPlacement: null,
    browserAgent: null,
    keywords: null,
    url: null,
    isp: null,
    is_fraudulent: null
  };

  const [visits, setVisits] = useState({isLoading: true, params: {}, data: [], sparkGraphs: {}});
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const projectContext = useContext(ProjectContext);
  useEffect(() => {
    projectContext.loadProject(projectId).then((project) => {
      search();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * usage: search({ stuff you want to set, ...searchParams}. After API results searchParams will be updated
   * with new fields if provided etc.
   **/
  const search = (params) => {
    setVisits({...visits, isLoading: true});
    ProjectApi.getVisits(projectId, {...searchParams, ...params})
      .then(results => {
        setSearchParams({
          ...results.params,
          ...params
        });
        setVisits({...results, isLoading: false});
      });
  }

  const expandable = {
    expandedRowRender: row => (
      <>
        <div className={`scpc--datatable-expanded`}>
          <div className={`scpc--datatable-expanded-left`}>
            <h6>More informations:</h6>
            <p>gclid: <Tag color="default">{row.gclid}</Tag></p>
            <p>Location: <Tag color="default">{row.city}, {row.country}</Tag></p>
            <p>Ad placement: {row.ad_placement}</p>
          </div>
          <div className={`scpc--datatable-expanded-right`}>
            <h6>AdWords informations:</h6>
            <p>Campaign ID: <Tag color="default">{row.adwords_campaign_id}</Tag></p>
            <p>Campaign Group ID: <Tag color="default">{row.adwords_campaign_group_id}</Tag></p>
            <p>Ad Group ID: <Tag color="default">{row.adwords_ad_group_id}</Tag></p>
          </div>
        </div>
      </>
    )
  };

  const pagination = {
    total: visits.total || 0,
    defaultPageSize: visits.params.pageSize || 20,
    page: visits.params.page,
    onChange: (page, pageSize) => {
      search({
        ...searchParams,
        page,
        pageSize
      });
    }
  };

  const onCalendarSave = () => {
    let startDate = calendarStartDate.format('YYYY-MM-DD').toString();
    let endDate = calendarEndDate.format('YYYY-MM-DD').toString();
    search({fromDate: startDate, toDate: endDate, page: 1});
  }

  const columns = [
    {
      title: "Date",
      dataIndex: "logged_at",
      key: "logged_at",
      ellipsis: true,
      // render: (logged_at) => {
      //   let datetime = logged_at.split(' ')
      //   return (
      //     <>
      //       <div className={`scpc--datatable-datetime`}>
      //         <span>
      //         <i className={`linear-calendar-31`}/>
      //           {datetime[0]}
      //       </span>
      //         <span>
      //         <i className={`linear-clock`}/>
      //           {datetime[1]}
      //       </span>
      //       </div>
      //     </>
      //   )
      // }
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip",
      render: (ip) => {
        return (
          <Link to={getRoute(routes.analyticsDetailsByIp, {projectId, ip})}>{ip}</Link>
        )
      }
    },
    {
      title: "Fingerprint",
      dataIndex: "fingerprint",
      key: "fingerprint",
      render: (fingerprint) => {
        return (
          <Link to={getRoute(routes.analyticsDetailsByFingerprint,
            {projectId, fingerprint})}>{fingerprint}</Link>
        )
      }
    },
    // {
    //   title: "Country",
    //   dataIndex: "country_code",
    //   key: "country_code",
    //   render: (country_code) => {
    //     return (
    //       <div className={`datatable-flag-container`}>
    //         {/*<Flag code={country_code} height={22} fallback={ <span>Unknown</span> }/>*/}
    //       </div>
    //
    //       );
    //   }
    // },
    // {
    //   title: "Ban",
    //   dataIndex: "is_ban_active",
    //   key: "is_ban_active",
    //   render: (isBanActive, record) => {
    //     return (
    //       <Switch checkedChildren="Blocked" unCheckedChildren="Block" defaultChecked={isBanActive}
    //               onChange={(checked) => ProjectApi.changeBanStatus(projectId, checked, record)}/>
    //     )
    //   }
    // },
    {
      title: 'Device',
      dataIndex: 'device',
      key: 'device',
      ellipsis: true,
      render: (device) => {
        switch (device) {
          case 'm':
            return (<>Mobile</>);
          case 't':
            return (<>Tablet</>);
          case 'd':
            return (<>Desktop</>);
          default:
            return (<></>);
        }
      }
    },
    {
      title: 'gclid',
      key: 'gclid',
      dataIndex: 'gclid',
      ellipsis: true,
    },
    {
      title: "Browser",
      dataIndex: "browser_agent",
      key: "browser_agent",
      ellipsis: true,
      render: (browser_agent) => {
        return (<UAParser ua={browser_agent} show={`browser`} showLabel={true}/>);
      }
    },
    {
      title: "OS",
      dataIndex: "browser_agent",
      key: "browser_agent_os",
      ellipsis: true,
      render: (browser_agent_os) => {
        return (<UAParser ua={browser_agent_os} show={`os`} showLabel={true}/>);
      }
    },
  ];

  return (
    <ProjectContext.Consumer>
      {({isProjectLoading}) => (
        <>
          <AnalyticsLeft load={props.load}/>
          {isProjectLoading ? <div></div> :
            <>
              <RightContent>
                <div className={`container-fluid chartboxes-header ${isMobileSafari ? 'isMobileSafari' : ''}`}>
                  <div className={`row`}>
                    <SparkSummaryGraphs
                      isLoading={visits.isLoading}
                      graphData={visits.sparkGraphs}
                      isMobileSafari={isMobileSafari}
                    />
                    <div className={`col-3 chartboxes-calendar`}>
                      <h4>
                        <i className={`linear-calendar-31`}/>
                        Pick a date range
                      </h4>
                      <Calendar
                        startDate={calendarStartDate}
                        endDate={calendarEndDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        onCalendarSave={onCalendarSave}
                      />
                    </div>
                  </div>
                </div>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                  <ContentBoxHeader title={`Page clicks data`}/>
                  <Table
                    dataSource={visits.data}
                    rowKey={'id'}
                    expandable={expandable}
                    pagination={pagination}
                    size="medium"
                    columns={columns}
                    loading={visits.isLoading}
                    scroll={{x: true}}
                    rowClassName={(record, rowIndex) => {
                      console.log(record);
                      if (record.is_fraudulent) {
                        return 'has_ban';
                      }
                    }}
                    // onRow={(record, rowIndex) => {
                    //   console.log(record);
                    //   console.log(rowIndex);
                    //   if(record.is_fraudulent){
                    //
                    //   }
                    //   return {
                    //
                    //     onClick: event => {}, // click row
                    //     onDoubleClick: event => {}, // double click row
                    //     onContextMenu: event => {}, // right button click row
                    //     onMouseEnter: event => {}, // mouse enter row
                    //     onMouseLeave: event => {}, // mouse leave row
                    //   };
                    // }}
                  >
                  </Table>
                </ContentBox>
              </RightContent>
            </>
          }

        </>
      )}
    </ProjectContext.Consumer>
  );
};
