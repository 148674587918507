import React, {useContext, useEffect, useState} from 'react';
import moment from 'moment';
import {Table} from 'antd';
import {Link, useParams} from "react-router-dom";
import {Calendar} from '../Calendar/Calendar';
import {RightContent} from "../appLayout/RightContent";
import {ContentBox, ContentBoxHeader} from "../box/ContentBox";
import {AnalyticsLeft} from './AnalyticsLeft';

import ProjectContext from "../../../context/CF/ProjectContext/ProjectContext";
import * as ProjectApi from "../../../api/projects";
import 'antd/dist/antd.css';
import routes, {getRoute} from "../../../routes/routes";
import ReactApexChart from "react-apexcharts";
import _ from "lodash";
import {BanReason} from "./Graphs/BanReason";
import {isMobile} from "react-device-detect";
// eslint-disable-next-line no-unused-vars
const {Column, ColumnGroup} = Table;

export const AnalyticsIps = (props) => {
  const [calendarStartDate, setStartDate] = useState(moment().subtract(1, 'week'));
  const [calendarEndDate, setEndDate] = useState(moment());
  const {projectId} = useParams();

  const defaultSearchParams = {
    fromDate: calendarStartDate.format('YYYY-MM-DD').toString(),
    endDate: calendarEndDate.format('YYYY-MM-DD').toString(),
    mode: 'ips',
    page: 1,
    pageSize: 20,
  };

  const [visits, setVisits] = useState({isLoading: true, params: {}, data: []});
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const projectContext = useContext(ProjectContext);

  useEffect(() => {
    projectContext.loadProject(projectId).then((project) => {
      search();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * usage: search({ stuff you want to set, ...searchParams}. After API results searchParams will be updated
   * with new fields if provided etc.
   **/
  const search = (params) => {
    setVisits({...visits, isLoading: true});
    ProjectApi.getVisits(projectId, {...searchParams, ...params})
      .then(results => {
        setSearchParams({
          ...results.params,
          ...params
        });
        setVisits({...results, isLoading: false});

        let newMostFraudulentIps = _.cloneDeep(mostFraudulentIps);
        newMostFraudulentIps.series[0].data = results.charts.mostFraudulentIps.series[0].data;
        newMostFraudulentIps.options.xaxis = results.charts.mostFraudulentIps.options.xaxis;

      //  console.log(newMostFraudulentIps);
        setMostFraudulentIps(newMostFraudulentIps);
      });
  }

  const pagination = {
    total: visits.total || 0,
    defaultPageSize: visits.params.pageSize || defaultSearchParams.pageSize,
    page: visits.params.page,
    onChange: (page, pageSize) => {
      search({
        ...searchParams,
        page,
        pageSize
      });
    }
  };

  const columns = [
    {
      title: "IP",
      dataIndex: "ip",
      render: (ip) => {
        return (
          <Link to={getRoute(routes.analyticsDetailsByIp, {projectId, ip})}>{ip}</Link>
        )
      }
    },
    {
      title: "Click Count",
      dataIndex: "click_count"
    },
    {
      title: "Fraud Count",
      dataIndex: "fraud_count",
    },
    {
      title: "Country",
      dataIndex: "country",
    },
    {
      title: "Last Visit",
      dataIndex: "last_visit",
    },
    {
      title: "Fingerprints",
      dataIndex: "fingerprints",
      render: (value) => {
        if (value) {
          let fingerprints = value.split(',');
          return (
            <>{fingerprints.map((fingerprint) => (
              <>
                <Link to={getRoute(routes.analyticsDetailsByFingerprint,
                  {projectId, fingerprint})}>{fingerprint}</Link><br/></>
            ))}
            </>
          )
        }
      }
    },
  ];

  const onCalendarSave = () => {
    let startDate = moment(calendarStartDate).format('YYYY-MM-DD').toString();
    let endDate = moment(calendarEndDate).format('YYYY-MM-DD').toString();
    search({fromDate: startDate, toDate: endDate, page: 1});
  }

  const [mostFraudulentIps, setMostFraudulentIps] = useState({
    series: [{
      data: []
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350
      },
      plotOptions: {
        bar: {
          horizontal: true,
        }
      },
      dataLabels: {
        enabled: false
      },
      xaxis: {
        categories: [],
      }
    }
  })
  return (
    <ProjectContext.Consumer>
      {({isProjectLoading}) => (
        <>
          <AnalyticsLeft load={props.load}/>
          {isProjectLoading ? <div></div> :
            <>
              <RightContent>
                <div className={`container-fluid chartboxes-header`}>
                  <div className={`row`}>
                    <div className={`col-6`}>
                      <h3>IP Analytics</h3>
                    </div>
                    <div className={`col-3 chartboxes-calendar`}>
                      <h4>
                        <i className={`linear-calendar-31`}/>
                        Pick a date range
                      </h4>
                    </div>
                    <div className={`col-3 chartboxes-calendar`}>
                      <Calendar
                        startDate={calendarStartDate}
                        endDate={calendarEndDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        onCalendarSave={onCalendarSave}
                      />
                    </div>
                  </div>
                </div>
                <div className={`container-fluid`}>
                  <div className={`row`}>
                    <div
                      className={`col-lg-8 col-md-12 col-sm-12 col-xs-12 pl-0 analytics-chart-col`}>
                      <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                        <ContentBoxHeader title={`Most fraudulent IPs`}/>
                        <ReactApexChart options={mostFraudulentIps.options}
                                        series={mostFraudulentIps.series} type="bar"
                                        height={350}/>
                      </ContentBox>
                    </div>
                    <div
                      className={`col-lg-4 col-md-12 col-sm-12 col-xs-12 pr-0 analytics-chart-col`}>
                      <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                        <ContentBoxHeader title={`Ban by reason`}/>
                        {!visits.isLoading &&
                        <BanReason
                          labels={visits.charts.banReason.labels}
                          series={visits.charts.banReason.series}
                          isMobile={isMobile}
                        />
                        }
                      </ContentBox>
                    </div>
                  </div>


                </div>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                  <ContentBoxHeader title={`Page clicks data`}/>
                  <Table
                    dataSource={visits.data}
                    rowKey={'ip'}
                    // expandable={expandable}
                    pagination={pagination}
                    size="medium"
                    columns={columns}
                    loading={visits.isLoading}
                    scroll={{x: true}}
                  >
                  </Table>
                </ContentBox>
              </RightContent>
            </>
          }
        </>
      )}
    </ProjectContext.Consumer>
  );
};
