import React, {useContext, useEffect, useState} from 'react';
import {useParams} from "react-router-dom";
import {RightContent} from "../appLayout/RightContent";
import {ContentBox, ContentBoxHeader} from "../box/ContentBox";
import {BansList} from "./DetailsView/BansList";
import {VisitsList} from "./DetailsView/VisitsList";

import ProjectContext from "../../../context/CF/ProjectContext/ProjectContext";
import * as ProjectApi from "../../../api/projects";
import 'antd/dist/antd.css';
import { DetailsLeft } from './DetailsLeft';

export const AnalyticsFingerprintDetails = () => {
  const {projectId, fingerprint} = useParams();
  const defaultSearchParams = {mode: 'fingerprint', fingerprint};

  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const [details, setDetails] = useState({isLoading: true});
  const projectContext = useContext(ProjectContext);

  useEffect(() => {
    projectContext.loadProject(projectId).then((project) => {
      loadDetails();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * usage: search({ stuff you want to set, ...searchParams}. After API results searchParams will be updated
   * with new fields if provided etc.
   **/
  const loadDetails = (params) => {
    setDetails({...details, isLoading: true});
    ProjectApi.getVisitorDetails(projectId, {...searchParams, ...params})
      .then(results => {
        setSearchParams({
          ...results.params,
          ...params
        });
        setDetails({...results, isLoading: false});
      });
  }

  return (
    <ProjectContext.Consumer>
      {({isProjectLoading}) => (
        <>
          {details.isLoading ? <></> : <DetailsLeft bans={details.bans} projectID={projectId} fingerprint={fingerprint}/>}
          {isProjectLoading ? <div></div> :
            <>
              <RightContent>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                  <ContentBoxHeader title={<span>Details for: <i className="fal fa-fingerprint" /> {fingerprint}</span>}/>
                  {details.isLoading ? <></> :
                    <>
                      <BansList
                        bans={details.bans}
                      />

                      <VisitsList
                        visits={details.visits}
                        projectId={projectId}
                      />
                    </>
                  }
                </ContentBox>
              </RightContent>
            </>
          }
        </>
      )}
    </ProjectContext.Consumer>
  );
};
