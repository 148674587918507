import React, { useContext, useEffect, useState } from 'react';
import moment from 'moment';
import { uuid } from "uuidv4";
import { Switch, Table, Tag } from 'antd';
import { useParams } from "react-router-dom";
import ReactApexChart from "react-apexcharts";
import { UAParser } from "../Helpers/UAParser";
import { Calendar } from '../Calendar/Calendar';
import { RightContent } from "../appLayout/RightContent";
import { LeftMainBoxes } from "../appLayout/LeftMainBoxes";
import { ContentBox, ContentBoxHeader } from "../box/ContentBox";

import ProjectContext from "../../../context/CF/ProjectContext/ProjectContext";
import * as ProjectApi from "../../../api/projects";
import 'antd/dist/antd.css';
// eslint-disable-next-line no-unused-vars
const { Column, ColumnGroup } = Table;

const VisitsSearch = () => {
  const [calendarStartDate, setStartDate] = useState(moment());
  const [calendarEndDate, setEndDate] = useState(moment());
  const { projectId } = useParams();

  const defaultSearchParams = {
    page: 1,
    pageSize: 20,
    ip: null,
    fingerprint: null,
    gclid: null,
    country: null,
    device_id: null,
    city: null,
    ad_placement: null,
    browser_agent: null,
    keywords: null,
    url: null,
    isp: null,
  };

  const [visits, setVisits] = useState({ isLoading: true, params: {}, data: [] });
  const [searchParams, setSearchParams] = useState(defaultSearchParams);
  const projectContext = useContext(ProjectContext);
  // eslint-disable-next-line no-unused-vars
  const [sparkFirst, setSparkFirst] = useState(
    {
      series: [{
        data: [25, 66, 41, 59, 25, 44, 12, 36, 9, 21]
      }],
      options: {
        chart: {
          id: 'spark2',
          group: 'sparks',
          type: 'line',
          height: 80,
          foreColor: '#ccc',
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 2,
            opacity: 0.2,
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        grid: {
          padding: {
            top: 20,
            bottom: 10,
            left: 110
          },
          borderColor: "#535A6C",
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        markers: {
          size: 0
        },
        colors: ['#fff'],
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function formatter(val) {
                return '';
              }
            }
          }
        }
      }
    }
  );
  // eslint-disable-next-line no-unused-vars
  const [sparkSecond, setSparkSecond] = useState(
    {
      series: [{
        data: [12, 14, 2, 47, 32, 44, 14, 55, 41, 69]
      }],
      options: {
        chart: {
          id: 'spark2',
          group: 'sparks',
          type: 'line',
          height: 80,
          foreColor: '#ccc',
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 2,
            opacity: 0.2,
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        grid: {
          padding: {
            top: 20,
            bottom: 10,
            left: 110
          },
          borderColor: "#535A6C",
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        markers: {
          size: 0
        },
        colors: ['#fff'],
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function formatter(val) {
                return '';
              }
            }
          }
        }
      }
    }
  );
  // eslint-disable-next-line no-unused-vars
  const [sparkThird, setSparkThird] = useState(
    {
      series: [{
        data: [47, 45, 74, 32, 56, 31, 44, 33, 45, 19]
      }],
      options: {
        chart: {
          id: 'spark2',
          group: 'sparks',
          type: 'line',
          height: 80,
          foreColor: '#ccc',
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          dropShadow: {
            enabled: true,
            top: 1,
            left: 1,
            blur: 2,
            opacity: 0.2,
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth',
          width: 3
        },
        grid: {
          padding: {
            top: 20,
            bottom: 10,
            left: 110
          },
          borderColor: "#535A6C",
          xaxis: {
            lines: {
              show: true
            }
          }
        },
        markers: {
          size: 0
        },
        colors: ['#fff'],
        tooltip: {
          theme: 'dark',
          x: {
            show: false
          },
          y: {
            title: {
              formatter: function formatter(val) {
                return '';
              }
            }
          }
        }
      }
    }
  );
  useEffect(() => {
    projectContext.loadProject(projectId).then((project) => {
      search();
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * usage: search({ stuff you want to set, ...searchParams}. After API results searchParams will be updated
   * with new fields if provided etc.
   **/
  const search = (params) => {
    setVisits({ ...visits, isLoading: true });
    ProjectApi.getVisits(projectId, params)
    .then(results => {
      setVisits({ ...results, isLoading: false });
      setSearchParams({
        ...params,
        page: results.params.page,
        pageSize: results.params.pageSize
      });
    });
  }

  const expandable = {
    expandedRowRender: row => (
      <>
        <div className={`scpc--datatable-expanded`}>
          <div className={`scpc--datatable-expanded-left`}>
            <h6>More informations:</h6>
            <p>gclid: <Tag color="default">{row.gclid}</Tag></p>
            <p>Location: <Tag color="default">{row.city}, {row.country}</Tag></p>
            <p>Ad placement: {row.ad_placement}</p>
          </div>
          <div className={`scpc--datatable-expanded-right`}>
            <h6>AdWords informations:</h6>
            <p>Campaign ID: <Tag color="default">{row.adwords_campaign_id}</Tag></p>
            <p>Campaign Group ID: <Tag color="default">{row.adwords_campaign_group_id}</Tag></p>
            <p>Ad Group ID: <Tag color="default">{row.adwords_ad_group_id}</Tag></p>
          </div>
        </div>
      </>
    )
  };

  const pagination = {
    total: visits.total || 0,
    defaultPageSize: visits.params.pageSize || 20,
    page: visits.params.page,
    onChange: (page, pageSize) => {
      search({
        ...searchParams,
        page,
        pageSize
      });
    }
  };

  const columns = [
    {
      title: "Date",
      dataIndex: "logged_at",
      key: "logged_at",
      render: (logged_at) => {
        let datetime = logged_at.split(' ')
        // console.log(datetime)
        return (
          <>
            <div className={`scpc--datatable-datetime`}>
              <span>
              <i className={`linear-calendar-31`}/>
                {datetime[0]}
            </span>
              <span>
              <i className={`linear-clock`}/>
                {datetime[1]}
            </span>
            </div>
          </>
        )
      }
    },
    {
      title: "IP",
      dataIndex: "ip",
      key: "ip"
    },
    {
      title: "Country",
      dataIndex: "country",
      key: "country"
    },
    {
      title: "Device",
      dataIndex: "fingerprint",
      key: "fingerprint",
      render: (fingerprint) => {
        return (
          <Switch checkedChildren="Blocked" unCheckedChildren="Block"/>
        )
      }
    },
    {
      title: 'Device',
      dataIndex: 'browser_agent',
      key: 'device_type',
      render: (browser_agent) => {
        return (<UAParser ua={browser_agent} show={`browser`} showLabel={false}/>);
      }
    },
    {
      title: "Browser",
      dataIndex: "browser_agent",
      key: "browser_agent",
      render: (browser_agent) => {
        return (<UAParser ua={browser_agent} show={`browser`} showLabel={true}/>);
      }
    },
    {
      title: "OS",
      dataIndex: "browser_agent",
      key: "browser_agent_os",
      render: (browser_agent_os) => {
        return (<UAParser ua={browser_agent_os} show={`os`} showLabel={true}/>);
      }
    },
    {
      title: "keywords",
      dataIndex: "keywords",
      key: "keywords",
      render: (keywords) => {
        return (
          <Tag color="blue">
            {keywords}
          </Tag>
        )
      }
    }
  ];

  /**
   *  Visual related settings
   */
  const leftNavBoxes = [
    {
      box: {
        'id': uuid(),
        'icon': 'linear-desktop',
        'name': 'Know more about fraudulent devices'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-mouse-left',
        'name': 'Know more about fraudulent clicks'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-chart-bars',
        'name': 'Generate report about fraud for Google'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-brain',
        'name': 'Use the best settings for my campaign'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-aim',
        'name': 'Switch settings to the extreme mode'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-glass',
        'name': 'Know more about auto-pause settings'
      }
    }
    // Know more about auto-pause settings
    //Generate report about fraud for Google
  ];

  return (
    <ProjectContext.Consumer>
      {({ isProjectLoading }) => (
        <>
          <LeftMainBoxes cols={1} boxTextCenter={true} boxesSize={'full'} boxes={leftNavBoxes}>
            <div className={`mt-20`}>
              {/*<ReactApexChart options={donutChart.options} series={donutChart.series} type="area" height={160} />*/}
            </div>
          </LeftMainBoxes>
          {isProjectLoading ? <div></div> :
            <>
              <RightContent>
                <div className={`container-fluid chartboxes-header`}>
                  <div className={`row`}>
                    <div className={`col-3`}>
                      <div className={`chartbox box-2`}>
                        <div className={`chartbox--details`}>
                          <h4>411</h4>
                          <p>Clicks</p>
                        </div>
                        <ReactApexChart options={sparkFirst.options} series={sparkFirst.series}
                                        type="line" height={80}/>
                      </div>
                    </div>
                    <div className={`col-3`}>
                      <div className={`chartbox box-3`}>
                        <div className={`chartbox--details`}>
                          <h4>131</h4>
                          <p>Frauds</p>
                        </div>
                        <ReactApexChart options={sparkSecond.options} series={sparkSecond.series}
                                        type="line" height={80}/>
                      </div>
                    </div>
                    <div className={`col-3`}>
                      <div className={`chartbox box-4`}>
                        <div className={`chartbox--details`}>
                          <h4>119</h4>
                          <p>VPNs detected</p>
                        </div>
                        <ReactApexChart options={sparkThird.options} series={sparkThird.series}
                                        type="line" height={80}/>
                      </div>
                    </div>
                    {/*<div className={`col-2`}>*/}
                    {/*  <div className={`chartbox box-5`}>*/}
                    {/*    <div className={`chartbox--details`}>*/}
                    {/*      <h4>81</h4>*/}
                    {/*      <p>VPNs detected</p>*/}
                    {/*    </div>*/}
                    {/*    <ReactApexChart options={sparkThird.options} series={sparkThird.series} type="line" height={80} />*/}
                    {/*  </div>*/}
                    {/*</div>*/}
                    <div className={`col-3 chartboxes-calendar`}>
                      <h4>
                        <i className={`linear-calendar-31`}/>
                        Pick a date range
                      </h4>
                      <Calendar
                        startDate={calendarStartDate}
                        endDate={calendarEndDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                      />
                    </div>
                  </div>
                </div>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                  <ContentBoxHeader title={`Page clicks data`}/>
                  <Table
                    dataSource={visits.data}
                    rowKey={'id'}
                    expandable={expandable}
                    pagination={pagination}
                    size="medium"
                    columns={columns}
                    loading={visits.isLoading}
                  >
                  </Table>
                </ContentBox>
              </RightContent>
            </>
          }

        </>
      )}
    </ProjectContext.Consumer>
  );
};

export default VisitsSearch;