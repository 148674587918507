export const UPDATE_PROJECT_STATE = 'UPDATE_PROJECT_STATE';
export const UPDATE_PROJECTS_STATE = 'UPDATE_PROJECTS_STATE';
export const PROJECT_LOADING_STATE = 'PROJECT_LOADING_STATE';
export const PROJECTS_LOADING_STATE = 'PROJECTS_LOADING_STATE';

export const UPDATE_PROJECT_SETTINGS_STATE = 'UPDATE_PROJECT_SETTINGS_STATE';
export const PROJECT_SETTINGS_LOADING_STATE = 'PROJECT_SETTINGS_LOADING_STATE';
export const UPDATE_PROJECT_SETTINGS_UI_STATE = 'UPDATE_PROJECT_SETTINGS_UI_STATE';

// WIZARD
export const ADD_WIZARD_DOMAIN = 'ADD_WIZARD_DOMAIN';
export const ADD_WIZARD_CF_PROJECT = 'ADD_WIZARD_CF_PROJECT';
export const ADD_WIZARD_SUBSCRIPTION = 'ADD_WIZARD_SUBSCRIPTION';
export const UPDATE_WIZARD_NEXT_STEP = 'UPDATE_WIZARD_NEXT_STEP';
export const UPDATE_WIZARD_PREV_STEP = 'UPDATE_WIZARD_PREV_STEP';
export const UPDATE_WIZARD_STEP = 'UPDATE_WIZARD_STEP';

export const APP_REGISTER_STATE = '';

export const CAMPAIGN_GROUP_TYPE_ADVANCED = 'advanced';
export const CAMPAIGN_GROUP_TYPE_DEFAULT = 'default_project_group';

export const PROJECT_GROUP_MODE_SIMPLE = 'simple';
export const PROJECT_GROUP_MODE_ADVANCED = 'advanced';

export const BAN_SOURCE_MONITOR = 'monitor';
export const BAN_SOURCE_MANUAL = 'manual';

export const NOTIFICATIONS_ARRIVED = 'NOTIFICATIONS_ARRIVED';
export const NOTIFICATIONS_UPDATED = 'NOTIFICATIONS_UPDATED';


