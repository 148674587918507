import React, {useEffect, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {LeftMainBoxes} from '../appLayout/LeftMainBoxes';
import {Button} from '../../layout/Button';
import {RightContent} from '../appLayout/RightContent';
import {ContentBox, ContentBoxFooter, ContentBoxHeader} from '../box/ContentBox';
import {Alert, Button as AntButton, Result, Spin} from 'antd';
import {CheckCircleOutlined, CopyOutlined} from '@ant-design/icons';
import * as ProjectsApi from '../../../api/projects';
import {uuid} from 'uuidv4';
import {toast} from "react-toastify";

export const DomainSetup = (props) => {
  let history = useHistory();
  const {projectId} = useParams();
  const [trackingCode, setTrackingCode] = useState();
  const [copySuccess, setCopySuccess] = useState(false);
  // 'verifying', 'installed', 'notInstalled'
  const [embedCodeVerificationStatus, setCodeVerificationStatus] = useState('verifying');

  const copyToClipBoard = async copyMe => {
    try {
      await navigator.clipboard.writeText(copyMe);
      setCopySuccess(true);
    } catch (err) {
      setCopySuccess(false);
    }
  };

  // const copyActionOptions = {
  //   loop: false,
  //   autoplay: true,
  //   animationData: BackgroundAnimationData,
  //   rendererSettings: {
  //     preserveAspectRatio: 'xMidYMid slice'
  //   }
  // };
  const leftNavBoxes = [
    {
      box: {
        'id': uuid(),
        'icon': 'linear-code',
        'name': 'How to add tracking code',
        'active': false,
        'link': '/account/support'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-shield',
        'name': 'Tracking template - FAQ',
        'active': false,
        'link': '/account/support'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-toggle-on',
        'name': 'How to verify tracking template',
        'active': false,
        'link': '/account/notifications'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'linear-file-check',
        'name': 'How to verify SCPC Monitor installation',
        'active': false,
        'link': '/account/invoices'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'icon-help',
        'name': 'Support',
        'active': false,
        'link': '/account/support'
      }
    },
    {
      box: {
        'id': uuid(),
        'icon': 'icon-0152-envelope-open',
        'name': 'Contact Us',
        'active': false,
        'link': '/account/contact'
      }
    }
  ];
  useEffect(() => {
    ProjectsApi.getEmbedCode(projectId).then((res) => {
      setTrackingCode(res.embedCode);
    })
    verifyEmbedCodeInstallation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyEmbedCodeInstallation = () => {
    setCodeVerificationStatus('verifying');
    ProjectsApi.verifyEmbedCodeInstallation(projectId).then((res) => {
      if (res.embedCodeVerified) {
        setCodeVerificationStatus('installed');
      } else {
        setCodeVerificationStatus('notInstalled');
      }
    });
  }

  const reCheckAdWords = () => {
    ProjectsApi.updateAdWordsAccountLinkStatus(projectId, 1234)
      .then((result) => {
        if (result.isAccountLinked) {
          toast.success('Google Ads account is linked.');
        } else {
          toast.error('Account is not linked.');
        }
      });
  };

  const reSendAdwordsInvitation = () => {
    ProjectsApi.linkAdWordsAccount(projectId)
      .then((result) => {
        toast.success('Invitation sent');
      })
  };

  return (
    <>
      <LeftMainBoxes
        boxes={leftNavBoxes}
        cols={2}
        boxTextCenter={true}
      >
        <div className={`scpc--user-avatarbox scpc--user-avatarbox-animation`} style={{'paddingTop': '100px'}}>
          <div className={`scpc--user-avatarbox-circle avatarbox-animate`} style={{'overflow': 'hidden'}}>
            <lord-icon animation="auto" palette="#0296ff;#313971"
                       src="/lordicons/40-gears-settings-double-outline.json"/>
          </div>
          <div className={`scpc--user-avatarbox-inner`}>

            <h4>Setup project</h4>
            <p>
              You can manage your entire account from here, however, if you need any assistance, or
              have any questions feel free to ask through the Contact Center.
            </p>
          </div>
        </div>
        <div className={`scpc--dashboard-iwantto`}>
          <h4>I want to: </h4>
          <Button
            flat={true}
            color={`blue`}
            rounded={`default`}
            width={`full`}
            textAlign={`center`}
            hasIcon={true}
            icon={`linear-plus-circle`}
            style={{'width': '100%'}}
          >
            Protect another domain
          </Button>
        </div>

      </LeftMainBoxes>
      <RightContent>
        <div className={`container-fluid`}>
          <div className={`row`}>
            <div
              className={`col-xs-12 col-sm-8 offset-sm-2 col-md-8 offset-md-2 col-lg-10 offset-lg-1`}>
              <div className={`scpc--account-baner`}>
                <div className={`scpc--account-baner-content`}>

                  <h4>Domain setup</h4>
                  <p>
                    You can verify here whether your domain and your Google Ads account are setup
                    correctly.
                  </p>
                </div>
                <img
                  src={`/svg/setup.svg`}
                  className={`scpc--account-baner-image`}
                  alt={`Payments baner`}
                />
              </div>
              <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                <ContentBoxHeader title={`Tracking template verification`}/>
                <button className={`scpc--contentbox-needhelpbtn`} onClick={() => {
                  history.push('/account/support')
                }}>

                </button>
                {embedCodeVerificationStatus === 'verifying' &&
                <Spin tip="Loading...">
                  <Alert
                    message="We are verifying now"
                    description="Please, give us a moment as we are verifying your tracking template installation now. "
                    type="info"
                  />
                </Spin>
                }

                {embedCodeVerificationStatus === 'installed' &&
                  <Result
                  status="success"
                  title="Embed code installed properly"
                  extra={
                  <AntButton
                    type="primary"
                    shape="round"
                    icon={copySuccess ? <CheckCircleOutlined/> : <CopyOutlined/>}
                    size={`large`}
                    onClick={verifyEmbedCodeInstallation}
                  >
                    Re-Check embed code installation
                  </AntButton>
                }
                  />
                }

                {embedCodeVerificationStatus === 'notInstalled' &&
                <Result
                  status="warning"
                  title="Embed code not detected"
                  extra={
                    <AntButton
                      type="primary"
                      shape="round"
                      icon={copySuccess ? <CheckCircleOutlined/> : <CopyOutlined/>}
                      size={`large`}
                      onClick={verifyEmbedCodeInstallation}
                    >
                     Re-Check embed code installation
                    </AntButton>
                  }
                />
                }
                {embedCodeVerificationStatus ? '' :
                <AntButton
                  type="primary"
                  shape="round"
                  icon={copySuccess ? <CheckCircleOutlined/> : <CopyOutlined/>}
                  size={`large`}
                  onClick={verifyEmbedCodeInstallation}
                >
                  Check embed code installation
                </AntButton> }


              </ContentBox>
              <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                <ContentBoxHeader title={`SCPC Tracking code`}/>
                <p>
                  This is your tracking code, paste it into the &lt;head&gt; section of your
                  site. <br/>
                  If you don't know how to add this code, you can send it to your developer, or
                  contact us for assistance.
                </p>
                <div className="pre--code-box-holder">

                  <code className="pre--code-box">
                    {trackingCode}
                  </code>
                </div>
                <ContentBoxFooter>
                  <div type={`left`}>
                    <button className={`scpc--contentbox-needhelpbtn`} onClick={() => {
                      history.push('/account/support')
                    }}>
                      Need help?
                    </button>
                  </div>
                  <div type={`right`}>
                    <div className={`scpc--copy-button-handler`}>
                      {/*<ReactLottie options={copyActionOptions}*/}
                      {/*             height={400}*/}
                      {/*             width={400}/>*/}

                      <AntButton
                        type="primary"
                        shape="round"
                        icon={copySuccess ? <CheckCircleOutlined/> : <CopyOutlined/>}
                        size={`large`}
                        onClick={() => {
                          copyToClipBoard(trackingCode)
                        }}
                        ghost={copySuccess}
                      >
                        {copySuccess ? `Copied` : `Copy tracking code`}
                      </AntButton>
                    </div>

                  </div>
                </ContentBoxFooter>
              </ContentBox>

              <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
                <ContentBoxHeader title={`Tracking template verification`}/>
                <button className={`scpc--contentbox-needhelpbtn`} onClick={() => {
                  history.push('/account/support')
                }}>
                </button>
                <div>
                  <h6>I have troubles with my setup</h6>
                  <p>
                    Having trouble with your domain setup? You can't see any blocked clicks? Try to re-check Google Ads account connection.
                  </p>
                <AntButton
                  type="primary"
                  shape="round"
                  icon={copySuccess ? <CheckCircleOutlined/> : <CopyOutlined/>}
                  size={`large`}
                  onClick={reCheckAdWords}
                >
                  Re-check GoogleAds account connection
                </AntButton>
                </div>
                <br /><br />
                <div>

                  <h6>I have no invitation from SafeCPC or I removed an access</h6>
                  <p>
                   If you have removed an access for us (SafeCPC Manager) or never got one, please click the Re-send invitation button.
                  </p>
                  <AntButton
                    type="primary"
                    shape="round"
                    icon={copySuccess ? <CheckCircleOutlined/> : <CopyOutlined/>}
                    size={`large`}
                    onClick={reSendAdwordsInvitation}
                  >
                    Re-send invitation
                  </AntButton>
                </div>



              </ContentBox>
            </div>
          </div>
        </div>
      </RightContent>
    </>
  )
}