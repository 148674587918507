import React, {useState} from 'react';
import Scrollbar from 'react-scrollbars-custom';
import { Avatar, Tabs } from 'antd';
import {ContentBox, ContentBoxHeader} from '../box/ContentBox';
import HtmlParser from 'react-html-parser';
import { useForm } from 'react-hook-form';
import { Button } from '../../layout/Button';
import { Card } from 'antd';
import Meta from 'antd/es/card/Meta';
const { TabPane } = Tabs;

export const ContactUsInner = (props) => {
  // eslint-disable-next-line no-unused-vars
  const [size,setSize] = useState(`large`);
  const { handleSubmit, register, errors } = useForm();
  const onSubmit = data => {

  }
  return (
    <>
      <div>
        <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
          <Tabs defaultActiveKey="1" size={size} style={{ marginBottom: 32 }}>
            <TabPane tab="Customer help" key="1">
             <div className={`scpc--contact-inner`}>
               <div className={`container pl-0`}>
                 <div className={`row`}>
                   <div className={`col-6`}>
                     <Card title="Working hours" bordered={false} style={{ width: 300 }}>
                       <p>Mon-Fr : 08:00 - 20:00</p>
                     </Card>
                   </div>
                   <div className={`col-6`}>
                     <Card title="" bordered={false} style={{ width: 300 }}>
                       <Meta
                         avatar={
                           <Avatar src="https://safecpc.com/wp-content/uploads/2020/09/jaro-team.jpg" />
                         }
                         title="Jerry Skrzypczuk"
                         description="customers@scpc.com"
                       />
                     </Card>
                   </div>
                 </div>
               </div>
             </div>
            </TabPane>
            <TabPane tab="Invoices and Billings" key="2">
              <div className={`scpc--contact-inner`}>
                <div className={`container pl-0`}>
                  <div className={`row`}>
                    <div className={`col-6`}>
                      <Card title="Working hours" bordered={false} style={{ width: 300 }}>
                        <p>Mon-Fr : 08:00 - 16:00</p>
                      </Card>
                    </div>
                    <div className={`col-6`}>
                      <Card title="" bordered={false} style={{ width: 300 }}>
                        <Meta
                          avatar={
                            <Avatar src="https://safecpc.com/wp-content/uploads/2020/09/jerzy-team2.jpg" />
                          }
                          title="George Zielinski"
                          description="invoices@scpc.com"
                        />
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
            <TabPane tab="Technical support" key="3">
              <div className={`scpc--contact-inner`}>
                <div className={`container pl-0`}>
                  <div className={`row`}>
                    <div className={`col-6`}>
                      <Card title="Working hours" bordered={false} style={{ width: 300 }}>
                        <p>Mon-Fr : 08:00 - 16:00</p>
                      </Card>
                    </div>
                    <div className={`col-6`}>
                      <Card title="" bordered={false} style={{ width: 300 }}>
                        <Meta
                          avatar={
                            <Avatar src="https://safecpc.com/wp-content/uploads/2020/09/ja-team.jpg" />
                          }
                          title="Alex Miesak"
                          description="tech@scpc.com"
                        />
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </TabPane>
          </Tabs>
        </ContentBox>
        <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}>
          <ContentBoxHeader title={`Contact form`} />
          <form onSubmit={handleSubmit(onSubmit)}
                autoComplete={'off'}
                className={`scpc--myaccount-form ${props.standalone  ? 'scpc--myaccount-form-standalone' : ''}`}>
            <div className={`scpc--forms-input-holder ${errors.address_line_1 ? 'has-error' : ''}`}>
              <label>Your name</label>
              <input
                type={`text`}
                ref={register({
                  required: true
                })}
                className={`scpc--forms-input-text input-default input-full`}
              />
              {errors.address_line_1 && HtmlParser(
                "<small class='scpc--forms-input-error'>This field is required</small>")}
            </div>
            <div className={`scpc--forms-input-holder ${errors.email ? 'has-error' : ''}`}>
              <label>Your e-mail</label>
              <input
                type={`text`}
                ref={register({
                  required: true
                })}
                className={`scpc--forms-input-text input-default input-full`}
              />
              {errors.email && HtmlParser(
                "<small class='scpc--forms-input-error'>This field is required</small>")}
            </div>
            <div className={`scpc--forms-input-holder`} style={{'width':'100%'}}>
              <label>Your message</label>
              <textarea className={`scpc--forms-input-textarea input-default input-full`} />
            </div>
            <div className={`scpc--forms-input-holder scpc--forms-input-holder-submit`}>
              <Button
                icon={`linear-envelope-open`}
                color={`blue`}
                flat={true}
                rounded={`default`}
                onClick={() => {}}
              >
                Send Message
              </Button>
            </div>
          </form>


        </ContentBox>
      </div>
    </>
  );
}

export const ContactUs = (props) => {
  return (
  <>
    {props.type !== 'standalone' ?
      <>
        <Scrollbar disableTracksWidthCompensation
                   style={{ position: "", 'height': '100vh', 'padding': '40px' }}>
          <div style={{ 'width': '100%', 'padding': '20px' }}>
            <h1 className={`mt-15`}>Contact Us</h1>
            <div className={`scpc--card-element-stripe-info mb-50 mt-15`}>
              <i className={`linear-at-sign`}/>
              <p>
                <strong>We are here to help you!</strong><br />
               If you have any question, feel free to drop us a note. You can send your query via e-mail or through the form below.
              </p>
            </div>
            <ContactUsInner/>
          </div>
        </Scrollbar>
      </>
      :
      <>
        <div className={`scpc--card-element-stripe-info mb-40 mt-15`}>
          <i className={`linear-at-sign`}/>
          <p>
            <strong>We are here to help you!</strong><br />
            If you have any question, feel free to drop us a note. You can send your query via e-mail or through the form below.
          </p>
        </div>
        <ContactUsInner standalone={true}/>
      </>
    }
  </>
  )
};