import React from 'react';
import Firefox from 'simple-icons/icons/mozillafirefox';
import Chrome from 'simple-icons/icons/googlechrome';
import Opera from 'simple-icons/icons/opera';
import Safari from 'simple-icons/icons/safari';
import IE from 'simple-icons/icons/internetexplorer';

import Windows from 'simple-icons/icons/windows';
import Apple from 'simple-icons/icons/apple';
import Linux from 'simple-icons/icons/linux';
import parser from 'ua-parser-js';

export const UAIconLabelWrapper = (props) => {
  // const Div = styled.div`
  //   display:flex;
  //   flex-flow:row;
  //   align-items:center;`;
  return (
    <>
      {props.showLabel ?
        <>
          <div className={`ua-parser--holder`} style={{'display':'flex','flexFlow':'row','alignItems':'center'}}>
            {props.children}
            <span>{props.label}</span>
          </div>
        </>: props.children}
    </>
  )
}

export const UABrowser = (props) => {
  const browserIcon = (browserName) => {
    switch (browserName) {
      case 'Firefox':
        return Firefox;
      case 'Chrome':
        return Chrome;
      case 'Opera':
        return Opera;
      case 'Safari':
        return Safari;
      case 'IE':
        return IE;
      default:
        return {
          path: '',
          hex: ''
        }
    }
  }

  return (
    <>
      <UAIconLabelWrapper
        showLabel={props.showLabel}
        label={`${props.browser.name} ${props.browser.version}`}>

        <svg width={`30px`} height={`30px`}>
          {/*<Path iconpath={browserIcon(props.browser.name).path} fill={`#${browserIcon(props.browser.name).hex}`}></Path>*/}
          <path
            d={browserIcon(props.browser.name).path}
            fill={`#${browserIcon(props.browser.name).hex}`}
          />
        </svg>
      </UAIconLabelWrapper>
    </>
  )
}

export const UAOperatingSystem = (props) => {
  const osIcon = (osName) => {
    switch (osName) {
      case 'Windows':
        return Windows;
      case 'Mac OS':
        return Apple;
      case 'Linux':
        return Linux;
      default:
        return {
          path: '',
          hex: ''
        }
    }
  }
  return (
    <>
      <UAIconLabelWrapper
        showLabel={props.showLabel}
        label={`${props.os.name} ${props.os.version}`}>
        <svg width={`30px`} height={`30px`}>
          <path
            d={osIcon(props.os.name).path}
            fill={`#${osIcon(props.os.name).hex}`}
          />
        </svg>
      </UAIconLabelWrapper>
    </>
  );
}


export const UAParser = (props) => {
  // TODO: @alex restore, turned off to avoid a lot of warnings in console
  // return (<></>);
  let parser_instance = new parser();
  parser_instance.setUA(props.ua);
  let result = parser_instance.getResult();
  const isVisible = (what) => {
    if (props.show === 'all' || !props.show) {
      return true;
    } else if (props.show === what) {
      return true;
    }
    return false;
  }
  return (
    <>
      {isVisible('browser') ?
        <UABrowser browser={result.browser} showLabel={props.showLabel}/>
        : ''}
      {isVisible('os') ?
        <UAOperatingSystem os={result.os} showLabel={props.showLabel}/>
        : ''}
    </>
  )
}