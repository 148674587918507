import React from 'react';
import CardForm from '../../CF/Stripe/CardOption';
// import {loadStripe} from "@stripe/stripe-js";
// import {Elements} from "@stripe/react-stripe-js";
import Scrollbar from "react-scrollbars-custom";

export const Payments = (props) => {
//  const stripePromise = loadStripe("pk_test_6pRNASCoBOKtIshFeQd4XMUh");
  return (
    <>
      {props.type !== 'standalone' ? <>
        <Scrollbar disableTracksWidthCompensation style={{position: "", 'height': '100vh', 'padding': '40px'}}>
          <div style={{'width': '100%', 'padding': '20px'}}>
            <h1 className={`mt-10`}>Payments</h1>
            <CardForm/>
            {/*<Elements stripe={stripePromise}>*/}
            {/*  <CardForm/>*/}
            {/*</Elements>*/}
          </div>
        </Scrollbar>
      </> : <>
        <h1 className={`mt-10`}>Payments</h1>
        <CardForm/>
        {/*<Elements stripe={stripePromise}>*/}
        {/*  <CardForm/>*/}
        {/*</Elements>*/}
      </>}

    </>
  )
};