import React, {useContext, useState} from 'react';
import TimePeriodSelector from "./TimePeriodSelector";
import ProjectSettingsContext from "../../../context/CF/ProjectSettingsEditor/ProjectSettingsContext";

export const ClickRule = (props) => {
  const [maxClicks, setMaxClicks] = useState(props.clickRule.maxClicks);
  const projectSettingsContext = useContext(ProjectSettingsContext);

  const onMaxClicksChange = (e) => {
    setMaxClicks(e.target.value);
    projectSettingsContext.setClickRuleField(props.campaignGroupId, props.clickRule.id, 'max_clicks', e.target.value);
  };

  const onPeriodChange = (period) => {
    // console.log(`${period} seconds`);
    projectSettingsContext.setClickRuleField(props.campaignGroupId, props.clickRule.id, 'period', period);
  };

  let uiKey = `campaignGroups[${props.campaignGroupId}].rules.clickRules[${props.clickRule.id}]`;

  return (
    <ProjectSettingsContext.Consumer>
      {({addClickRule, deleteClickRule, setUiData, getUiData}) => (
        <>
          <div className={`scpc--trigger`}>
            <div className={`scpc--trigger-icon`}>
              <i className={`icon-0049-aim`}/>
            </div>
            <div className={`scpc--trigger-box`}>
              <strong>
                Trigger rule after
              </strong>
              <div className={`scpc--trigger-inputholder`}>
                <i className={`linear-mouse-left`}/>
                <input
                  type={`text`}
                  defaultValue={maxClicks}
                  onBlur={onMaxClicksChange}
                  className={`scpc--trigger-input`}
                />
              </div>
              <strong>
                ad Clicks
              </strong>
            </div>
            <div className={`scpc--trigger-within`}>in</div>
            <div className={`scpc--trigger-box`}>
              <div className={`scpc--controls-combobox`}>
                <div className={`scpc--controls-combobox-icon`}>
                  {/*<i className={currentTimeOption.icon}></i>*/}
                </div>
                <TimePeriodSelector
                  seconds={props.clickRule.period}
                  onChange={onPeriodChange}
                  uiSetData={(key, value) => setUiData(`${uiKey}[${key}]`, value)}
                  uiGetData={(key) => getUiData(`${uiKey}[${key}]`)}
                />
              </div>
            </div>
            <div className={`scpc--trigger-buttons`}>
              <button
                className={`scpc--trigger-button button-add`}
                onClick={() => {
                  // console.log(props.campaignGroupId);
                  addClickRule(props.campaignGroupId);
                }}
              >
                <i className={`linear-plus-circle`}/>
                <span>Add new rule</span>
              </button>
              {props.canBeDeleted &&
              <button
                className={`scpc--trigger-button button-remove`}
                onClick={() => deleteClickRule(props.campaignGroupId, props.clickRule.id)}
              >
                <i className={`linear-trash`}/>
                <span>Delete rule</span>
              </button>
              }
            </div>
          </div>
        </>
      )}
    </ProjectSettingsContext.Consumer>
  );
};