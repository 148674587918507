import React, {useState} from 'react';
import moment from 'moment';
import {Button} from '../../layout/Button';
import {DatePicker} from 'antd';
import {useComponentVisible} from '../../../hooks/useComponentVisible';

export const Calendar = (props) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);
  const [selectedDays, setSelectedDays] = useState(0);
  const {RangePicker} = DatePicker;

  const moveRangesToPanels = () => {
    setIsComponentVisible(true);
    /**
     * As we are accessing DOM element
     * we need to trigger this function as the last in the lifecycle order hence the timeout.
     */
    setTimeout(() => {
      let ranges = document.querySelector('.ant-picker-ranges');
      let rangesTargetLocation = document.querySelector('.ant-picker-panels');
      if (ranges && rangesTargetLocation) {
        rangesTargetLocation.insertBefore(ranges, rangesTargetLocation.firstChild);
      }
    }, 0);
  }
  const countDaysBetweenDates = (a, b) => {
    let start = moment(a);
    let end = moment(b);

    return Math.abs(start.diff(end, 'days', true)) + 1;
  }
  return (
    <>
      <div id={`scpc--calendar`} ref={ref}>
        <RangePicker
          open={isComponentVisible}

          ranges={{
            'Today': [
              moment(), moment()
            ],
            'Yesterday': [
              moment().subtract(1, 'day'),
              moment().subtract(1, 'day')
            ],
            'Last 3 days': [
              moment().subtract(3, 'day'),
              moment().subtract(1, 'day')
            ],
            'This Week': [
              moment().startOf('week'), moment()
            ],
            'Last Week': [
              moment().subtract(1, 'weeks').startOf('week'),
              moment().subtract(1, 'weeks').endOf('week')
            ],
            'This Month': [
              moment().startOf('month'),
              moment()
            ],
            'Last Month': [
              moment().subtract(1, 'months').startOf('month'),
              moment().subtract(1, 'months').endOf('month')
            ],
            'Last 2 Months': [
              moment().subtract(2, 'months').startOf('month'),
              moment().subtract(1, 'months').endOf('month')
            ]
          }}
          onCalendarChange={(dates, dateStrings) => {
            let daysDiff = countDaysBetweenDates(dateStrings[0], dateStrings[1]);
            setSelectedDays(isNaN(daysDiff) ? 0 : daysDiff);
            return false;
          }}
          size={'large'}
          getPopupContainer={() => document.getElementById('scpc--calendar')}
          onDropdownVisibleChange={() => console.log('open')}
          onOpenChange={() => moveRangesToPanels()}
          value={[props.startDate, props.endDate]}
          disabledDate={d => !d || d.isAfter(moment())}
          onChange={(rangeArray) => {
            if (rangeArray) {
              props.setStartDate(rangeArray[0]);
              props.setEndDate(rangeArray[1]);
            }
          }}
          renderExtraFooter={() => {
            return (
              <>
                <div className={`scpc--calendar-footer`}>
                  <div className={`scpc--calendar-days-count`}>
                    <strong>Selected days: {selectedDays}</strong>
                  </div>
                  <div className={`scpc--calendar-footer-buttons`}>
                    <Button
                      rounded={'max'}
                      color={'blue'}
                      border={true}
                      size={`small`}
                      onClick={() => {
                        setIsComponentVisible(false)
                      }}
                    >Close</Button>
                    <Button
                      rounded={'max'}
                      color={'blue'}
                      border={false}
                      flat={true}
                      size={`small`}
                      onClick={() => {
                        props.onCalendarSave();
                        setIsComponentVisible(false)
                      }}
                    >Save</Button>
                  </div>
                </div>
              </>
            )
          }}
        />
      </div>
    </>
  )
}