import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Button } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import activeMenu from '../../../svg/active-menu.svg';
import { LeftNavSubmenu } from './leftNavSubmenu';

export const MobileNavBoxElem = ({
                                   additionalClass,
                                   routeName,
                                   elem,
                                   setActiveMenu,
                                   setCurrentMobileNav,
                                   mobileNavSubmenuToggle
                                 }) => {
  let location = useLocation();
  let history = useHistory();
  const handleMobileNavClick = (e, link, submenu, routeName, elem) => {
    if (link !== '#') {
      history.push(link);
    }
    mobileNavSubmenuToggle(elem.id);
    setCurrentMobileNav(routeName);
  }
  return (
    <>
      <li
        key={elem.id}
        className={`scpc--box${setActiveMenu(routeName,
          location.pathname) ? 'scpc--nav-left-active' : ''} ${elem.mobileSubmenuActive ? `active-mobile` : ``} ${additionalClass}`}>
        <div className={`scpc--mobilenav-big-clickholder`}>
          <div className="scpc--nav-left-menuitem-holder"
               onClick={(e) => handleMobileNavClick(e,
                 elem.link,
                 elem.submenu,
                 elem.routeName,
                 elem)}>
            <div className="scpc--box_icon">
              <i className={elem.icon}/>
            </div>
            <div className="scpc--box--title">
              <span>{elem.name.replace('<br />', ' ')}</span>
            </div>
            {setActiveMenu(routeName, location.pathname) ?
              <img src={activeMenu} alt={`active menu`}/> : ''}
            {elem.submenu ? <div className="icon-chevron-right"/> : ''}
            {elem.submenu ? <LeftNavSubmenu submenuItems={elem.submenuItems} isMobile={true}
                                            setCurrentMobileNav={setCurrentMobileNav}/> : ''}
          </div>
        </div>
      </li>
    </>
  );
}

export const MobileNav = (props) => {
  // let location = useLocation();
  const closeCurrentSubmenu = (id) => {
    props.mobileNavSubmenuToggle(id);
  }
  const renderSubmenuHeader = () => {
    let activeSubmenu = props.navElements.filter((el) => el.mobileSubmenuActive);
    if (activeSubmenu && activeSubmenu.length) {
      let name = activeSubmenu ? activeSubmenu[0].name : null;
      let id = activeSubmenu ? activeSubmenu[0].id : null;
      return (
        <>
          <Button type="primary" shape="round" icon={<LeftOutlined/>} size={`medium`}
                  onClick={() => closeCurrentSubmenu(id)}/>
          <h3>
            {name.replace('<br />', ' ')}
          </h3>
        </>
      )
    }
  }
  return (
    <>
      <div className={`scpc--mobilenav-big-holder ${props.isActive ? 'is-active' : ''}`}>
        <div className={`scpc--mobilenav-big-content`}>
          <div className={`scpc--mobilenav-big-header`}>
            {renderSubmenuHeader()}
          </div>
          <ul>
            {props.navElements.filter((el) => !el.mobileOnly).map((elem) => (
              <MobileNavBoxElem
                elem={elem}
                setActiveMenu={props.setActiveMenu}
                setCurrentMobileNav={props.setCurrentMobileNav}
                mobileNavSubmenuToggle={props.mobileNavSubmenuToggle}
                closeCurrentSubmenu={closeCurrentSubmenu}
                key={elem.id}
              />
            ))}
          </ul>
        </div>
      </div>
    </>
  );
}