import React, { useState } from 'react';
import howAwesome from '../../../svg/howawesome.svg';
import { Modal, InputNumber } from 'antd';

export const SavedWithUs = (props) => {

  const [currentCPC, setCurrentCPC] = useState('11');
  // eslint-disable-next-line no-unused-vars
  const [fraudClicksRatio, setFraudClicksRatio] = useState(0.81);
  // eslint-disable-next-line no-unused-vars
  const [fraudSuccessRatio, setFraudSuccessRatio] = useState(0.94);
  // eslint-disable-next-line no-unused-vars
  const [clicksCount, setClicksCount] = useState(150);
  const [toggleCpcModal, setToggleCpcModal] = useState(false);

  let currentFraudCount = clicksCount * fraudClicksRatio;
  let successfullFraudBlock = currentFraudCount * fraudSuccessRatio;

  const formatMoney = (amount, decimalCount = 2, decimal = ".", thousands = ",") => {
    try {
      decimalCount = Math.abs(decimalCount);
      decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

      const negativeSign = amount < 0 ? "-" : "";

      let i = parseInt(amount = Math.abs(Number(amount) || 0).toFixed(decimalCount)).toString();
      let j = (i.length > 3) ? i.length % 3 : 0;

      return negativeSign + (j ? i.substr(0, j) + thousands : '') + i.substr(j).replace(
        /(\d{3})(?=\d)/g,
        "$1" + thousands) + (decimalCount ? decimal + Math.abs(amount - i)
      .toFixed(decimalCount)
      .slice(2) : "");
    }
    catch (e) {
     // console.log(e)
    }
  };

  const [savingValue, setSavingValue] = useState(formatMoney(Math.round(successfullFraudBlock * currentCPC)));

  const countCurrentSavings = () => {
    let currentFraudCount = clicksCount * fraudClicksRatio;
    let successfullFraudBlock = currentFraudCount * fraudSuccessRatio;
    setSavingValue(formatMoney(Math.round(successfullFraudBlock * currentCPC)));
  }
  const handleOk = () => {
    setToggleCpcModal(false);
    countCurrentSavings();
  }
  const handleCancel = () => {
    setToggleCpcModal(false);
  }

  return (
    <>
      <Modal
        title="Set your current avg. CPC spend"
        visible={toggleCpcModal}
        onOk={handleOk}
        onCancel={handleCancel}
        size={`large`}
      >
        <InputNumber
          defaultValue={currentCPC}
          formatter={value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
          parser={value => value.replace(/\$\s?|(,*)/g, '')}
          onChange={(value) => {
            setCurrentCPC(value)
          }}
          placeholder="Current CPC"/>
      </Modal>
      <div className="scpc--right-nav-savings">
        <div className="scpc--right-nav-settings-title">
          <h4>Look, how awesome you are</h4>
        </div>
        <div className="scpc--right-nav-savings-box">
          <div className="scpc--right-nav-savings-box-image">
            <img src={howAwesome} alt={`how awesome you are`}/>
          </div>
          <div className="scpc--right-nav-savings-box-content">
            <p>You saved with us:</p>
            <h4>${savingValue}</h4>
          </div>
        </div>
        <div className="scpc--right-nav-savings-box-options">
          <button className="scpc--inline-link" onClick={() => {
            setToggleCpcModal(true)
          }}>change avg. cpc
          </button>
          <span>
            ${currentCPC} - current CPC
          </span>
        </div>
      </div>
    </>
  )
}
