import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import {useHistory} from "react-router-dom";
// import {Breadcrumb} from 'antd';
// import {HomeOutlined} from '@ant-design/icons';
import ProjectContext from '../../../context/CF/ProjectContext/ProjectContext';

export const LeftNavSubmenu = ({isMobile, submenuItems}) => {
  const history = useHistory();
  // const buttonClick = (button, projectId) => {
  //   // history.push(button.projectBasedLink ? button.link.toString().replace('{id}',projectId) : button.link);
  // }

  return (
    <>
      <ProjectContext.Consumer>
        {({projectId}) => (
          <div className="scpc--nav-left-menu-submenu">
            <ul>
              {submenuItems.map((button) => (
                <li key={button.id} className={`${button.active ? 'active' : ''} 
            ${button.active && isMobile ? 'active-mobile' : ''}`}>
                  <button onClick={(e) => {
                    history.push(button.link);
                    return false;
                  }} className={button.blocked ? 'scpc--nav-left-menu-submenu-blocked' : ''}>
                    {isMobile ? button.name.split("&nbsp;")
                    .join(" ") : ReactHtmlParser(button.name)}
                  </button>
                </li>
              ))
              }
            </ul>
            <div className="scpc--nav-left-breadcrumbs">
              {/*<Breadcrumb>*/}
              {/*  <Breadcrumb.Item>*/}
              {/*    <HomeOutlined/>*/}
              {/*  </Breadcrumb.Item>*/}
              {/*  <Breadcrumb.Item>Dashboard</Breadcrumb.Item>*/}
              {/*</Breadcrumb>*/}
            </div>
          </div>
        )}
      </ProjectContext.Consumer>
    </>
  );
};