import React, {useContext, useState} from 'react';
import TimePeriodSelector from "./TimePeriodSelector";
import ProjectSettingsContext from "../../../context/CF/ProjectSettingsEditor/ProjectSettingsContext";

export const PauseRule = (props) => {
  const [maxFrauds, setMaxFrauds] = useState(props.pauseRule.maxFrauds);
  const projectSettingsContext = useContext(ProjectSettingsContext);

  const onMaxFraudChange = (e) => {
    setMaxFrauds(e.target.value);
    // console.log(props.campaignGroupId, props.pauseRule.id, 'max_frauds', e.target.value);
    projectSettingsContext.setPauseRuleField(props.campaignGroupId, props.pauseRule.id, 'max_frauds', e.target.value);
  };

  const onPeriodChange = (period) => {
    // console.log(`${period} seconds`);
    projectSettingsContext.setPauseRuleField(props.campaignGroupId, props.pauseRule.id, 'period', period);
  };

  let uiKey = `campaignGroups[${props.campaignGroupId}].rules.pauseRules[${props.pauseRule.id}]`;

  return (
    <ProjectSettingsContext.Consumer>
      {({setUiData, getUiData}) => (
        <>
          <div className={`scpc--trigger pause-settings ${props.isOn ? 'active' : ''}`}>
            <div className={`scpc--trigger-icon`}>
              <i className={`linear-pause-circle`}/>
            </div>
            <div className={`scpc--trigger-box`}>
              <strong>
                Trigger rule after
              </strong>
              <div className={`scpc--trigger-inputholder`}>
                <i className={`linear-mouse-left`}/>
                <input
                  type={`text`}
                  defaultValue={maxFrauds}
                  onBlur={onMaxFraudChange}
                  className={`scpc--trigger-input`}
                />
              </div>
              <strong>
                Frauds
              </strong>
            </div>
            <div className={`scpc--trigger-within`}>in</div>
            <div className={`scpc--trigger-box`}>
              <div className={`scpc--controls-combobox`}>
                <div className={`scpc--controls-combobox-icon`}>
                  {/*<i className={currentTimeOption.icon}></i>*/}
                </div>
                <TimePeriodSelector
                  seconds={props.pauseRule.period}
                  onChange={onPeriodChange}
                  uiSetData={(key, value) => setUiData(`${uiKey}[${key}]`, value)}
                  uiGetData={(key) => getUiData(`${uiKey}[${key}]`)}
                />
              </div>
            </div>
            <div className={`scpc--trigger-buttons`}>
            </div>
          </div>
        </>
      )}
    </ProjectSettingsContext.Consumer>
  );
};