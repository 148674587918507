import React, {useContext} from 'react';
import _ from "lodash";
import {ClickRule} from "./ClickRule";
import {PauseRule} from "./PauseRule";
import TimePeriodSelector from "./TimePeriodSelector";
import {Select, Switch, Tag} from 'antd';
import ProjectSettingsContext from "../../../context/CF/ProjectSettingsEditor/ProjectSettingsContext";
import {ContentBox} from "../box/ContentBox";
import ReactFlagsSelect from "react-flags-select";
import * as CountryNames from "react-flags-select/lib/countries";
import {StarOutlined} from '@ant-design/icons';

const {Option} = Select;

export const CampaignGroup = (props) => {
  const {campaignGroup} = props;

  const projectSettingsContext = useContext(ProjectSettingsContext);

  const getIsAccordionOpen = () => {
    return projectSettingsContext.getUiData(
      `campaignGroups[${campaignGroup.id}].isAccordionOpened`,
      true
    );
  }

  const toggleAccordion = () => {
    projectSettingsContext.setUiData(
      `campaignGroups[${campaignGroup.id}].isAccordionOpened`,
      !getIsAccordionOpen()
    );
  }

  const getCampaignList = () => {
    return _.filter(
      projectSettingsContext.getCampaigns(),
      (campaign) => projectSettingsContext.isCampaignInGroup(campaignGroup.id, campaign.id)
    );
  }

  const getPausedGroupLabelFromDetails = (campaignGroup) => {
    const details = campaignGroup.pauseDetails;
    if (details) {
      if (details.manualPause) {
        // Alex: removed at ${details.pausedAt} because it shown previous pause time.
        return `Paused by the user`;
      } else {
        return `Paused by the system, at ${details.pausedAt} and will be unpaused in ${secondsToString(details.pauseTime)}`;
      }
    } else {
      return "Paused by the user";
    }
  }

  const secondsToString = (seconds) => {
    let numdays, numhours, nummilli, numminutes, numseconds, numyears, res;
    numyears = Math.floor(seconds / 31536000);
    numdays = Math.floor(seconds % 31536000 / 86400);
    numhours = Math.floor(seconds % 31536000 % 86400 / 3600);
    numminutes = Math.floor(seconds % 31536000 % 86400 % 3600 / 60);
    numseconds = seconds % 31536000 % 86400 % 3600 % 60;
    nummilli = seconds % 1.0;
    res = [];
    if (numyears > 0) {
      res.push(numyears + " year(s)");
    }
    if (numdays > 0) {
      res.push(numdays + " day(s)");
    }
    if (numhours > 0) {
      res.push(numhours + " hour(s)");
    }
    if (numminutes > 0) {
      res.push(numminutes + " minute(s)");
    }
    if (numseconds > 0) {
      res.push(numseconds + " second(s)");
    }
    if (nummilli > 0) {
      res.push(nummilli + " millisecond(s)");
    }
    return [res.slice(0, -1).join(", "), res.slice(-1)[0]].join(res.length > 1 ? " and " : "");
  }


  return (
    <ProjectSettingsContext.Consumer>
      {({
          deleteGroup,
          addCampaignToGroup,
          removeCampaignFromGroup,
          getCampaignsNotInAnyGroup,
          setCampaignGroupName,
          setCampaignGroupField,
          setCampaignGroupRuleField,
          addCampaignGroupGeotargetingListItem,
          removeCampaignGroupGeotargetingListItem,
        }) => (
        <div
          className={`scpc--accordion-holder ${getIsAccordionOpen() ? 'active' : ''} ${props.standardMode ? 'scpc--standard-mode-holder' : ''}`}>
          {!props.standardMode ?
            <div className={`scpc--accordion-header ${getIsAccordionOpen() ? 'active' : ''}`}>
              <div className={`scpc--accordion-header-active-background`}/>
              <div className={`scpc--accordion-header-holder`}>

                <div className={`scpc--accordion-header-groupname`}>
                <span>
                  <i className={`linear-folder`}/>
                  Group name: <strong>{campaignGroup.name}</strong>
                </span>
                  {/*<span>Campaigns: <strong>{group.campaigns}</strong></span>*/}
                </div>
                <div className={`scpc--accordion-header-buttons`}>
                  {props.canBeDeleted &&
                  <>
                    <button
                      onClick={() => deleteGroup(campaignGroup.id)}
                      className={`scpc--accordion-deleteGroup`}
                    >
                      <i className={`linear-trash`}/>
                    </button>
                  </>
                  }
                  <button
                    className={`scpc--accordion-toggle ${getIsAccordionOpen() ? 'active' : ''}`}
                    onClick={() => toggleAccordion()}>
                    <i className={`linear-chevron-down `}/>
                  </button>
                </div>
              </div>
            </div>
            : ''}
          <div className={`scpc--accordion-body ${getIsAccordionOpen() ? 'active' : ''}`}>
            {!props.standardMode ?
              <h3>
                <i className={`linear-cog2`}/>
                Campaign group settings
              </h3> : ''}
            {!props.standardMode ?
              <div className={`scpc--accordion-body-groupSettings`}>
                <div className={`scpc--accordion-body-groupSettings-name`}>
                  <div className={`scpc--forms-input-holder`}>
                    <strong>Name:</strong>
                    <input
                      type={`text`}
                      defaultValue={campaignGroup.name}
                      className={`scpc--forms-input-text input-default`}
                      onBlur={(e) => setCampaignGroupName(campaignGroup.id, e.target.value)}
                    />

                  </div>
                </div>
                <div className={`scpc--accordion-body-groupSettings-campaigns`}>
                  <strong>Add campaign to this group:</strong>
                  <Select
                    showSearch
                    style={{width: '100%'}}
                    placeholder="Select campaign"
                    optionFilterProp="children"
                    onChange={(campaignId) => addCampaignToGroup(campaignGroup.id, campaignId)}
                  >
                    {_.map(getCampaignsNotInAnyGroup(), (campaign) =>
                      (
                        <Option
                          value={campaign.id}
                          groupid={campaignGroup.id}
                          key={campaign.id}
                        >
                          {campaign.name} ({campaign.adwordsCampaignId})
                        </Option>
                      )
                    )}
                  </Select>
                </div>
                <div className={`scpc--accordion-body-groupSettings-selectedCampaigns`}>
                  <strong>Selected campaigns:</strong>
                  <div className={`scpc--accordion-body-groupSettings-selectedHolder`}>
                    {
                      _.map(getCampaignList(), (campaign) => {
                        return (
                          <Tag
                            closable
                            color="blue"
                            key={campaign.id}
                            onClose={() => removeCampaignFromGroup(campaignGroup.id, campaign.id)}
                          >
                            {campaign.name} ({campaign.adwordsCampaignId})
                          </Tag>
                        )
                      })
                    }
                  </div>
                </div>
              </div>
              : ''}
            <div className="scpc--contentbox-content">
              <h3>
                <i className={`icon-0049-aim`}/>
                <span>Triggers</span>
              </h3>
              <div className="scpc--contentbox-content-triggers">
                {_.map(
                  campaignGroup.rules.clickRules,
                  clickRule => {
                    return (
                      <ClickRule
                        key={clickRule.id}
                        clickRule={clickRule}
                        campaignGroupId={campaignGroup.id}
                        canBeDeleted={_.size(campaignGroup.rules.clickRules) > 1}
                      />
                    )
                  })}
              </div>
            </div>
            <div className="scpc--contentbox-content mt-30">
              <h3>
                <i className={`linear-power`}/>
                <span>Actions</span>
              </h3>
              <div className="scpc--contentbox-content-triggers">


                <div className={`scpc--action`}>
                  <div className={`scpc--action-icon`}>
                    <i className={`linear-cross-square`}/>
                  </div>
                  <div className={`scpc--action-box banbox`}>
                    <div className={`scpc--action-inputholder`}>
                      <div className={`scpc--controls-banbox`}>
                        {/*<i className={`linear-cross-square`}/>*/}
                        <span>Ban <strong>IP</strong> & <strong>Device</strong></span>
                      </div>
                    </div>
                  </div>
                  <div className={`scpc--action-within`}>for</div>
                  <div className={`scpc--action-box`}>
                    <TimePeriodSelector
                      uiKey={`campaignGroups[${props.campaignGroup.id}].rules.banTime]`}
                      seconds={campaignGroup.rules.banTime}
                      onChange={(value) => setCampaignGroupRuleField(campaignGroup.id, 'banTime', value)}
                    />
                  </div>
                </div>


              </div>
            </div>
            <div className={`scpc--contentbox-content`}>
              <h3 className={`mb-20`}>
                <i className={`linear-pause-circle`}/>
                <span className={`mr-15`}>Pause if under attack</span>
                <Tag color="#2db7f5" icon={<StarOutlined/>}> Premium</Tag>
              </h3>
              <p>
                We've got you covered; you can set automatic campaign pause when we notice any fraudulent clicks. This
                way, you are protected not only by blocking the attacker but also by disabling the target of attack.
              </p>

              <div
                className={`scpc--contentbox-switcherrow mt-20 ${campaignGroup.rules.pauseUnderAttack ? '' : 'mb-20'}`}>
                <Switch
                  className={`scpc--controls-switch`}
                  onChange={(value) => setCampaignGroupRuleField(campaignGroup.id, 'pauseUnderAttack', value)}
                  defaultChecked={campaignGroup.rules.pauseUnderAttack}
                />
                <span>Pause campaigns when under attack</span>

              </div>


              <div
                className={`scpc--contentbox-content-triggers pause-settings ${campaignGroup.rules.pauseUnderAttack ? 'active' : ''}`}>
                {_.map(
                  campaignGroup.rules.pauseRules,
                  pauseRule => {
                    return (
                      <PauseRule
                        key={pauseRule.id}
                        pauseRule={pauseRule}
                        campaignGroupId={campaignGroup.id}
                        canBeDeleted={false}
                        isOn={campaignGroup.rules.pauseUnderAttack}
                      />
                    )
                  })}
              </div>
              <div className={`scpc--action pause-settings ${campaignGroup.rules.pauseUnderAttack ? 'active' : ''}`}>
                <div className={`scpc--action-icon`}>
                  <i className={`linear-cross-square`}/>
                </div>
                <div className={`scpc--action-box banbox`}>
                  <div className={`scpc--action-inputholder`}>
                    <div className={`scpc--controls-banbox`}>
                      {/*<i className={`linear-cross-square`}/>*/}
                      <span> <strong>Pause</strong> all campaigns and <strong>resume after</strong></span>
                    </div>
                  </div>
                </div>
                <div className={`scpc--action-within`}>&nbsp;</div>
                <div className={`scpc--action-box`}>
                  <TimePeriodSelector
                    uiKey={`campaignGroups[${props.campaignGroup.id}].rules.pauseTime]`}
                    seconds={campaignGroup.rules.pauseTime}
                    onChange={(value) => setCampaignGroupRuleField(campaignGroup.id, 'pauseTime', value)}
                  />
                </div>
              </div>
            </div>
            <div style={{'borderTop': '1px solid #f0f0f0', 'borderBottom': '1px solid #f0f0f0'}}>
              <ContentBox>
                <div className="scpc--contentbox-content mt-30">
                  <h3>
                    <i className={`linear-map-marker`}/>
                    <span>Block clicks based on country</span>
                  </h3>
                </div>
                <div className={`scpc--contentbox-body mt-40`}>
                  <div className={`scpc--contentbox-body-left`}>
                    <div className={`scpc--contentbox-switcherrow mb-20`}>
                      <Switch
                        className={`scpc--controls-switch`}
                        onChange={(value) => setCampaignGroupRuleField(campaignGroup.id, 'geotargeting', value)}
                        defaultChecked={campaignGroup.rules.geotargeting}
                      />
                      <span>Block or allow clicks coming from IPs from the following countries:</span>
                    </div>

                    {/* TODO: @alex #74 maybe gray-off the following elements if geotargeting is off? */}
                    {/*campaignGroup.rules.geotargeting indicates if it's on or off for this campaign group*/}

                    <div className={`scpc--contentbox-switcherrow mb-20`}>
                      <Switch
                        className={`scpc--controls-switch`}
                        onChange={(value) => setCampaignGroupRuleField(campaignGroup.id, 'geotargetingOnlyAllow', !value)}
                        checked={!campaignGroup.rules.geotargetingOnlyAllow}
                      />
                      <span><strong>Block</strong> any clicks from the following countries</span>
                    </div>
                    <div className={`scpc--contentbox-switcherrow mb-20`}>
                      <Switch
                        className={`scpc--controls-switch`}
                        onChange={(value) => setCampaignGroupRuleField(campaignGroup.id, 'geotargetingOnlyAllow', value)}
                        checked={campaignGroup.rules.geotargetingOnlyAllow}
                      />
                      <span>Only <strong>allow</strong> clicks coming from the following countries  </span>
                    </div>
                    <div className={`scpc--contentbox-switcherrow mb-20`}>
                      <Switch
                        className={`scpc--controls-switch`}
                        onChange={(value) => setCampaignGroupField(campaignGroup.id, 'paused', value)}
                        checked={campaignGroup.paused}
                      />
                      <span>
                        {campaignGroup.paused ? getPausedGroupLabelFromDetails(campaignGroup) : 'Activate to pause campaigns in group'}</span>
                    </div>
                  </div>
                  <div className={`scpc--contentbox-body-right`}>
                    <ReactFlagsSelect
                      searchable={true}
                      searchPlaceholder="Search for a country"
                      onSelect={(country) => addCampaignGroupGeotargetingListItem(campaignGroup.id, country)}
                      countries={campaignGroup.rules.geotargetingList}
                      blackList={true}
                    />
                    <div className={`scpc--accordion-body-groupSettings-selectedCampaigns countries`}>
                      <div className={`scpc--accordion-body-groupSettings-selectedHolder`}>
                        {
                          _.map(campaignGroup.rules.geotargetingList, (country) => {
                            return (
                              <Tag
                                closable
                                color="blue"
                                key={country}
                                onClose={() => removeCampaignGroupGeotargetingListItem(campaignGroup.id, country)}
                              >
                                {CountryNames[country]}
                              </Tag>
                            )
                          })
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </ContentBox>
            </div>
          </div>
        </div>
      )}
    </ProjectSettingsContext.Consumer>
  );
};
