import auth0 from 'auth0-js';
import {apiGET} from "./api/api";

class Auth {
  constructor() {
    this.auth0 = new auth0.WebAuth({
      // the following three lines MUST be updated
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI,
      responseType: 'id_token',
      //responseMode: 'query',
      scope: 'openid profile email',
      leeway: 10
    });

    this.getProfile = this.getProfile.bind(this);
    this.getUser = this.getUser.bind(this);
    this.getUserId = this.getUserId.bind(this);
    this.setUser = this.setUser.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);
    this.isAuthenticated = this.isAuthenticated.bind(this);
    this.signIn = this.signIn.bind(this);
    this.signOut = this.signOut.bind(this);
  }

  setUser(user) {
    this.profile.user = user;
    // make sure we're dealing with numeric ids
    this.profile.user.id = parseInt(this.profile.user.id);
  }

  getUserId() {
    if (this.profile.user) {
      return this.profile.user.id;
    }
    return null;
  }

  getProfile() {
    return this.profile;
  }

  getUser() {
    return this.profile.user || null;
  }

  getIdToken() {
    return this.idToken;
  }

  isAuthenticated() {
    return new Date().getTime() < this.expiresAt;
  }

  signIn() {
    this.auth0.authorize();
  }

  async handleAuthentication() {
    return new Promise((resolve, reject) => {
      this.auth0.parseHash((err, authResult) => {
        if (err) return reject(err);
        if (!authResult || !authResult.idToken) {
          return reject(err);
        }
        this.setSession(authResult);
        apiGET('/core/user').then(res => {
          this.profile.user = res.user;
          resolve();
        });
      });
    })
  }

  setSession(authResult) {
    this.idToken = authResult.idToken;
    console.log(this.idToken);
    this.profile = authResult.idTokenPayload;
    // set the time that the id token will expire at
    this.expiresAt = authResult.idTokenPayload.exp * 1000;
  }

  signOut() {
    this.auth0.logout({
      returnTo: process.env.REACT_APP_AUTH0_SIGN_OUT_URI,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID
    });
  }

  silentAuth() {
    return new Promise((resolve, reject) => {
      this.auth0.checkSession({}, (err, authResult) => {
        if (err) return reject(err);
        this.setSession(authResult);
       // console.log(authResult.idToken);
        // fetch user details from API and make sure the user actually exists
        // if not, the error handler will redirect to registration process
        apiGET('/core/user').then(res => {
          this.profile.user = res.user;
          resolve();
        });
      });
    });
  }
}

const auth0Client = new Auth();

export default auth0Client;