import React, {useState} from "react";
import _ from "lodash";
import {useComponentVisible} from '../../../hooks/useComponentVisible';

const TimePeriodSelector = (props) => {
  const {
    ref,
    isComponentVisible,
    setIsComponentVisible
  } = useComponentVisible(false);
  const units = {
    seconds: {

      icon: 'fal fa-hourglass-start',
      name: 'seconds',
      seconds: 1
    },
    minutes: {
      icon: 'fal fa-hourglass-half',
      name: 'minutes',
      seconds: 60,
    },
    hours: {
      icon: 'fal fa-hourglass-end',
      name: 'hours',
      seconds: 60 * 60,
    },
    days: {
      icon: 'fal fa-calendar-day',
      name: 'days',
      seconds: 60 * 60 * 24
    },
    weeks: {
      icon: 'fal fa-calendar-week',
      name: 'weeks',
      seconds: 60 * 60 * 24 * 7
    },
    months: {
      icon: 'fal fa-calendar-alt',
      name: 'months',
      seconds: 60 * 60 * 24 * 30
    }
  };

  const getInitialUnitAndValue = (seconds) => {
    let unit;
    if (props.uiGetData) {
      unit = props.uiGetData('unit');
    }

    if (!unit) {
      // what's the max value before we switch to the next unit
      // e.g. 90 seconds will remain seconds, but 200 will be converted
      // to minutes
      let nextUnitThreshold = 1.9;

      if (seconds <= nextUnitThreshold * units.minutes.seconds) {
        unit = units.seconds;
      } else if (seconds <= nextUnitThreshold * units.hours.seconds) {
        unit = units.minutes;
      } else if (seconds <= nextUnitThreshold * units.days.seconds) {
        unit = units.hours;
      } else if (seconds <= nextUnitThreshold * units.weeks.seconds) {
        unit = units.days;
      } else if (seconds <= nextUnitThreshold * units.months.seconds) {
        unit = units.weeks;
      } else {
        unit = units.months;
      }
    }

    return {
      initialValue: Math.round(seconds / unit.seconds),
      initialUnit: unit
    };
  }

  let {initialUnit, initialValue} = getInitialUnitAndValue(props.seconds);
  // const [isSelectorOpen, setIsSelectorOpen] = useState(false);
  const [selectedUnit, setSelectedUnit] = useState(initialUnit);
  const [value, setValue] = useState(initialValue);

  /**
   * Parse input to ensure it's a non-negative int
   * @param value
   * @returns {number}
   */
  const parseValue = (value) => {
    if (isNaN(value)) {
      // if string or something else was entered, 60 seconds or 1 other-unit is the default
      if (selectedUnit.seconds === 1) {
        value = 60;
      } else {
        value = 1;
      }
    }

    return Math.abs(value);
  }

  const onValueChange = (e) => {
    let newValue = parseValue(e.target.value);
    e.target.value = newValue;
    setValue(newValue);
    props.onChange(newValue * selectedUnit.seconds)
  };

  const onTimeUnitChange = (newUnit) => {
    setSelectedUnit(newUnit);
    props.onChange(value * newUnit.seconds);
    if (props.uiSetData) {
      props.uiSetData('unit', newUnit);
    }
  };

  return (
    <>
      <div className={`scpc--controls-combobox`}>
        <div className="scpc--controls-combobox-icon">
          <i className={`${selectedUnit.icon}`} />
        </div>
        <div className={`scpc--controls-combobox-textinput`}>
          <input
            type={`text`}
            name={`time.number`}
            defaultValue={value}
            onBlur={onValueChange}
          />
        </div>

        <div
          className={`scpc--controls-dropdown has-icon`}
          onClick={(e) => setIsComponentVisible(true)}
        >
          <div className={`scpc--controls-dropdown-current`}>
            <span>{selectedUnit.name}</span>
          </div>
          <div className={`scpc--controls-dropdown-list ${isComponentVisible ? ' active' : ''}`} ref={ref}>
            <ul>
              {_.map(units, (unit) => {
                return (
                  <li
                    key={unit.name}
                    className={unit.id === selectedUnit.id ? 'activeElem' : ''}
                    onClick={(e) => {
                      e.stopPropagation();
                      onTimeUnitChange(unit);
                      setIsComponentVisible(false);
                    }}
                  >
                    <div className={`scpc--controls-dropdown-elem-wrapper`}>
                      <div className={`scpc--controls-dropdown-elem`}>
                        <i className={unit.icon}/>
                        <span>{unit.name}</span>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          </div>
          <div className={`scpc--controls-dropdown-toggle`}>
            <button
              className={`scpc--controls-dropdown-toggleDropdown`}
              onClick={(e) => {
                setIsComponentVisible(true);
              }}
            >
              <i className={`linear-chevron-down`}/>
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default TimePeriodSelector;