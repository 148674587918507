import React, {useContext, useEffect, useState} from 'react';
import {LeftMainBoxes} from "../appLayout/LeftMainBoxes";
import {RightContent} from "../appLayout/RightContent";
import {ContentBox, ContentBoxFooter, ContentBoxHeader} from "../box/ContentBox";
import {StandardMode} from "./StandardMode";
import {ExpertMode} from "./ExpertMode";
import {Button, Input, notification, Switch} from 'antd';
import {CheckCircleOutlined, PlusCircleOutlined, ReloadOutlined, SaveOutlined} from '@ant-design/icons';

import {useHistory, useParams} from "react-router-dom";
import 'antd/dist/antd.css';
import ProjectContext from "../../../context/CF/ProjectContext/ProjectContext";
import ProjectSettingsContext from "../../../context/CF/ProjectSettingsEditor/ProjectSettingsContext";
import {PROJECT_GROUP_MODE_ADVANCED, PROJECT_GROUP_MODE_SIMPLE} from "../../../context/types";
import 'react-flags-select/scss/react-flags-select.scss';
import routes, {getRoute} from '../../../routes/routes';
import {toast} from "react-toastify";

const {TextArea} = Input;

/**
 * This component operates on a deep copy of project taken from ProjectState.
 * Each edit is done to a local project object. On save it's send back to API, then ProjectState
 * and local copy if refreshed.
 *
 * @returns {*}
 * @constructor
 */
export const ProjectSettings = (props) => {
  const projectContext = useContext(ProjectContext);
  const projectSettingsContext = useContext(ProjectSettingsContext);
  const [refreshLoading, setRefreshLoading] = useState(false);
  const [updateProjectLoading, setUpdateProjectLoading] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [currentView, setCurrentView] = useState(props.show);
  let {projectId} = useParams();
  let history = useHistory();

  useEffect(() => {
    projectContext.loadProject(projectId).then((project) => {
      projectSettingsContext.setProject(project);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const showSuccess = (title, desc) => {
    notification.success({
      message: title,
      description: desc,
      placement: `topRight`,
      duration: 5,
      icon: <CheckCircleOutlined/>
    });
  }

  const toggleProjectGroupMode = () => {
    let currentGroupMode = projectSettingsContext.project.groupMode;
    projectSettingsContext.setProjectGroupMode(
      currentGroupMode === PROJECT_GROUP_MODE_SIMPLE ? PROJECT_GROUP_MODE_ADVANCED : PROJECT_GROUP_MODE_SIMPLE
    );
  }

  const updateProject = () => {
    setUpdateProjectLoading(true);
    projectSettingsContext.updateProject().then((newProjectDetails) => {
      // after successful save set new project data in global project context
      projectContext.setProject(newProjectDetails);
      setUpdateProjectLoading(false);
      showSuccess('Project saved', 'Your project was saved successfully');
    }).catch((error) => {
      setUpdateProjectLoading(false);
      switch (error.response.data.error) {
        case 'InconsistentData':
          toast(error.response.data.message, {
            onClose: () => {

            },
            type: toast.TYPE.ERROR
          });
          break;
        default:
          break;
      }
    });
  };

  const refreshCampaignList = () => {
    projectSettingsContext.refreshCampaignList().then((result) => {
      if (result.campaignsChanged) {
        // TOOD: @alex let's have a standard notification that there was a change or not, it's just info,
        // shouldn't be a modal
        toast("Campaign list updated.",
          {
            type: toast.TYPE.SUCCESS
          }
        );

      } else {
        toast("We did not find any new or removed campaigns.",
          {
            type: toast.TYPE.SUCCESS
          }
        );
      }
      setRefreshLoading(false);
    }).catch((error) => {
      setRefreshLoading(false);
    });
  }

  /**
   *  Visual related settings
   */
  const leftNavBoxes = [
    {
      box: {
        'id': 0,
        'icon': 'linear-cog',
        'name': 'Show all settings',
        'link': getRoute(routes.projectSettings, {projectId}),
        'visible': props.show ? '' : 'none'
      }
    },
    {
      box: {
        'id': 1,
        'icon': 'linear-aim',
        'name': 'Click Fraud Detection Rules',
        'link': getRoute(routes.projectSettingsClickFraud, {projectId}),
        'active': currentView === 'click-fraud'
      }
    },
    {
      box: {
        'id': 2,
        'icon': 'linear-radar',
        'name': 'Detect IP based on device id',
        'link': getRoute(routes.projectSettingsIpBased, {projectId}),
        'active': currentView === 'ip-based'
      }
    },
    {
      box: {
        'id': 3,
        'icon': 'linear-location',
        'name': 'Manual ban IPs',
        'link': getRoute(routes.projectSettingsManualBan, {projectId}),
        'active': currentView === 'manual-ban'
      }
    },
    {
      box: {
        'id': 4,
        'icon': 'linear-dna',
        'name': 'VPN Blocking',
        'link': getRoute(routes.projectSettingsVpnBlocking, {projectId}),
        'active': currentView === 'vpn-blocking'
      }
    },
    {
      box: {
        'id': 5,
        'icon': 'linear-select',
        'name': 'Behavior analysis',
        'link': getRoute(routes.projectSettingsBehaviorAnalysis, {projectId}),
        'active': currentView === 'behavior-analysis'
      }
    },
    {
      box: {
        'id': 6,
        'icon': 'linear-eye',
        'name': 'Toggle Monitor mode',
        'link': getRoute(routes.projectSettingsMonitorMode, {projectId}),
        'active': currentView === 'monitor-mode'
      }
    }
  ];

  const showHideBlock = (blockName) => {
    if (currentView) {
      if (currentView === blockName) {
        return {'display': 'block'}
      } else {
        return {'display': 'none'}
      }
    }
  }

  return (
    <ProjectSettingsContext.Consumer>
      {({isProjectLoading, project, addGroup, setSettingsField, getManuallyBannedIps, setManuallyBannedIps}) => (
        <>
          {isProjectLoading ? <div></div> :
            <>
              <LeftMainBoxes boxes={leftNavBoxes} cols={1} boxTextCenter={true}>
              </LeftMainBoxes>

              <RightContent>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}
                            style={showHideBlock('click-fraud')}>
                  <ContentBoxHeader title={`Click fraud`}>
                    <div className={`scpc--contentbox-header-right-switcher`}>
                          <Button
                            type="primary"
                            icon={<ReloadOutlined/>}
                            loading={refreshLoading}
                            shape="round"
                            onClick={() => {
                              setRefreshLoading(true);
                              refreshCampaignList()
                            }}
                          >
                            Refresh Google Ads Campaigns
                          </Button>
                      <p className={`mb-0 ml-15 hidden-sm`}>Expert mode</p>
                      <Switch
                        className={`scpc--controls-switch hidden-sm`}
                        onChange={toggleProjectGroupMode}
                        defaultChecked={project.groupMode === PROJECT_GROUP_MODE_ADVANCED}
                      />
                    </div>
                  </ContentBoxHeader>

                  {project.groupMode === PROJECT_GROUP_MODE_ADVANCED ?
                    <div className="scpc--contentbox-content">
                      <ExpertMode/>
                    </div>
                    :
                    <div className="scpc--contentbox-content">
                      <StandardMode/>
                    </div>}

                  <ContentBoxFooter>
                    <div type={`left`}>
                      <button className={`scpc--contentbox-needhelpbtn`} onClick={() => {
                        history.push('/account/support')
                      }}>
                        Need help?
                      </button>
                    </div>
                    <div type={`right`}>
                      {/*{project.groupMode === PROJECT_GROUP_MODE_ADVANCED &&*/}
                      {/*<SCPCButton*/}
                      {/*  border={true}*/}
                      {/*  icon={`linear-checkmark-circle`}*/}
                      {/*  color={`blue`}*/}
                      {/*  rounded={`default`}*/}
                      {/*  onClick={addGroup}*/}
                      {/*>*/}
                      {/*  Add new group*/}
                      {/*</SCPCButton>*/}
                      {/*}*/}
                      {/*<SCPCButton*/}
                      {/*  icon={`linear-checkmark-circle`}*/}
                      {/*  color={`blue`}*/}
                      {/*  flat={true}*/}
                      {/*  rounded={`default`}*/}
                      {/*  onClick={updateProject}*/}
                      {/*  style={{*/}
                      {/*    marginLeft: 25*/}
                      {/*  }}*/}
                      {/*>*/}
                      {/*  Save project*/}
                      {/*</SCPCButton>*/}
                      {project.groupMode === PROJECT_GROUP_MODE_ADVANCED &&
                      <Button
                        type="default"
                        shape={`round`}
                        icon={<PlusCircleOutlined/>}
                        size={`large`}
                        onClick={addGroup}
                      >
                        Add new group
                      </Button>
                      }
                      <Button
                        type="primary"
                        shape="round"
                        icon={<SaveOutlined/>}
                        size={`large`}
                        onClick={updateProject}
                        loading={updateProjectLoading}
                      >
                        Save Project
                      </Button>
                    </div>
                  </ContentBoxFooter>
                </ContentBox>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`} style={showHideBlock('ip-based')}>
                  <ContentBoxHeader title={`Detect IP based on device ID`}>
                  </ContentBoxHeader>
                  <div className={`scpc--contentbox-switcherrow`}>
                    <Switch
                      className={`scpc--controls-switch`}
                      onChange={(value) => setSettingsField('fingerprinting', value)}
                      checked={project.settings.fingerprinting}
                    />
                    <span>By enabling this feature, SafeCPC will detect IPs that were Used by the same device id to click your ads repeatedly.</span>
                  </div>
                  <ContentBoxFooter>
                    <div type={`left`}>
                      <button className={`scpc--contentbox-needhelpbtn`} onClick={() => {
                        history.push('/account/support')
                      }}>
                        Need help?
                      </button>
                    </div>
                    <div type={`right`}>
                      <Button
                        type="primary"
                        shape="round"
                        icon={<SaveOutlined/>}
                        size={`large`}
                        onClick={updateProject}
                        loading={updateProjectLoading}
                      >
                        Update Settings
                      </Button>
                    </div>
                  </ContentBoxFooter>
                </ContentBox>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}
                            style={showHideBlock('vpn-blocking')}>
                  <ContentBoxHeader title={`VPN Blocking`}>
                  </ContentBoxHeader>
                  <div className={`scpc--contentbox-switcherrow`}>
                    <Switch
                      className={`scpc--controls-switch`}
                      onChange={(value) => setSettingsField('vpnBlocking', value)}
                      checked={project.settings.vpnBlocking}
                    />
                    <span>By enabling this feature, SafeCPC will block devices and IPs that uses VPN to click your ads </span>
                  </div>
                  <ContentBoxFooter>
                    <div type={`left`}>
                      <button className={`scpc--contentbox-needhelpbtn`} onClick={() => {
                        history.push('/account/support')
                      }}>
                        Need help?
                      </button>
                    </div>
                    <div type={`right`}>
                      <Button
                        type="primary"
                        shape="round"
                        icon={<SaveOutlined/>}
                        size={`large`}
                        onClick={updateProject}
                        loading={updateProjectLoading}
                      >
                        Update Settings
                      </Button>
                    </div>
                  </ContentBoxFooter>
                </ContentBox>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}
                            style={showHideBlock('behavior-analysis')}>
                  <ContentBoxHeader title={`Behavior Analysis`}>
                  </ContentBoxHeader>
                  <div className={`scpc--contentbox-switcherrow`}>
                    <Switch
                      className={`scpc--controls-switch`}
                      onChange={(value) => setSettingsField('behaviourAnalysis', value)}
                      checked={project.settings.behaviourAnalysis}
                    />
                    <span>By enabling this feature, SafeCPC will record users activity which then will use to determine whether or not the visit was genuine. </span>
                  </div>
                  <ContentBoxFooter>
                    <div type={`left`}>
                      <button className={`scpc--contentbox-needhelpbtn`} onClick={() => {
                        history.push('/account/support')
                      }}>
                        Need help?
                      </button>
                    </div>
                    <div type={`right`}>
                      <Button
                        type="primary"
                        shape="round"
                        icon={<SaveOutlined/>}
                        size={`large`}
                        onClick={updateProject}
                        loading={updateProjectLoading}
                      >
                        Update Settings
                      </Button>
                    </div>
                  </ContentBoxFooter>
                </ContentBox>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`} style={showHideBlock('manual-ban')}>
                  <ContentBoxHeader title={`Banned IPs`}>
                  </ContentBoxHeader>
                  <div className={`scpc--contentbox-switcherrow`}>
                    <TextArea
                      defaultValue={getManuallyBannedIps()}
                      onChange={(e) => setManuallyBannedIps(e.target.value)}
                      rows={5}
                    />
                  </div>
                  <div>
                    <span>IPs that will be banned in all campaings in the domain, separate with new line or comma.</span>
                  </div>
                  <ContentBoxFooter>
                    <div type={`left`}>
                      <button className={`scpc--contentbox-needhelpbtn`} onClick={() => {
                        history.push('/account/support')
                      }}>
                        Need help?
                      </button>
                    </div>
                    <div type={`right`}>
                      <Button
                        type="primary"
                        shape="round"
                        icon={<SaveOutlined/>}
                        size={`large`}
                        onClick={updateProject}
                        loading={updateProjectLoading}
                      >
                        Update Settings
                      </Button>
                    </div>
                  </ContentBoxFooter>
                </ContentBox>
                <ContentBox shadow={`small-2`} rounded={`small`} padding={`medium`}
                            style={showHideBlock('monitor-mode')}>
                  <ContentBoxHeader title={`Monitor Mode`}>
                  </ContentBoxHeader>
                  <div className={`scpc--contentbox-switcherrow`}>
                    <Switch
                      className={`scpc--controls-switch`}
                      onChange={(value) => setSettingsField('demoMode', value)}
                      checked={project.settings.demoMode}
                    />
                    <span>By enabling this mode, SafeCPC will record users activity IPs and devices, but will not block them.</span>
                  </div>
                  <ContentBoxFooter>
                    <div type={`left`}>
                      <button className={`scpc--contentbox-needhelpbtn`} onClick={() => {
                        history.push('/account/support')
                      }}>
                        Need help?
                      </button>
                    </div>
                    <div type={`right`}>
                      <Button
                        type="primary"
                        shape="round"
                        icon={<SaveOutlined/>}
                        size={`large`}
                        onClick={updateProject}
                        loading={updateProjectLoading}
                      >
                        Update Settings
                      </Button>
                    </div>
                  </ContentBoxFooter>
                </ContentBox>
              </RightContent>
            </>
          }</>
      )}
    </ProjectSettingsContext.Consumer>
  );
};

