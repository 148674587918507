import React, {useEffect, useReducer, useRef} from 'react';
import NotificationsContext from './NotificationsContext';
import NotificationsReducer from "./NotificationsReducer";
import * as UsersApi from "../../api/users";
import {NOTIFICATIONS_ARRIVED, NOTIFICATIONS_UPDATED} from "../types";
import _ from 'lodash';
import auth0Client from "../../Auth";

// https://overreacted.io/making-setinterval-declarative-with-react-hooks/
function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const NotificationsState = (props) => {
  const initialState = {
    isLoading: true,
    notifications: [],
    maxId: 0  // id of the last notification we got so far
  };

  const [state, dispatch] = useReducer(NotificationsReducer, initialState);

  useInterval(() => {
    loadNotifications();
  }, process.env.REACT_APP_NOTIFICATIONS_POOLING_INTERVAL || 20000);

  useEffect(() => {
    // load the notifications right after loading
    loadNotifications();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const loadNotifications = () => {
    if (auth0Client.isAuthenticated()) {
      UsersApi.getNotifications(state.maxId)
        .then((result) => {
          if (result.notifications !== undefined
            && _.isArray(result.notifications)
            && result.notifications.length > 0) {
            // && !_.isEqual(result.notifications, state.notifications) // afterId makes this unnecessary
            // update only if content changed
            dispatch({type: NOTIFICATIONS_ARRIVED, payload: result.notifications});

            // don't like it but let's yield toasts here
            _.eachRight(result.notifications, (notification) => {
              if (!notification.isProcessed && !notification.isRead) {
                // TODO: render some summary and link to notification in tooltip
                switch (notification.type) {
                  case 'new_ban':
                    // w notications.content masz rozne rzeczy w zaleznosci od notication.type
                    // bans [] zawiera obiekty albo z ip ban albo z finigerprint, kazdy ma pole type
                    // toast.success(`New ban: ${notification.content.bans[0].fingerprint}`, {
                    //   position: "top-right",
                    //   autoClose: 2000,
                    //   hideProgressBar: false,
                    //   closeOnClick: true,
                    //   pauseOnHover: true,
                    //   draggable: true,
                    //   progress: undefined,
                    // });
                    break;
                  default:
                    console.log('unknown type');
                    break;
                }
              }
            })
          }
        });
    }
  };

  const markNotificationsRead = (projectId) => {
    UsersApi.markNotificationsRead(projectId);

    // don't reload from server, just set read flags on notifications we have so far
    const notifications = state.notifications.map((notification) => ({...notification, isRead: true}));
    dispatch({type: NOTIFICATIONS_UPDATED, payload: notifications});
  }

  return <NotificationsContext.Provider
    value={{
      isLoading: state.isLoading,
      notifications: state.notifications,
      markNotificationsRead
    }}
  >
    {props.children}
  </NotificationsContext.Provider>
};

export default NotificationsState;
